import React, { useState, useEffect } from "react";
import usePermission from "../../../../../hooks/usePermissions";
import { useNav } from "../../../../../hooks/useNav";
// import "./HousePlans.css";
import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../../../hooks/useAxiosBase";
import Button from "../../../../../Components/Common/Button";
import Modal from "../../../../../Components/Common/Modal";
import { useNavigate, useParams } from "react-router-dom";
import useGetPlans from "./hooks/useGetPlans";
import Spinner from "../../../../../Components/Spinner";
// import { plansColumns } from "./constants";
import InfoTable from "../../../../../Components/Common/InfoTable";
import InfoCard from "../../../../../Components/Common/InfoCard";
import CenteredText from "../../../../../Components/CenteredText";
import useDeletePlans from "../../../../Plans/hooks/useDeletePlans";
import {
  convertToTitleCase,
  formattedPrice,
  colors
} from "../../../../../utils/constants";
import { Dropdown } from "react-bootstrap";
import { HiDotsVertical } from "react-icons/hi";
import useGetHouse from "../hooks/useGetHouse";
import CellSelector from "../../../../../Components/Common/CellSelector";
import TableLoader from "../../../../../Components/Common/TableLoader";
import SkeletonLoader from "../../../../../Components/SkeletonLoader";

const HousePlans = () => {
  const navigate = useNavigate();

  const params = useParams();
  const houseId = params?.houseId;

  const { data: house, isLoading: houseLoading } = useGetHouse(houseId);

  const [houseName, setHouseName] = useState();
  const [housePrice, setHousePrice] = useState();
  const [houseAddress, setHouseAddress] = useState();

  useNav({
    mainTitle: `Plans ${houseName ? `- ${houseName}` : ""}`,
    subTitle: (
      <p className="m-0">
        This are different plans available for customers to purchase {houseName}{" "}
        {houseAddress && <span className="circle_bar"></span>} {houseAddress}{" "}
        {housePrice && (
          <>
            <span className="circle_bar"></span> ₦{formattedPrice(housePrice)}
          </>
        )}
      </p>
    ),
    leftBtnClick: () => navigate("/properties/my-properties"),
    // hasBackButton: true
  });

  const {
    accessViewPlans,
    accessCreatePlan,
    accessUpdatePlan,
    accessDeletePlan,
    setAccessDeletePlan,
    setAccessUpdatePlan,
  } = usePermission();

  const [planId, setPlanId] = useState("");

  const [pagination, setPagination] = useState(1);
  const {
    data: plans,
    isLoading,
    refetch,
    isRefetching,
  } = useGetPlans(houseId, pagination);

  const { deletePlans } = useDeletePlans(planId);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const showEditTeamMember = (rowId) => {
    navigate(`/properties/my-properties/house/plans/${houseId}/${rowId}`);
  };

  // PLANS COLUMN
  const plansColumns = (showEditModal, showDeleteModal, loading) => {
    const columns = [
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            PLAN TYPE
          </p>
        ),
        sortable: true,
        wrap: true,
        grow: 1.5,
        selector: (row) => (
          <CellSelector loading={loading}>
            {convertToTitleCase(row?.type)}
          </CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            PLAN NAME
          </p>
        ),
        sortable: true,
        wrap: true,
        grow: 1.5,
        selector: (row) => (
          <CellSelector loading={loading}>{row?.name}</CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            DELIVERY LEVEL
          </p>
        ),
        sortable: true,
        wrap: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            {convertToTitleCase(row?.deliveryLevel)}
          </CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            DELIVERY PRICE
          </p>
        ),
        sortable: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            <>₦ {formattedPrice(row?.deliveryLevelPrice)}</>
          </CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            MAX LOAN AMOUNT
          </p>
        ),
        sortable: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            {row?.maxLoanAmount === 0 ? (
              "---"
            ) : (
              <>₦ {formattedPrice(row?.maxLoanAmount)}</>
            )}
          </CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            DOWNPAYMENT
          </p>
        ),
        sortable: true,
        wrap: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            {row?.deliveryLevelPrice > row?.maxLoanAmount ? (
              <>
                {row?.equityPercentage}% (₦{" "}
                {formattedPrice(
                  (
                    row?.deliveryLevelPrice *
                    (row.equityPercentage / 100)
                  )?.toFixed(2)
                )}
                )`
              </>
            ) : (
              <>
                {row?.equityPercentage}% (₦{" "}
                {formattedPrice(
                  (row?.maxLoanAmount * (row.equityPercentage / 100))?.toFixed(
                    2
                  )
                )}
                )`
              </>
            )}
          </CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            PERIOD
          </p>
        ),
        sortable: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            {row?.loanTenor} {row?.loanTenor > 1 ? "Months" : "Month"}{" "}
          </CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            INTEREST RATE
          </p>
        ),
        sortable: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            <>{row?.interestRate}%</>
          </CellSelector>
        ),
      },
    ];

    // Check if accessSendProspectReminder.value is true before adding the action column
    if (accessUpdatePlan?.value || accessDeletePlan?.value) {
      columns.push({
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            {" "}
            ACTION{" "}
          </p>
        ),
        sortable: false,
        grow: 0.5,
        center: true,
        cell: (row) => (
          <Dropdown>
            <Dropdown.Toggle id={`dropdown-${row.id}`}>
              <HiDotsVertical className="fw-bold text-dark" />
            </Dropdown.Toggle>

            {!loading && (
              <Dropdown.Menu>
                {accessUpdatePlan?.value && (
                  <Dropdown.Item onClick={() => showEditModal(row.id)}>
                    Edit
                  </Dropdown.Item>
                )}

                {accessDeletePlan?.value && (
                  <Dropdown.Item
                    className="text-danger"
                    onClick={() => showDeleteModal(row.id)}
                  >
                    Delete
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            )}
          </Dropdown>
        ),
      });
    }
    return columns;
  };

  useEffect(() => {
    setHouseName(house?.name);
    setHousePrice(house?.price);
    setHouseAddress(house?.address);
  }, [house]);

  useEffect(() => {
    if (pagination) {
      refetch();
    }
  }, [pagination]);

  return (
    <div className="px-2 mortgage-plans">
      {accessViewPlans?.value ? (
        <>
          {accessCreatePlan?.value && (
            <div className="pe-1 text-end">
              <Button
                btnText={"Create Plan"}
                onBtnClick={() =>
                  navigate(`/properties/my-properties/house/plans/create/${houseId}`)
                }
              />
            </div>
          )}

          <div className="">
            <InfoCard
              mainValue={
                isLoading || isRefetching ? (
                  <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
                ) : (
                  plans?.totalRecords ?? "0"
                )
              }
              description={
                isLoading || isRefetching ? <SkeletonLoader /> : "Total Plans"
              }
            />
          </div>

          <div className="mb-4">
            {isLoading ? (
              <div className="mt-4">
                <TableLoader />
              </div>
            ) : plans?.data?.length > 0 ? (
              <div className="mt-2">
                <InfoTable
                  columns={plansColumns(
                    (rowId) => showEditTeamMember(rowId),
                    (rowId) => {
                      setPlanId(rowId);
                      setShowDeleteModal(true);
                    },
                    isRefetching
                  )}
                  dataCollection={plans?.data}
                  pointerOnHover={true}
                  highlightOnHover={true}
                  loadingTable={isRefetching}
                  paginationTotalRows={plans?.totalRecords}
                  onChangePage={(page) => setPagination(page)}
                  onRowClicked={(row, event) =>
                    navigate(`/properties/my-properties/house/plans/${houseId}/${row?.id}`)
                  }
                />
              </div>
            ) : (
              <div className="mt-5">
                <CenteredText title={"You have no Plans at this time"}>
                  {" "}
                  To begin accepting applications and selling your properties
                  online to customers, click the create plan button above to
                  create a purchase plan.
                </CenteredText>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="mt-5">
          <CenteredText title={"Unauthorized Access"}>
            You don't have permission to view plans. Please contact the
            organization administrator for assistance.
          </CenteredText>
        </div>
      )}

      {/* DELETE MODAL */}
      <Modal
        show={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        title={"Delete this Plan."}
        confirmModal={() =>
          deletePlans.mutate(null, {
            onSuccess: () => {
              refetch();
              setShowDeleteModal(false);
            },
          })
        }
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Delete"}
        isBtnLoading={deletePlans.isLoading}
        disabled={deletePlans.isLoading}
      >
        <p className="m-0 text-center">
          Are you sure you want to delete?{" "}
          <strong className="text-danger">This action can't be undone.</strong>
        </p>
      </Modal>
    </div>
  );
};

export default HousePlans;
