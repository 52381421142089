import React, { useEffect, useMemo, useState } from "react";
import FormInput from "../../../Components/Common/FormInput";
import Spinner from "../../../Components/Spinner";
import { getImage } from "../../../utils/generalUtils";
import { colors } from "../../../utils/constants";
import useGetAllDevelopers from "../../../hooks/useGetAllDevelopers";
import CenteredText from "../../../Components/CenteredText";
import useAuth from "../../../hooks/useAuth";

const SelectMortgageBank = ({ data, setData, formErrors, setFormErrors }) => {
  const { authUser } = useAuth();
  const organizationName = authUser?.user?.organization?.name;


  const [searchInput, setSearchInput] = useState("");
  const [selectedValues, setSelectedValues] = useState(data.invitationRequests);
  const { data: banks, error, isLoading } = useGetAllDevelopers();

  const bankCollection = useMemo(() => {
    if (!searchInput) return banks;
    if (searchInput.length > 1) {
      return banks.filter((x) => {
        return x.name
          .toLocaleLowerCase()
          .includes(searchInput.toLocaleLowerCase());
      });
    }
    return banks;
  }, [searchInput, banks]);

  const handleCheck = (event, bankid) => {
    if (selectedValues?.find((id) => id === bankid)) {
      setSelectedValues(selectedValues.filter((id) => id !== bankid));
      setData({
        ...data,
        invitationRequests: selectedValues.filter((id) => id !== bankid),
      });
      return;
    }
    setSelectedValues([...selectedValues, bankid]);
    setData({
      ...data,
      invitationRequests: [...data.invitationRequests, bankid],
    });
  };

  useEffect(() => {
    document.title = `Giddaa - Developers`;
  }, []);
  
  if (isLoading) return <div style={{height: "60vh"}}> <Spinner loadingText={"Loading Available Developers on Giddaa..."} /> </div>;

  return (
    <>
      <div className="row d-flex justify-content-center w-100 text-center">
        <h1 className="h2 sub-title">Select Developers</h1>
        <h6 className="sub-title_2 mb-4">
          Select the Developers you currently work with or want to work
          with.
          <strong>You can select more than one.</strong>
        </h6>
      </div>
      {/* <div className="row mt-4 d-flex justify-content-center">
        <div className="col-md-6 form-content ">
          <FormInput
            placeholder={"Search for you mortgage banks"}
            lefticonname={"search"}
            name="search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            disabled={!bankCollection?.length}
          />
        </div>
      </div> */}
      {error || !bankCollection.length ? (
        <div className="mt-5">
          <CenteredText title={"No Banks at this time"}>
            Proceed with the registration
          </CenteredText>
        </div>
      ) : (
        <div className="row d-flex justify-content-center mt-3">
          {bankCollection?.map((bank) => (
            <div
              className="row d-flex justify-content-center"
              key={bank.id}
            >
              <div className="col-md-6 pb-3 mb-3 bank-list-wrapper">
                <div className="row d-flex justify-content-between align-items-center position-relative">
                  <div className="col-3 col-md-4">
                    <img
                      src={bank.logo}
                      alt={bank.name}
                      className="bank_img img-fluid"
                    />
                  </div>
                  <span className="col-6 col-md-4 bank-name">{bank.name}</span>
                  <div className="col-3 col-md-4 d-flex justify-content-center form-check">
                    <input
                      className="bank-radio rounded-circle form-check-input"
                      type="checkbox"
                      checked={data.invitationRequests?.some(
                        (x) => x === bank.id
                      )}
                      onChange={(e) => {
                        handleCheck(e, bank.id);
                        setFormErrors({
                          ...formErrors,
                          invitationRequests: "",
                        });
                      }}
                    />
                    <span className="form-error">
                      {formErrors?.invitationRequests?.message}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SelectMortgageBank;
