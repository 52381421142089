import React, { useEffect, useState } from "react";
import usePermission from "../../hooks/usePermissions";
import { useNav } from "../../hooks/useNav";
import InfoCard from "../../Components/Common/InfoCard/index";
import InfoTable from "../../Components/Common/InfoTable";
import Spinner from "../../Components/Spinner";
import CenteredText from "../../Components/CenteredText";
import TabGroup from "../../Components/TabGroup";
import "./transactions.css";
import Icons from "../../Components/Common/Icons";
import {
  transactionsColumn,
  missedPaymentColumn,
  successfulTransactionsColumn,
  expectedTransactionsColumn,
} from "./utils/constants";
import { useGetMissedPayments } from "./hooks/useGetMissedPayments";
import { useGetSuccessfulTransactions } from "./hooks/useGetSuccessfulTransactions";
import { useGetExpectedTransactions } from "./hooks/useGetExpectedTransactions";
import { useGetTransactions } from "./hooks/useGetTransactions";
import { useGetSummary } from "./hooks/useGetSummary";
import { formattedPrice } from "../../utils/constants";
import useSendReminder from "./hooks/useSendReminder"
import useSendWarning from "./hooks/useSendWarning";
import { toast } from "react-toastify";

const Transactions = () => {
  useNav({
    mainTitle: "Transactions & Earnings",
    subTitle:
      "View all your transactions and how much you have earned from customers",
  });
  useEffect(() => {
    document.title = 'Transactions & Earnings';
  }, []);

  const {
    accessViewTransaction,
    accessViewTransactionReciept,
    accessDownloadTransactionReciept,
    accessViewRepaymentSchedule,
    accessRemindCustomer,
  } = usePermission();

  const [transactionId, setTransactionId] = useState("");
  const { sendReminder } = useSendReminder(transactionId);
  const { sendWarning } = useSendWarning(transactionId);

  const [successfulTransactionPageNumber, setSuccessfulTransactionPageNumber] =
    useState(1);
  const [expectedTransactionsPageNumber, setExpectedTransactionsPageNumber] =
    useState(1);
  const [missedPaymentsPageNumber, setMissedPaymentsPageNumber] = useState(1);

  const [
    loadingSuccessfulTransactionTable,
    setLoadingSuccessfulTransactionTable,
  ] = useState(false);
  const [
    loadingExpectedTransactionsTable,
    setLoadingExpectedTransactionsTable,
  ] = useState(false);
  const [loadingMissedPaymentsTable, setLoadingMissedPaymentsTable] =
    useState(false);

  const { data: getSummary } = useGetSummary();
  const {
    data: getSuccessfulTransctions,
    refetch: refetchSuccessfulTransaction,
    isRefetching: isRefetchingSuccessfulTransaction,
  } = useGetSuccessfulTransactions(successfulTransactionPageNumber);
  const {
    data: getExpectedTransactions,
    refetch: refetchExpectedTransactions,
    isRefetching: isRefetchingExpectedTransactions,
  } = useGetExpectedTransactions(expectedTransactionsPageNumber);
  const {
    data: getMissedPayments,
    refetch: refetchMissedPayments,
    isRefetching: isRefetchingMissedPayments,
  } = useGetMissedPayments(missedPaymentsPageNumber);
  const { data: getTransactions, isLoading } = useGetTransactions();

  console.log("getMissedPayments?.data", getMissedPayments?.data)

  const formatCurrency = (value) => {
    if (value === undefined || value === null) {
      return "N/A";
    }

    return value.toLocaleString("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const handleButtonClick = (rowId, event) => {
    setTransactionId(rowId);

    if (event === "remind") {
      sendReminder.mutate();
      toast.warning("Sending Reminder!")
    } else {
      sendWarning.mutate();
      toast.warning("Sending Warning!")
    }
  };

  // SUCCESSFULL TRANSATIONS
  useEffect(() => {
    setLoadingSuccessfulTransactionTable(isRefetchingSuccessfulTransaction);
  }, [isRefetchingSuccessfulTransaction]);

  useEffect(() => {
    if (successfulTransactionPageNumber) {
      refetchSuccessfulTransaction();
    }
  }, [successfulTransactionPageNumber]);

  // EXPECTED TRANSACTIONS
  useEffect(() => {
    setLoadingExpectedTransactionsTable(isRefetchingExpectedTransactions);
  }, [isRefetchingExpectedTransactions]);

  useEffect(() => {
    if (expectedTransactionsPageNumber) {
      refetchExpectedTransactions();
    }
  }, [expectedTransactionsPageNumber]);

  // DEFAULT TRANSACTIONS
  useEffect(() => {
    setLoadingMissedPaymentsTable(isRefetchingMissedPayments);
  }, [isRefetchingMissedPayments]);

  useEffect(() => {
    if (missedPaymentsPageNumber) {
      refetchMissedPayments();
    }
    
  }, [missedPaymentsPageNumber]);

  return (
    <div className="container-fluid">
      {accessViewTransaction?.value ? (
        <>
          <TabGroup>
            {/* SUMMARY TAB */}
            <div label="SUMMARY">
              {isLoading ? (
                <div style={{ height: "50vh" }}>
                  <Spinner loadingText={"Loading Portfolio..."} />{" "}
                </div>
              ) : !getSummary ? (
                <div className="mt-5">
                  <CenteredText title={"No Summary"}>
                    You have no Summary at the moment.
                  </CenteredText>
                </div>
              ) : (
                <>
                  <div className="mt-3">
                    <p className="summ-text m-0 mb-1">Earnings Breakdown</p>
                    <div className="row">
                      <div className="mb-4 col-md-6 col-lg-4 col-xl-3">
                        <InfoCard
                          info={true}
                          width={"100%"}
                          mainValue={
                            <div className="d-flex align-items-center gap-2">
                              {getSummary?.expectedEarnings
                                ? formatCurrency(getSummary?.expectedEarnings)
                                : "0"}
                            </div>
                          }
                          description={"Expected Earnings"}
                        />
                      </div>
                      <div className="mb-4 col-md-6 col-lg-4 col-xl-3">
                        <InfoCard
                          info={true}
                          width={"100%"}
                          mainValue={
                            <div className="d-flex align-items-center gap-2">
                              {getSummary?.totalEarned
                                ? formatCurrency(getSummary?.totalEarned)
                                : "0"}
                            </div>
                          }
                          description={"Total Earned"}
                        />
                      </div>
                      <div className="mb-4 col-md-6 col-lg-4 col-xl-3">
                        <InfoCard
                          info={true}
                          width={"100%"}
                          mainValue={
                            <div className="d-flex align-items-center gap-2">
                              {getSummary?.leftToEarn
                                ? formatCurrency(getSummary?.leftToEarn)
                                : "0"}
                            </div>
                          }
                          description={"Amount Left To Earn"}
                        />
                      </div>
                      <div className="mb-4 col-md-6 col-lg-4 col-xl-3">
                        <InfoCard
                          info={true}
                          width={"100%"}
                          mainValue={
                            <div className="d-flex align-items-center gap-2">
                              {getSummary?.averageAmountEarned
                                ? formatCurrency(
                                    getSummary?.averageAmountEarned
                                  )
                                : "0"}
                            </div>
                          }
                          description={"Average Amount Earned"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-1">
                    <p className="summ-text m-0 mb-1">Frequency Breakdown</p>
                    <div className="row">
                      <div className="mb-4 col-md-6 col-lg-4 col-xl-3">
                        <InfoCard
                          info={true}
                          width={"100%"}
                          mainValue={
                            <div className="d-flex align-items-center gap-2">
                              {getSummary?.totalTransactions ?? "0"}
                            </div>
                          }
                          description={"Expected Transactions"}
                        />
                      </div>
                      <div className="mb-4 col-md-6 col-lg-4 col-xl-3">
                        <InfoCard
                          info={true}
                          width={"100%"}
                          mainValue={
                            <div className="d-flex align-items-center gap-2">
                              {" "}
                              {getSummary?.totalPaidTransactions ?? "0"}
                            </div>
                          }
                          description={"Successful Transactions"}
                        />
                      </div>
                      <div className="mb-4 col-md-6 col-lg-4 col-xl-3">
                        <InfoCard
                          info={true}
                          width={"100%"}
                          mainValue={
                            <div className="d-flex align-items-center gap-2">
                              {" "}
                              {getSummary?.numberOfTransactionsLeft ?? "0"}
                            </div>
                          }
                          description={"Transactions Left"}
                        />
                      </div>
                      <div className="mb-4 col-md-6 col-lg-4 col-xl-3">
                        <InfoCard
                          info={true}
                          width={"100%"}
                          mainValue={
                            <div className="d-flex align-items-center gap-2">
                              {" "}
                              {getSummary?.averageMonthlyTransactions ?? "0"}
                            </div>
                          }
                          description={"Average Transactions/Month"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-1">
                    <p className="summ-text m-0 mb-1">Default Breakdown</p>
                    <div className="row">
                      <div className="mb-4 col-md-6 col-lg-4 col-xl-3">
                        <InfoCard
                          info={true}
                          width={"100%"}
                          mainValue={
                            <div className="d-flex align-items-center gap-2">
                              {" "}
                              {getSummary?.totalMissedTransactions ?? "0"}
                            </div>
                          }
                          description={"Missed Payments"}
                        />
                      </div>
                      <div className="mb-4 col-md-6 col-lg-4 col-xl-3">
                        <InfoCard
                          info={true}
                          width={"100%"}
                          mainValue={
                            <div className="d-flex align-items-center gap-2">
                              {getSummary?.transactionDefaultRate
                                ? getSummary?.transactionDefaultRate?.toFixed(2)
                                : "0"}
                              %
                            </div>
                          }
                          description={"Transaction Default rate"}
                        />
                      </div>
                      <div className="mb-4 col-md-6 col-lg-4 col-xl-3">
                        <InfoCard
                          info={true}
                          width={"100%"}
                          mainValue={
                            <div className="d-flex align-items-center gap-2">
                              <span style={{ color: "red" }}>
                                {getSummary?.totalApplicationsWithMissedTransactions ??
                                  0}
                              </span>{" "}
                              of{" "}
                              {getSummary?.totalApplicationsInTransactions ?? 0}
                            </div>
                          }
                          description={"Applications with missed payment"}
                        />
                      </div>
                      {/* <div className="mb-4 col-md-6 col-lg-4 col-xl-3">
                    <InfoCard
                      info={true}
                     
                      width={"100%"}
                      mainValue={
                        <div className="d-flex align-items-center gap-2">
                          {" "}
                          <Icons iconName={"transaction_earnings"} /> 112,000,000
                        </div>
                      }
                      description={"Expected Earnings"}
                    />
                  </div> */}
                    </div>
                  </div>
                </>
              )}
            </div>

            {/* SUCCESSFUL TRANSACTIONS TAB */}
            <div label="SUCCESSFUL TRANSACTIONS">
              <div className="mt-3">
                <InfoCard
                  info={true}
                  mainValue={getSuccessfulTransctions?.totalRecords ?? "0"}
                  description={"Total Transactions"}
                />
              </div>
              <p className="trans-text m-0 mt-3">
                Successful transactions made by customers in your organization
              </p>
              <div className="">
                {isLoading ? (
                  <div style={{ height: "35vh" }}>
                    <Spinner loadingText={"Loading Portfolio..."} />{" "}
                  </div>
                ) : getSuccessfulTransctions?.data?.length <= 0 ? (
                  <div className="mt-5">
                    <CenteredText title={"No Transactions"}>
                      There are no Successful Transactions at this time
                    </CenteredText>
                  </div>
                ) : (
                  <div style={{ fontFamily: '"Red Hat Display", sans-serif' }}>
                    <InfoTable
                      columns={successfulTransactionsColumn(
                        
                      )}
                      dataCollection={getSuccessfulTransctions?.data}
                      pointerOnHover={true}
                      loadingTable={loadingSuccessfulTransactionTable}
                      paginationTotalRows={
                        getSuccessfulTransctions?.totalRecords
                      }
                      onChangePage={(page) =>
                        setSuccessfulTransactionPageNumber(page)
                      }
                    />
                  </div>
                )}
              </div>
            </div>

            {/* EXPECTED TRANSACTIONS TAB */}
            <div label="EXPECTED TRANSACTIONS">
              <div className="mt-3">
                <InfoCard
                  info={true}
                  mainValue={getExpectedTransactions?.totalRecords ?? "0"}
                  description={"Total Transactions"}
                />
              </div>
              <p className="trans-text m-0 mt-3">
                Expected transactions involve payments awaiting the payment due
                date, anticipated to be fulfilled by customers, and primarily
                representing future repayments.
              </p>
              <div>
                {isLoading ? (
                  <div style={{ height: "35vh" }}>
                    <Spinner loadingText={"Loading Portfolio..."} />{" "}
                  </div>
                ) : !getExpectedTransactions?.data?.length ? (
                  <div className="mt-5">
                    <CenteredText title={"No Transactions"}>
                      There are no Expected Transactions at this time
                    </CenteredText>
                  </div>
                ) : (
                  <div style={{ fontFamily: '"Red Hat Display", sans-serif' }}>
                    <InfoTable
                      columns={expectedTransactionsColumn(
                        
                      )}
                      dataCollection={getExpectedTransactions?.data}
                      pointerOnHover={true}
                      loadingTable={loadingExpectedTransactionsTable}
                      paginationTotalRows={
                        getExpectedTransactions?.totalRecords
                      }
                      onChangePage={(page) =>
                        setExpectedTransactionsPageNumber(page)
                      }
                    />
                  </div>
                )}
              </div>
            </div>

            {/* MISSED PAYMENTS TAB */}
            <div label="DEFAULTS">
              <div className="mt-3">
                <div className="row">
                  <div className="mb-4 col-md-6 col-lg-4 col-xl-3">
                    <InfoCard
                      info={true}
                      width={"100%"}
                      mainValue={
                        getSummary?.expectedEarnings
                          ? formatCurrency(getSummary?.expectedEarnings)
                          : 0
                      }
                      description={"Expected Earnings"}
                    />
                  </div>
                  <div className="mb-4 col-md-6 col-lg-4 col-xl-3">
                    <InfoCard
                      info={true}
                      width={"100%"}
                      mainValue={
                        getSummary?.totalMissedTransactionsAmount
                          ? `₦${formattedPrice(
                              getSummary?.totalMissedTransactionsAmount?.toFixed()
                            )}`
                          : 0
                      }
                      description={"Transaction Default Amount"}
                    />
                  </div>
                  <div className="mb-4 col-md-6 col-lg-4 col-xl-3">
                    <InfoCard
                      info={true}
                      width={"100%"}
                      mainValue={getSummary?.totalMissedTransactions ?? "0"}
                      description={"Missed Payments"}
                    />
                  </div>
                  <div className="mb-4 col-md-6 col-lg-4 col-xl-3">
                    <InfoCard
                      info={true}
                      width={"100%"}
                      mainValue={
                        <>
                          <span style={{ color: "red" }}>
                            {getSummary?.totalApplicationsWithMissedTransactions ??
                              0}
                          </span>{" "}
                          of {getSummary?.totalApplicationsInTransactions ?? 0}
                        </>
                      }
                      description={"Applications with missed payment"}
                    />
                  </div>
                </div>
              </div>
              <p className="trans-text m-0">
                Data on payments that should have beeen made but weren’t and the
                customers who should have paid
              </p>
              <div>
                {isLoading ? (
                  <div style={{ height: "35vh" }}>
                    <Spinner loadingText={"Loading Portfolio..."} />{" "}
                  </div>
                ) : !getMissedPayments?.data?.length ? (
                  <div className="mt-5">
                    <CenteredText title={"No Transactions"}>
                      There are no Transactions at this time
                    </CenteredText>
                  </div>
                ) : (
                  <div style={{ fontFamily: '"Red Hat Display", sans-serif' }}>
                    <InfoTable
                      columns={missedPaymentColumn((rowId, event) =>
                        handleButtonClick(rowId, event)
                      )}
                      dataCollection={getMissedPayments?.data}
                      pointerOnHover={true}
                      loadingTable={loadingMissedPaymentsTable}
                      paginationTotalRows={getMissedPayments?.totalRecords}
                      onChangePage={(page) => setMissedPaymentsPageNumber(page)}
                    />
                  </div>
                )}
              </div>
            </div>
          </TabGroup>
        </>
      ) : (
        <div className="mt-5">
          <CenteredText title={"Unauthorized Access"}>
            You don't have permission to view transactions. Please contact the
            organization administrator for assistance.
          </CenteredText>
        </div>
      )}
    </div>
  );
};

export default Transactions;
