import React, { useEffect, useState } from "react";
import FormInput from "../../../Components/Common/FormInput";
import Dropdown from "../../../Components/Common/Dropdown";
import { colors } from "../../../utils/constants";
import useAuth from "../../../hooks/useAuth";

const PropertyViewing = ({ data, setData }) => {
  const { authUser } = useAuth();
  const organizationName = authUser?.user?.organization?.name;

  const [propertyViewing, setPropertyViewing] = useState(false);

  const handleSelectOption = (value) => {
    if (value === "Yes") {
      setPropertyViewing(true);
    } else {
      setPropertyViewing(false);
      setData({
        ...data,
        agentCharge: "",
      });
    }
  };

  const options = [
    {
      id: "No",
      name: "No",
    },
    {
      id: "Yes",
      name: "Yes",
    },
  ];

  useEffect(() => {
    document.title = `Giddaa - Viewing Settings`;
  }, []);

  return (
    <>
      <div className="row d-flex justify-content-center w-100 text-center">
        <h1 className="h2 sub-title">Control Property Viewings</h1>
        <h6 className="sub-title_2">
          Let prospective customers know how they should handle property
          viewings with staff
        </h6>
      </div>

      <div className="row mt-3 d-flex flex-column align-items-center justify-content-center col-md-12">
        <div className="col-6 mx-auto ">
          <Dropdown
            labelName={" Do your staffs charge for property viewings?"}
            options={options}
            onChange={(e) => handleSelectOption(e.target.value)}
            classname="mx-auto"
          />
        </div>

        {propertyViewing ? (
          <div className="col-md-6 mx-auto pb-4">
            <div className="col-md-12">
              <FormInput
                required
                type={"number"}
                labelName={"How Much Do they Charge"}
                placeholder="What do agents charge per viewing?"
                value={data.agentCharge}
                onChange={(name, value) => {
                  const charge = parseFloat(value);
                  setData({
                    ...data,
                    agentCharge: isNaN(charge) ? "" : charge,
                  });
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default PropertyViewing;
