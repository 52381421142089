import React, { useEffect, useRef, useState } from "react";
import FormInput from "../../../Components/Common/FormInput";
import Dropdown from "../../../Components/Common/Dropdown";
import MultitextInput from "../../../Components/Common/MultitextInput";
import Icon from "../../../Components/Common/Icons";
import Button from "../../../Components/Common/Button";
import Icons from "../../../Components/Common/Icons";
import { getBase64Image, fileExtension } from "../../../utils/imageUtils";
import { colors } from "../../../utils/constants";
// import { bankList } from "../../../Components/Common/BankList/bankListConstant";
import { bankTypes } from "../../../utils/mockData.js";
import useAllStates from "../../../hooks/useAllStates";
import useGetCity from "../../../hooks/useGetCity";
import { toast } from "react-toastify";
import { convertToTitleCase } from "../../../utils/constants";
import useAuth from "../../../hooks/useAuth";

const CompanyDetails = ({ data, setData, formErrors, setFormErrors }) => {
  const { authUser } = useAuth();
  const organizationName = authUser?.user?.organization?.name;

  const imageRef = useRef();
  const coverImageRef = useRef();

  const [logoImg, setLogoImg] = useState(data.companyLogo);
  const [featureImg, setFeatureImg] = useState(data.featuredImage);

  const handleDeleteCoverImage = () => {
    setData({ ...data, featuredImage: null });
    setFeatureImg(data.featuredImage);
  };

  const {
    data: states,
    error,
    isLoading,
  } = useAllStates({ onSuccess: (data) => {} });
  const [stateId, setStateId] = useState("");
  const { useCity } = useGetCity();

  const [bankInfo, setBankInfo] = useState({});
  const [cityId, setCityId] = useState("");

  const [banks, setBanks] = useState([]);
  const [cityList, setCityList] = useState([]);

  const handleSelectedBank = (id) => {
    if (id) {
      const bankName = bankTypes.find((x) => x.id === id).name;
      setBankInfo({
        bankCode: id,
        bankName: bankName,
      });
    }
  };

  const handleSelectedState = (stateId) => {
    if (stateId) {
      setStateId(stateId);
    }
  };

  const handleSelectedCityId = (cityId) => {
    if (cityId) {
      setCityId(cityId);
    }
  };

  const handleAddLogo = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        // Check if the file size is greater than 2MB
        toast.error("Image size should be less than 2MB");
        setFormErrors({
          ...formErrors,
          logoBase64: { message: "File size should be less than 2MB" },
        });
        return;
      }
      if (!["image/jpg", "image/jpeg", "image/png"].includes(file.type)) {
        // Check if the file type is jpeg or png
        toast.error("Please upload a JPG, JPEG or PNG image");
        setFormErrors({
          ...formErrors,
          logoBase64: { message: "Please upload a JPG, JPEG or PNG image" },
        });
        return;
      }
      const base64 = await getBase64Image(file);
      let newImage = {
        base64: base64,
        name: file.name,
        optionId: "BANK_LOGO",
        extension: fileExtension(file),
      };

      setLogoImg(newImage);
      setData({ ...data, companyLogo: newImage, logoBase64: "Logo" });
    }
  };

  const handleAddFeaturedImage = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        // Check if the file size is greater than 2MB
        toast.error("Image size should be less than 2MB");
        setFormErrors({
          ...formErrors,
          featuredImage: { message: "File size should be less than 2MB" },
        });
        return;
      }
      if (!["image/jpg", "image/jpeg", "image/png"].includes(file.type)) {
        // Check if the file type is jpeg or png
        toast.error("Please upload a JPG, JPEG or PNG image");
        setFormErrors({
          ...formErrors,
          featuredImage: { message: "Please upload a JPG, JPEG or PNG image" },
        });
        return;
      }
      const base64 = await getBase64Image(file);
      let newImage = {
        base64: base64,
        name: file.name,
        optionId: "BANK_COVER_IMAGE",
        extension: fileExtension(file),
      };

      setFeatureImg(newImage);
      setData({
        ...data,
        featuredImage: newImage,
      });
    }
  };

  const handleInputChange = (name, value) => {
    // Check if the value is numeric
    if (/^\d*$/.test(value) || value === "") {
      // If the value is numeric or empty, update the state
      setData({
        ...data,
        [name]: value,
      });
      // Clear the form error for the input
      setFormErrors({
        ...formErrors,
        [name]: "",
      });
    } else {
      // If the value is not numeric, set a form error
      setFormErrors({
        ...formErrors,
        [name]: { message: "Please enter only numbers" },
      });
    }
  };

  useEffect(() => {
    if (bankTypes) {
      setBanks(bankTypes);
    }
  }, [bankTypes]);

  useEffect(() => {
    if (useCity?.data) {
      const newCityList = useCity?.data?.map((city) => ({
        id: city.id,
        name: city.name,
      }));

      setCityList(newCityList);
    }
  }, [useCity?.data]);

  useEffect(() => {
    setData({
      ...data,
      bankName: bankInfo?.bankName,
      bankCode: bankInfo?.bankCode,
      stateId: stateId,
      cityId: cityId,
    });
  }, [stateId, cityId, bankInfo]);

  useEffect(() => {
    if (stateId) {
      useCity.mutate(stateId);
    }
  }, [stateId]);

  useEffect(() => {
    document.title = `Giddaa - Company Details`;
  }, []);

  return (
    <>
      <div className="row d-flex justify-content-center align-items-center w-100 text-center">
        <h1 className="h2 sub-title">Company Details</h1>
        <h6 className="sub-title_2">Add details about your company</h6>
      </div>
      <div className="row mt-5">
        <div className="col-md-6 col-lg-4 mb-2 mb-md-4">
          <FormInput
            required
            labelName={"Organization Name"}
            placeholder={"eg. God Is Good Realty"}
            lefticonname={"semiDetachedDuplex"}
            error={formErrors?.companyName?.message}
            value={data.companyName}
            onChange={(name, value) => {
              setData({
                ...data,
                companyName: value,
              });
              setFormErrors({ ...formErrors, companyName: "" });
            }}
          />
        </div>
        <div className="col-md-6 col-lg-4 mb-2 mb-md-4">
          <FormInput
            required
            labelName={"Company Email"}
            type={"email"}
            lefticonname={"envolope"}
            placeholder={"john.anakson@gmail.com"}
            error={formErrors?.companyEmail?.message}
            value={data.companyEmail}
            onChange={(name, value) => {
              setData({
                ...data,
                companyEmail: value,
              });
              setFormErrors({ ...formErrors, companyEmail: "" });
            }}
          />
        </div>
        <div className="col-md-6 col-lg-4 mb-2 mb-md-4">
          <FormInput
            required
            labelName={"Company Phone Number"}
            type={"tel"}
            maxlength="11"
            placeholder={"eg. O9065718291"}
            lefticonname={"phone"}
            value={data.companyPhoneNumber}
            error={formErrors?.companyPhoneNumber?.message}
            onChange={(name, value) =>
              handleInputChange("companyPhoneNumber", value)
            }
            bordercolor={colors.grey}
          />
        </div>
        <div className="col-md-6 col-lg-4 mb-2 mb-md-4">
          <Dropdown
            required
            labelName={"State"}
            lefticonname="bank"
            options={states}
            placeholder={"eg. Abia"}
            error={formErrors?.stateId?.message}
            onChange={(e) => {
              handleSelectedState(e.target.value);
              setFormErrors({ ...formErrors, stateId: "" });
            }}
          />
        </div>
        <div className="col-md-6 col-lg-4 mb-2 mb-md-4">
          <Dropdown
            required
            labelName={"City"}
            lefticonname="bank"
            placeholder={"eg. Uturu"}
            isLoading={useCity?.isLoading}
            disabled={useCity?.data?.length < 0}
            options={cityList && cityList}
            error={formErrors?.cityId?.message}
            onChange={(e) => {
              handleSelectedCityId(e.target.value);
              setFormErrors({ ...formErrors, cityId: "" });
            }}
          />
        </div>
        <div className="col-md-6 col-lg-4 mb-2 mb-md-4">
          <FormInput
            required
            labelName={"Years In Operation"}
            placeholder={"eg. 4"}
            lefticonname="bank"
            value={data.companyYearsInExistence}
            error={formErrors?.companyYearsInExistence?.message}
            onChange={(name, value) => {
              const yearsInExistence = parseInt(value, 10);
              setData({
                ...data,
                companyYearsInExistence: isNaN(yearsInExistence)
                  ? ""
                  : yearsInExistence,
              });
              setFormErrors({ ...formErrors, companyYearsInExistence: "" });
            }}
            bordercolor={colors.grey}
            city={cityList}
          />
        </div>
        <div className="col-md-6 col-lg-4 mb-4 mb-md-2 mb-md-4">
          <Dropdown
            required
            labelName={"Bank Name"}
            lefticonname="bank"
            options={banks}
            placeholder={"eg. zenith bank"}
            error={formErrors?.bankName?.message}
            onChange={(e) => {
              handleSelectedBank(e.target.value);
              setFormErrors({ ...formErrors, bankName: "" });
            }}
          />
        </div>
        <div className="col-md-6 col-lg-4 mb-2 mb-md-4">
          <FormInput
            required
            type={"text"}
            maxlength="10"
            labelName={"Bank Account Number"}
            placeholder={"eg. 1234567890"}
            lefticonname={"bank"}
            error={formErrors?.companyBankAccountNumber?.message}
            value={data.companyBankAccountNumber}
            onChange={(name, value) =>
              handleInputChange("companyBankAccountNumber", value)
            }
          />
        </div>
        <div className="col-md-6 col-lg-4 mb-5 mb-md-0">
          <div className="row d-flex justify-content-end">
            <div className="col-md-6 mb-4 d-flex justify-content-center flex-column align-items-center position-relative">
              <label htmlFor="company_logo" className="form_label mb-2">
                Company Logo <span className="required">*</span>
              </label>
              <div className="company_logo_wrapper ">
                <div
                  className="company_logo"
                  onClick={() => imageRef?.current.click()}
                >
                  {logoImg?.base64 ? (
                    <img
                      src={`data:image/${logoImg.extension};base64,${logoImg.base64}`}
                      alt=""
                    />
                  ) : (
                    <Icon iconName="addImage" />
                  )}
                </div>
                <span className="form-error text-danger">
                  {formErrors?.logoBase64?.message}
                </span>
                <input
                  type="file"
                  ref={imageRef}
                  accept="image/*"
                  onChange={(e) => {
                    handleAddLogo(e);
                    setFormErrors({ ...formErrors, logoBase64: "" });
                  }}
                />
              </div>
            </div>
            <div className="col-md-6 mb-2 mb-md-4">
              <div className="color-pallete">
                <label
                  htmlFor="exampleColorInput"
                  className="form-label form_label text-center"
                >
                  Company Brand Color
                </label>
                <input
                  type="color"
                  className="form-control form-control-color form-control-lg color-picker"
                  title="Choose your color"
                  value={data.companyBrandColor}
                  onChange={(e) =>
                    setData({ ...data, companyBrandColor: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 mb-4">
          <MultitextInput
            required
            label={"Company Address"}
            placeholder={"Company address"}
            lefticonname={"bank"}
            value={data.companyAddress}
            error={formErrors?.companyAddress?.message}
            onChange={(e) => {
              setData({ ...data, companyAddress: e.target.value });
              setFormErrors({ ...formErrors, companyAddress: "" });
            }}
          />
        </div>
        <div className="col-md-6 col-lg-4 mb-2 mb-md-4">
          <MultitextInput
            label="Company Description"
            value={data.companyDescription}
            placeholder={
              "eg. We are a realty firm located in Abuja and has developed over 23 estate all around Nigeria."
            }
            onChange={(e) =>
              setData({ ...data, companyDescription: e.target.value })
            }
            error={formErrors?.companyDescription?.message}
          />
        </div>

        <div className="col-md-6 col-lg-4 mb-md-3 mt-3 mt-md-0">
          <label
            htmlFor="featuredImage"
            className="form-label ml-4 custom-form-label fw-bold"
          >
            Cover Image <span className="required">*</span>
          </label>
          <div>
            <div
              className="company-img-preview"
              onClick={() => coverImageRef?.current?.click()}
              role="button"
            >
              {featureImg?.base64 ? (
                <>
                  <img
                    src={`data:image/${featureImg.extension};base64,${featureImg.base64}`}
                    alt="companyCoverPhoto"
                    className="w-100"
                  />
                  <span className="delete-company-image-btn me-3">
                    <Button
                      btnText={<Icons iconName={"delete"} />}
                      bordercolor={colors.black}
                      borderHover={colors.red}
                      backgroundcolor={colors.white}
                      backgroundHover={colors.dangerLight}
                      colorHover={colors.danger}
                      color={colors.danger}
                      iconcolorHover={colors.danger}
                      padding={"0"}
                      bordersize={2}
                      btntextcolor={colors.red}
                      width={"40px"}
                      height={"43px"}
                      type={"button"}
                      onBtnClick={() => handleDeleteCoverImage()}
                    />
                  </span>
                </>
              ) : (
                <>
                  {" "}
                  <Icons iconName="addImage" className={"empty-img-svg"} />
                  <input
                    type="file"
                    ref={coverImageRef}
                    className="input-image"
                    accept="image/*"
                    onChange={(e) => {
                      handleAddFeaturedImage(e);
                      setFormErrors({ ...formErrors, featuredImage: "" });
                    }}
                    name="featuredImage"
                  />
                </>
              )}
              <span className="form-error text-danger">
                {formErrors?.featuredImage?.message}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDetails;
