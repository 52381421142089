import React, { useEffect, useState } from "react";
import CenteredText from "../../../../Components/CenteredText";
import InfoTable from "../../../../Components/Common/InfoTable";
import { colors, houseColumns } from "../../../../utils/constants";
import { useNavigate } from "react-router-dom";
import Button from "../../../../Components/Common/Button";
import useGetAcceptedHouses from "./hooks/useGetAcceptedHouses";
import TableLoader from "../../../../Components/Common/TableLoader";
import PendingPropertyModal from "../Modals/PendingHouseModal";

const HouseCollection = ({ pendingCount, refetchPendingCount }) => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState(1);

  const [openPropertyModal, setOpenPropertyModal] = useState(false);

  const {
    data: acceptedHouseCollection,
    isLoading,
    refetch,
    isRefetching,
  } = useGetAcceptedHouses();

  useEffect(() => {
    if (pagination) {
      refetch();
    }
  }, [pagination, refetch]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mt-5">
        <h3>
          Houses - (<span>{acceptedHouseCollection?.totalRecords ?? 0}</span>)
        </h3>
        <div className="d-flex justify-content-end me-2">
          <div className="ps-2 position-relative">
            {pendingCount ? (
              <span className="bage pending_count">{pendingCount}</span>
            ) : null}
            <Button
              btnText={"Add House"}
              type={"button"}
              onBtnClick={() => setOpenPropertyModal(true)}
            />
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="mt-4">
          <TableLoader />
        </div>
      ) : acceptedHouseCollection?.data?.length ? (
        <div className="mb-4">
          <InfoTable
            columns={houseColumns(isRefetching)}
            dataCollection={acceptedHouseCollection?.data}
            loadingTable={isRefetching}
            paginationTotalRows={acceptedHouseCollection?.totalRecords}
            onChangePage={(page) => setPagination(page)}
            pointerOnHover
            highlightOnHover
            onRowClicked={(row) =>
              navigate(
                `/properties/partnered-developer-properties/house/${row.id}`
              )
            }
          />
        </div>
      ) : (
        <div className="mt-5">
          <CenteredText title="No Houses Added Yet">
            If a house exists in an estate, create an estate and add the house
            under that estate. Houses added under estates will populate here.
            You can also add an individual house (a house not in an estate) by
            clicking the “add house” button above.
          </CenteredText>
        </div>
      )}

      {openPropertyModal ? (
        <PendingPropertyModal
          pendingCount={pendingCount}
          refetchPendingCount={refetchPendingCount}
          refetchAcceptedProperty={refetch}
          openPropertyModal={openPropertyModal}
          setOpenPropertyModal={setOpenPropertyModal}
        />
      ) : null}
    </>
  );
};

export default HouseCollection;
