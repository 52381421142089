import { isNil, arryEmpty } from "../utils/generalUtils";
const emailRegex =
  /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/;
const organization = {
  companyName: "",
  companyAddress: "",
  companyEmail: "",
  companyPhoneNumber: "",
  companyBankAccountNumber: "",
  bankName: "",
  companyLogo: { base64: "", optionId: "", extension: "" },
  logoBase64: "",
  featuredImage: "",
  companyBrandColor: "#1548EF",
  companyDescription: "",
  companyYearsInExistence: "",
  invitationRequests: [],
  documents: [],
  CACVerification: "",
  TINVerification: "",
  cacIsValidated: false,
  tinIsValidated: false,
  requiredDocuments: false,
  agentCharge: "",
};

export const validateCompanyDetails = (
  details = organization,
  setError,
  error
) => {
  if (isNil(details.companyName)) {
    setError({
      ...error,
      companyName: { message: "Organization Name is required" },
    });
    return false;
  }
  if (!isNil(details.companyName) && details.companyName.length <= 3) {
    setError({
      ...error,
      companyName: {
        message: "Organization name should be more than 3 characters",
      },
    });
    return false;
  }
  if (isNil(details.companyEmail)) {
    setError({
      ...error,
      companyEmail: { message: "Organization Email is required" },
    });
    return false;
  }
  if (!isNil(details.companyEmail) && !details.companyEmail.match(emailRegex)) {
    setError({
      ...error,
      companyEmail: { message: "Not a valid email address" },
    });
    return false;
  }
  if (isNil(details.companyPhoneNumber)) {
    setError({
      ...error,
      companyPhoneNumber: { message: "Organization Phone number is required" },
    });
    return false;
  }
  if (isNil(details.stateId)) {
    setError({
      ...error,
      stateId: {
        message: "State is required",
      },
    });
    return false;
  }
  if (isNil(details.cityId)) {
    setError({
      ...error,
      cityId: {
        message: "City is required",
      },
    });
    return false;
  }
  if (isNil(details.companyYearsInExistence)) {
    setError({
      ...error,
      companyYearsInExistence: {
        message: "Provide operational year of Organization",
      },
    });
    return false;
  }
  if (isNil(details.bankName)) {
    setError({
      ...error,
      bankName: { message: "Organization Bank Name is required" },
    });
    return false;
  }
  if (isNil(details.companyBankAccountNumber)) {
    setError({
      ...error,
      companyBankAccountNumber: {
        message: "Organization Bank Account Number is required",
      },
    });
    return false;
  }

  if (isNil(details.logoBase64)) {
    setError({
      ...error,
      logoBase64: {
        message: "Company Logo is required",
      },
    });
    return false;
  }
  if (isNil(details.companyAddress)) {
    setError({
      ...error,
      companyAddress: {
        message: "Company Address is required",
      },
    });
    return false;
  }
  if (isNil(details.featuredImage)) {
    setError({
      ...error,
      featuredImage: {
        message: "Featured Iamge is required",
      },
    });
    return false;
  }

  return true;
};

// export const validateMortageBank = (
//   details = organization,
//   setError,
//   error
// ) => {
//   if (arryEmpty(details.invitationRequests)) {
//     setError({
//       ...error,
//       invitationRequests: {
//         message: "Please select at least one mortage bank",
//       },
//     });
//     return false;
//   }
//   return true;
// };

export const validateDocumentSubmission = (
  details = organization,
  setError,
  error
) => {
  // if (isNil(details.CACVerification)) {
  //   setError({
  //     ...error,
  //     verifyCAC: {  
  //       message: "Corporate Affairs Commission verification is required",
  //     },
  //   });
  //   return false;
  // }

  // if (isNil(details.TINVerification)) {
  //   setError({
  //     ...error,
  //     verifyTIN: {
  //       message: "Tax Identification Number verification is required",
  //     },
  //   });
  //   return false;
  // }

  if (!details.requiredDocuments) {
    setError({
      ...error,
      requiredDoc: { message: "This document is required" },
    });
    return false;
  }

  return true;
};

export default organization;
