import React, { useState, useEffect } from "react";
import { saveAs } from "file-saver";
import Modal from "../../Components/Common/Modal";
import DocumentHeader from "./DocumentHeader";
import {
  BANK_STATEMENT,
  BIRTH_CERTIFICATE,
  EMPLOYMENT_LETTER,
  IDENTIFICATION,
  CREDIT_CHECK,
} from "./utils/constants";
import EmploymentLetter from "./Documents/EmploymentLetter";
import BankStatement from "./Documents/BankStatement";
import BirthCertificate from "./Documents/BirthCertificate";
import IdentificationDocument from "./Documents/IdentificationDocument";
import CreditCheck from "./Documents/CreditCheck";
import { toSentenceCase } from "../../utils/generalUtils";
import { bankStatementMockData } from "./Documents/mockData";
import CheckInput from "../../Components/Common/CheckInput";
import { colors } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import useAxiosBase from "../../hooks/useAxiosBase";
import { toast } from "react-toastify";
import DocumentDownload from "../../Components/Common/DocumentDownload";
import Button from "../../Components/Common/Button";
import useGetBankStatements from "./hooks/useGetBankStatements";
import { formattedPrice } from "../../utils/constants";
import { FaRegCircleQuestion } from "react-icons/fa6";

const Reports = (props) => {
  const {
    view,
    close,
    name,
    columns,
    columnData,
    documentId,
    appId,
    customerName,
    docValidation,
    docUrl,
    application,
  } = props;
  const [subTitle, setSubTitle] = useState("");
  const [documentInformation, setDocumentInformation] = useState({
    title: `${customerName} - ${toSentenceCase(name)}`,
  });

  const { getRequest } = useAxiosBase();
  const navigate = useNavigate();

  const { data: getBankStatement, isLoading } = useGetBankStatements(appId);

  const [bankStatement, setBankStatement] = useState();
  const [showAlteredDescription, setShowAlteredDescription] = useState();
  const [alteredDescriptionText, setAlteredDescriptionText] = useState();

  const [validationIsLoading, setValidationIsLoading] = useState(false);

  const handleValidate = async (appId, documentId) => {
    setValidationIsLoading(true);
    await getRequest({
      url: `/developer/application/${appId}/validate-document/${documentId}`,
    })
      .then((res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success(res?.data?.value?.message);
          setTimeout(navigate(0), 1500);
          return;
        }
        toast.error("Validation Failed!");
      })
      .finally(() => setValidationIsLoading(false));
  };

  // FORMAT THE START DATE, END DATE AND DATE PERIOD TO DISPLAY
  const startDate = new Date(bankStatement?.startDate);
  const endDate = new Date(bankStatement?.endDate);

  const startMonth = startDate.toLocaleDateString("en-US", {
    month: "long",
    year: "numeric",
  });
  const endMonth = endDate.toLocaleDateString("en-US", {
    month: "long",
    year: "numeric",
  });

  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  const durationInMonths =
    (endYear - startYear) * 12 + endDate.getMonth() - startDate.getMonth() + 1;
  // FORMAT THE START DATE, END DATE AND DATE PERIOD TO DISPLAY

  const renderComponent = (name) => {
    switch (name) {
      case "Employment Letter":
        return (
          <EmploymentLetter
            columns={columns}
            columnData={columnData}
            applicationId={appId}
          />
        );
      case "Bank Statement":
        return (
          <BankStatement
            applicationId={appId}
            customerName={customerName}
            application={application}
            bankStatement={bankStatement}
            durationInMonths={durationInMonths}
          />
        );
      case BIRTH_CERTIFICATE:
        return <BirthCertificate />;
      case IDENTIFICATION:
        return <IdentificationDocument />;
      case "Credit Check":
        return <CreditCheck applicationId={appId} />;
      default:
        return <CreditCheck applicationId={appId} />;
    }
  };

  useEffect(() => {
    if (getBankStatement) {
      setBankStatement(getBankStatement?.data?.insightsResponse);
    }
  }, [getBankStatement]);
  return (
    <Modal
      show={view}
      closeModalIcon={true}
      closeModal={close}
      width={"100%"}
      height={"100%"}
      crossLine={false}
      titleColor={colors.darkGreen}
      titleFont={"Millik"}
      titleFontSize={"38px"}
      component={
        <DocumentHeader
          leftTitle={
            name === "Employment Letter" ? (
              <CheckInput
                checked
                labelColor={colors.darkGreen}
                checkTick={true}
                label={"EMPLOYMENT VALIDATED"}
                width={"23px"}
                height={"23px"}
                bcolor={colors.darkGray}
                bordercolor={colors.grey}
                onChange={() => {}}
              />
            ) : name === "Bank Statement" ? (
              <div
                className="p-3"
                style={{
                  border: "1px solid #335F32",
                  borderRadius: "8px",
                  backgroundColor: "#F8F8F8",
                  display: "inline-block",
                }}
              >
                <h5 className="text-center text-success">
                  N{bankStatement?.accountBalance.toLocaleString("en-US")}{" "}
                  {/* Updated here */}
                </h5>
                <p className="text-center m-0" style={{ fontSize: "0.7rem" }}>
                  ACCOUNT BALANCE
                </p>
              </div>
            ) : null
          }
          title={`${customerName} - ${toSentenceCase(name)}`}
          subTitle={
            name === "Employment Letter" ? (
              "WORKS AT NATIONAL COMMUNICATION COMMISSION"
            ) : name === "Bank Statement" ? (
              <div>
                {bankStatement?.processingStatus === "ALTERED" ? (
                  <div>
                    <p
                      className="mb-2 me-2"
                      style={{
                        border: `1px solid ${colors.danger}`,
                        backgroundColor: colors.dangerLight,
                        color: colors.danger,
                        fontSize: "13px",
                        borderRadius: "20px",
                        display: "inline-block",
                        padding: "3px 10px",
                      }}
                    >
                      {"This Statement has been altered"}
                    </p>
                    <span
                      className="position-relative"
                      style={{ cursor: "pointer" }}
                    >
                      {showAlteredDescription && (
                        <div
                          className="animated toolTipText"
                          style={{ left: "-15rem", bottom: "-6.5rem", width: "20rem" }}
                        >
                          <p className="description-text m-0">
                            This statement seems to have been altered or
                            modified by the customer. The financial data in the
                            statement isn’t dependable.
                          </p>
                        </div>
                      )}
                      <FaRegCircleQuestion
                        size="1rem"
                        onMouseEnter={() => setShowAlteredDescription(true)}
                        onMouseLeave={() => setShowAlteredDescription(false)}
                      />
                    </span>
                  </div>
                ) : (
                  <div>
                    <p
                      className="mb-2 me-2"
                      style={{
                        border: `1px solid ${colors.darkGreen}`,
                        backgroundColor: colors.lightGreen,
                        color: colors.darkGreen,
                        fontSize: "13px",
                        borderRadius: "20px",
                        display: "inline-block",
                        padding: "3px 10px",
                      }}
                    >
                      This Statement has not been altered
                    </p>
                    <span
                      className="position-relative"
                      style={{ cursor: "pointer" }}
                    >
                      {showAlteredDescription && (
                        <div
                          className="animated toolTipText"
                          style={{ left: "-15rem", bottom: "-5rem", width: "20rem" }}
                        >
                          <p className="description-text m-0">
                            This statement has not been altered or modified by
                            the customer. The financial data here is dependable.
                          </p>
                        </div>
                      )}
                      <FaRegCircleQuestion
                        size="1rem"
                        onMouseEnter={() => setShowAlteredDescription(true)}
                        onMouseLeave={() => setShowAlteredDescription(false)}
                      />
                    </span>
                  </div>
                )}
                <p style={{ fontSize: "13px", fontWeight: "normal" }}>
                  {startMonth} - {endMonth} —{" "}
                  <span className="fw-bold">{durationInMonths} Months</span>
                </p>
              </div>
            ) : name === "Credit Check" ? (
              "CREDIT REPORT & SCORE AS OF JULY 14, 2023."
            ) : (
              "BIRTH CERTIFICATE ANALYSIS"
            )
          }
          rightTitle={
            name === "Employment Letter" || name === "Bank Statement" ? (
              <div className="d-flex align-items-center gap-4">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => saveAs(docUrl, `${name}`)}
                >
                  <DocumentDownload
                    name={"Download Document"}
                    icon={"file"}
                    textColor={colors.green}
                  />
                </div>
              </div>
            ) : null
          }
          documentId={documentId}
          applicationId={appId}
          showValidate={name === "Credit Check" ? false : true}
        />
      }
    >
      {renderComponent(name)}
    </Modal>
  );
};

export default Reports;
