import React, { useCallback, useEffect, useMemo, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import {
  columnData,
  columns,
  displayValidationValue,
  stringDisplayValidation,
} from "../utils/constants";
import DocumentTable from "../../../Components/DocumentTable";
import Reports from "../Reports";
import Modal from "../../../Components/Common/Modal";
import { toast } from "react-toastify";
// import useReviseDocument from "../hooks/useReviseDocument";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { useNavigate } from "react-router-dom";
import MultitextInput from "../../../Components/Common/MultitextInput";
import { convertToTitleCase } from "../../../utils/constants";
import { applicationStatusIndex } from "../utils/applicationStatus";

const DocumentValidation = ({
  appId,
  application,
  documents,
  customer,
  refetch,
  isRefetching,
}) => {
  const { postRequest, getRequest } = useAxiosBase();
  const [name, setName] = useState("");
  const [docUrl, setDocUrl] = useState("");
  const [comment, setComment] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [validateModalShow, setValidateModalShow] = useState(false);
  const [inValidateModalShow, setInValidateModalShow] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [btnIsLoading, setBtnIsLoading] = useState(false);
  const [documentId, setDocumentId] = useState();
  const [validationIsLoading, setValidationIsLoading] = useState(false);
  const [docValidation, setDocValidation] = useState({
    docId: "",
    validated: false,
  });
  const [customerName, setCustomerName] = useState(
    `${customer?.firstName} ${customer?.lastName}`
  );
  const [offerLetterRevision, setOfferLetterRevision] = useState();
  const [responseMessage, setResponseMessage] = useState();
  const [responseInvalidateMessage, setResponseInvalidateMessage] = useState();
  // const sendReviseDocument = useReviseDocument();
  const navigate = useNavigate();
  const [handleCloseModal, setHandleCloseModal] = useState(false);
  const [handleCloseInvalidateModal, setHandleCloseInvalidateModal] =
    useState(false);
  //resetInvalidateModal.
  useMemo(() => {
    if (handleCloseModal && !isRefetching) {
      setHandleCloseModal(false);

      setValidateModalShow(false);
      setValidationIsLoading(false);
      setDocValidation({
        docId: documentId,
        validated: !docValidation.validated,
        previous: document?.isValidated,
      });
      setResponseMessage("");
      toast.success(responseMessage, { toastId: "validate" });
    }
  }, [isRefetching]);

  useMemo(() => {
    if (handleCloseInvalidateModal && !isRefetching) {
      setHandleCloseInvalidateModal(false);
      setInValidateModalShow(false);
      setValidationIsLoading(false);

      setDocValidation({
        docId: documentId,
        validated: !docValidation.validated,
        previous: document?.isValidated,
      });
      toast.success(responseInvalidateMessage, { toastId: "Invalidate" });
      setResponseInvalidateMessage("");
    }
  }, [isRefetching]);

  const handleViewDocument = (name, docId, cusName, docUrl) => {
    setName(name);
    setCustomerName(cusName);
    setDocumentId(docId);
    setShowModal(true);
    setDocUrl(docUrl);
  };

  const handleRevise = (appId, docName, applicationDocumentId) => {
    setModalShow(true);
    setName(docName);
    setDocumentId(applicationDocumentId);
  };

  const handleSendRevisions = async (appId, docId) => {
    setDisableBtn(true);
    setBtnIsLoading(true);
    await postRequest({
      url: `/developer/application/${appId}/revise-document/${docId}`,
      data: {
        comment: comment,
      },
    }).then((res) => {
      if (res?.data?.value?.statusCode === 200) {
        toast.success("Revision sent successfully");

        refetch();
        setModalShow(false);
        setComment("");
        navigate(0);
        return;
      }
      setComment("");
      setBtnIsLoading(false);
      toast.error(res?.data?.value?.message);
    });
  };

  const handleValidate = async (appId, documentId) => {
    setValidationIsLoading(true);
    await getRequest({
      url: `/developer/application/${appId}/validate-document/${documentId}`,
    }).then((res) => {
      if (res?.data?.value?.statusCode === 200) {
        setResponseMessage(res?.data?.value?.message);
        setHandleCloseModal(true);
        setValidateModalShow(false);
        refetch();
        // resetModal();
        return;
      }
      toast.error("Validation Failed!");
      setValidateModalShow(false);
    });
  };

  const handleInValidate = async (appId, documentId) => {
    setValidationIsLoading(true);
    await getRequest({
      url: `/developer/application/${appId}/invalidate-document/${documentId}`,
    }).then((res) => {
      if (res?.data?.value?.statusCode === 200) {
        setResponseInvalidateMessage(res?.data?.value?.message);
        setHandleCloseInvalidateModal(true);
        setInValidateModalShow(false);
        refetch();
        return;
      }
      toast.error("Could not invalidate document, operation failed!");
      setInValidateModalShow(false);
    });
  };

  useEffect(() => {
    if (comment?.length > 10) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [comment]);

  useEffect(() => {
    if (application?.documents) {
      const appDoc = application?.documents?.find(
        (doc) => doc.document.optionId === "OFFER_LETTER_DOCUMENT"
      );

      setOfferLetterRevision(appDoc);
      if (application?.documents?.length > 0) {
        application?.documents?.map((x) => {
          if (x?.isValidated) {
            setDocValidation({
              docId: x?.applicationDocumentId,
              validated: true,
            });
          }
        });
      }
    }
  }, [application]);

  return (
    <div className="pe-0 ps-1 mb-5 mt-4">
      {/* {requiredDocuments?.length > 0 && documents?.length === 0 ? (
        <div
          style={{ borderStyle: "double" }}
          className="row justify-items-center pb-3 alert alert-danger blockquote me-5 mt-2 mb-4 callout callout-success border-5"
        >
          <span className="h5 fw-bold text-center">NO DOCUMENT PROVIDED</span>
          <span className="">
            <h4 className="h6 text-uppercase text-center">
              This application contains a plan that requires{" "}
              {
                JSON.parse(application?.mortgageDetails)?.RequiredDocuments
                  ?.length
              }{" "}
              documents. But none was provided.
            </h4>
          </span>
        </div>
      ) : (
        ""
      )} */}

      {application?.documents?.length > 0 && (
        <>
         <div className="w-100">
        <div className="p-0 me-0 df">
          <h5>
            {" "}
            {application?.status === "LEGAL_SEARCH" ||
            application?.status === "OFFER_LETTER_REQUEST" ||
            application?.status === "IDENTITY_VERIFICATION" ||
            application?.status === "DOCUMENT_SUBMISSION"
              ? ""
              : "Credit &"}
            Documents {application?.status === "PENDING" && "Verification"}
          </h5>
          {application?.status === "LEGAL_SEARCH" ||
          application?.status === "OFFER_LETTER_REQUEST" ||
          application?.status === "IDENTITY_VERIFICATION" ||
          application?.status === "DOCUMENT_SUBMISSION" ? (
            ""
          ) : (
            <div className="col-md-6 progress-bar">
              <ProgressBar
                completed={displayValidationValue(documents)}
                customLabel={stringDisplayValidation(documents)}
                labelAlignment="center"
                bgColor="#0A7E32"
                height="20px"
                labelSize="12px"
                transitionDuration="2s"
                animateOnRender={true}
                customLabelStyles={{
                  paddingLeft: "40px",
                  fontFamily: '"Red Hat Display", sans-serif',
                }}
              />
              <div className="stats d-flex justify-content-start gap-3 py-2">
                <span
                  style={{ fontFamily: '"Red Hat Display", sans-serif' }}
                  className="item-value p-0"
                >
                  {documents?.length} items &bull;{" "}
                </span>
                <span
                  style={{ fontFamily: '"Red Hat Display", sans-serif' }}
                  className="item-value p-0"
                >
                  {documents?.filter((doc) => doc.isValidated === true)?.length}{" "}
                  validated &bull;
                </span>
                <span
                  style={{ fontFamily: '"Red Hat Display", sans-serif' }}
                  className="item-value p-0"
                >
                  {
                    documents?.filter((docs) => docs?.revisions?.length > 0)
                      ?.length
                  }{" "}
                  sent for revision
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="pe-0 me-0 w-100">
          <>
            {application?.status === "LEGAL_SEARCH" ||
            application?.status === "OFFER_LETTER_REQUEST" ||
            application?.status === "IDENTITY_VERIFICATION" ||
            application?.status === "DOCUMENT_SUBMISSION" ? (
              ""
            ) : (
              <DocumentTable
                application={application}
                icon={"creditCheck"}
                documentName={"Credit Check"}
                isValidated={true}
                hasButton={false}
                handleOnClick={() =>
                  handleViewDocument(
                    "Credit Check",
                    null,
                    `${customer?.firstName} ${customer?.lastName}`,
                    `${document?.document?.document}`
                  )
                }
              />
            )}

            {documents.map((document) => (
              <>
                <DocumentTable
                  application={application}
                  key={document?.applicationDocumentId}
                  documentName={convertToTitleCase(
                    document?.document?.option?.name
                  )}
                  icon={document.icon}
                  hasButton={true}
                  handleOnClick={() =>
                    handleViewDocument(
                      convertToTitleCase(document?.document?.option?.name),
                      document?.applicationDocumentId,
                      `${customer?.firstName} ${customer?.lastName}`,
                      `${document?.document?.document}`
                    )
                  }
                  handleRevise={() =>
                    handleRevise(
                      appId,
                      convertToTitleCase(document?.document?.option?.name),
                      document?.applicationDocumentId
                    )
                  }
                  handleViewFeedback={() => setShowFeedbackModal(true)}
                  handleValidate={() => {
                    setValidateModalShow(true);
                    setDocumentId(document?.applicationDocumentId);
                  }}
                  handleInValidate={() => {
                    setInValidateModalShow(true);
                    setDocumentId(document?.applicationDocumentId);
                  }}
                  document={document}
                  docValidation={docValidation}
                  isValidated={document?.isValidated && docValidation.validated}
                  applicationStatus={application.status}
                  url={document?.document?.document}
                  userName={`${customer?.firstName?.toLowerCase()}_${customer?.lastName?.toLowerCase()}_${document?.document?.name
                    ?.replace(/\s/gm, "_")
                    ?.toLowerCase()}`}
                />
              </>
            ))}
          </>
        </div>
      </div>
      <Reports
        view={showModal}
        close={() => setShowModal(false)}
        name={name}
        columns={columns}
        columnData={columnData}
        docuementType={name}
        documentId={documentId}
        appId={appId}
        customerName={customerName}
        docValidation={docValidation}
        docUrl={docUrl}
        application={application}
      />

      <Modal
        title={"Offer Letter Review and Feedback"}
        show={showFeedbackModal}
        closeModal={() => setShowFeedbackModal(false)}
        titleFontSize={"38px"}
        width={"100%"}
        height={"100%"}
        crossLine={false}
        closeModalIcon
        btnHeight={45}
      >
        <p className="text-center m-0 px-3 mt-4">
          {offerLetterRevision?.revisions?.[0]?.comment}
        </p>
      </Modal>

      <Modal
        title={`Specific Revision - ${customerName}`}
        show={modalShow}
        closeModal={() => {
          setComment("");
          setModalShow(false);
        }}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Send Revision"}
        btnHeight={45}
        closeModalIcon={true}
        width={"100%"}
        height={"100%"}
        crossLine={false}
        titleFontSize={"38px"}
        confirmModal={() => handleSendRevisions(appId, documentId)}
        isBtnLoading={btnIsLoading}
        disabled={disableBtn}
      >
        <div className="row">
          <p className="m-0 text-center">{`What specific revisions would you like ${customerName} to make to this document? Please make revisions as specific as possible to address the issues you’ve identified.`}</p>
          <div className="col-md-9 m-auto">
            <MultitextInput
              value={comment}
              placeholder={`Please provide a detailed revisions for ${customerName}.`}
              onChange={(e) => setComment(e.target.value)}
              row={"6"}
            />
          </div>
        </div>
      </Modal>

      <Modal
        show={validateModalShow}
        title={"Validate Document"}
        subTitle={"Are you sure you want to validate this document?"}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Validate"}
        closeModal={() => setValidateModalShow(false)}
        confirmModal={() => handleValidate(appId, documentId)}
        isBtnLoading={validationIsLoading}
        disabled={validationIsLoading}
      />

      <Modal
        show={inValidateModalShow}
        title={"Invalidate Document"}
        subTitle={"Are you sure you want to invalidate this document?"}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Invalidate"}
        closeModal={() => setInValidateModalShow(false)}
        confirmModal={() => handleInValidate(appId, documentId)}
        isBtnLoading={validationIsLoading}
        disabled={validationIsLoading}
      />
        </>
      )}
    </div>
  );
};

export default DocumentValidation;
