import React, { useEffect, useRef, useState } from "react";
import Button from "../../../Components/Common/Button";
import { getBase64Image, fileExtension } from "../../../utils/imageUtils";
import { colors } from "../../../utils/constants";
import { toast } from "react-toastify";
import FormInput from "../../../Components/Common/FormInput";
import useGetDocuments from "../../../hooks/useGetDocuments";
import Spinner from "../../../Components/Spinner";
import CenteredText from "../../../Components/CenteredText";
import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import useAuth from "../../../hooks/useAuth";

const DocumentSubmission = ({ data, setData, formErrors, setFormErrors }) => {
  const { authUser } = useAuth();
  const organizationName = authUser?.user?.organization?.name;

  const { data: getDeveloperDocuments, isLoading } = useGetDocuments();

  const { getRequest } = useAxiosBase();

  const [files, setFiles] = useState(data.documents);
  const [docIndex, setDocIndex] = useState();

  const fileRef = useRef();
  // const redanRef = useRef();

  const [developerDocuments, setDeveloperDocuments] = useState([]);
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        // Check if the file size is greater than 2MB
        toast.error("File size should be less than 2MB");
        setFormErrors({
          ...formErrors,
          certificateDoc: { message: "File size should be less than 2MB" },
        });
        return;
      }
      if (file.type !== "application/pdf") {
        // Check if the file type is not PDF
        toast.error("Please upload a PDF file");
        setFormErrors({
          ...formErrors,
          certificateDoc: { message: "Please upload a PDF file" },
        });
        return;
      }

      const base64 = await getBase64Image(file);
      let newDocument = {
        base64: base64,
        id: developerDocuments[docIndex].id,
        optionId: developerDocuments[docIndex].id,
        optionTypeId: developerDocuments[docIndex].optionTypeId,
        extension: fileExtension(file),
        name: file.name,
      };

      // Update the state of the document at the specified docIndex
      setDeveloperDocuments((prevDocuments) => {
        const updatedDocuments = [...prevDocuments];
        updatedDocuments[docIndex] = {
          ...updatedDocuments[docIndex],
          isUploaded: true,
          fileName: file.name,
        };
        return updatedDocuments;
      });

      setFiles([...files, newDocument]);
      setData((prevData) => ({
        ...prevData,
        documents: [...prevData.documents, newDocument],
      }));
    }
  };

  const handleDeleteFile = (id) => {
    const fileIndex = files.findIndex((file) => file.id === id);

    if (fileIndex !== -1) {
      const updatedFiles = [...files];
      updatedFiles.splice(fileIndex, 1);
      setFiles(updatedFiles);
      setData({ ...data, documents: updatedFiles });

      // Update developerDocuments for the deleted file
      const updatedDeveloperDocuments = developerDocuments.map((doc) => {
        if (doc.id === id) {
          return {
            ...doc,
            isUploaded: false,
            fileName: "",
          };
        }
        return doc;
      });

      setDeveloperDocuments(updatedDeveloperDocuments);
      setData((prevData) => ({
        ...prevData,
        documents: updatedFiles,
      }));
    }
  };

  // HANDLE CAC VERIFICATION
  const [cacIsValidated, setCacIsValidated] = useState(false);
  const [cacIsloading, setCacIsloading] = useState(false);

  const verifyCAC = useMutation((cacNumber) => {
    setCacIsloading(true);

    return getRequest({
      url: `public/verify-cac/${cacNumber}`,
      data: cacNumber,
    }).then((res) => {
      if ([400, 401, 413].includes(res?.response?.data?.statusCode)) {
        toast.error(res?.response?.data?.value?.message);
        setCacIsloading(false);
        return;
      }
      if (res?.data?.value?.statusCode === 200) {
        toast.success(res?.data?.value?.message);
        setData({
          ...data,
          cacIsValidated: true,
          cac: cacNumber,
        });
        setCacIsloading(false);
        return;
      }
      setCacIsloading(false);
      return res.data;
    });
  });

  // HANDLE TIN VERIFICATION
  const [tinIsValidated, setTinIsValidated] = useState(false);
  const [tinIsloading, setTinIsloading] = useState(false);

  const verifyTIN = useMutation((tinNumber) => {
    setTinIsloading(true);

    return getRequest({
      url: `public/verify-tin/${tinNumber}`,
      data: tinNumber,
    }).then((res) => {
      if ([400, 401, 413].includes(res?.response?.data?.statusCode)) {
        toast.error(res?.response?.data?.value?.message);
        setTinIsloading(false);
        return;
      }
      if (res?.data?.value?.statusCode === 200) {
        toast.success(res?.data?.value?.message);
        setData({
          ...data,
          tinIsValidated: true,
          tin: tinNumber,
        });
        setTinIsloading(false);
        return;
      }
      setTinIsloading(false);
      return res.data;
    });
  });

  useEffect(() => {
    if (getDeveloperDocuments) {
      const updatedDocuments = getDeveloperDocuments.map((doc) => ({
        id: doc.id,
        name: doc.name,
        fileName: "",
        isRequired: doc.isRequired,
        isUploaded: false,
        identifier: doc.identifier,
        description: doc.description,
        optionId: doc.id,
        optionTypeId: doc.optionTypeId,
      }));
      setDeveloperDocuments(updatedDocuments);
    }
  }, [getDeveloperDocuments]);

  useEffect(() => {
    // Check if all required documents are uploaded
    const allRequiredUploaded = developerDocuments.every(
      (doc) => !doc.isRequired || doc.isUploaded
    );

    setData((data) => ({
      ...data,
      requiredDocuments: allRequiredUploaded,
    }));
  }, [developerDocuments, setData]);

  useEffect(() => {
    document.title = `Giddaa - Document Submission`;
  }, []);


  return (
    <>
      <div className="row d-flex justify-content-center w-100 text-center">
        <h1 className="h2 sub-title">Documents & Verification</h1>
        <h6 className="sub-title_2">
          Submit documents and verify your status as a recognized property
          developer in Nigeria.{" "}
        </h6>
      </div>

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="row p-0">
            <h6
              className="fw-bold mt-5 mb-3"
              style={{ color: colors.darkGreen }}
            >
              VERIFICATION
            </h6>
            <div className="col-md-6 col-lg-4 position-relative">
              <FormInput
                labelName={"Corporate Affairs Commission"}
                placeholder={"eg. RC123456"}
                value={data.CACVerification}
                disabled={data.cacIsValidated}
                onChange={(name, value) => {
                  setData({
                    ...data,
                    CACVerification: value,
                  });
                  setFormErrors({ ...formErrors, verifyCAC: "" });
                }}
                error={formErrors?.verifyCAC?.message}
              />
              {!formErrors?.verifyCAC?.message && (
                <div>
                  {data.cacIsValidated ? (
                    <div
                      className=" position-absolute"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.3rem",
                        bottom: "-0.8rem",
                        left: "1rem",
                        color: colors.darkGreen,
                      }}
                    >
                      <img src="/verified.png" width={"19rem"}></img>
                      <p className="fw-bold m-0 mt-1">Validated</p>
                    </div>
                  ) : (
                    <div
                      className=" position-absolute"
                      style={{
                        bottom: "-1.5rem",
                        left: "1rem",
                        color: colors.darkGreen,
                      }}
                    >
                      {data.CACVerification?.length > 6 && (
                        <Button
                          btnText={"Submit"}
                          width={"5.5rem"}
                          height={"2rem"}
                          isLoading={cacIsloading}
                          disabled={cacIsloading || data.cacIsValidated}
                          padding={"0"}
                          borderWidth={"4px"}
                          spinnerWidth={"1rem"}
                          spinnerHeight={"1rem"}
                          onBtnClick={() =>
                            verifyCAC.mutate(data.CACVerification)
                          }
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="col-md-6 col-lg-4 position-relative">
              <FormInput
                labelName={"Tax Identification Number"}
                placeholder={"e.g. 12345678-0001"}
                // maxlength={"12"}
                value={data.TINVerification}
                disabled={data.tinIsValidated}
                onChange={(name, value) => {
                  setData({
                    ...data,
                    TINVerification: value,
                  });
                  setFormErrors({ ...formErrors, verifyTIN: "" });
                }}
                error={formErrors?.verifyTIN?.message}
              />
              {!formErrors?.verifyTIN?.message && (
                <div>
                  {data.tinIsValidated ? (
                    <div
                      className=" position-absolute"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.3rem",
                        bottom: "-0.8rem",
                        left: "1rem",
                        color: colors.darkGreen,
                      }}
                    >
                      <img src="/verified.png" width={"19rem"}></img>
                      <p className="fw-bold m-0 mt-1">Validated</p>
                    </div>
                  ) : (
                    <div
                      className=" position-absolute"
                      style={{
                        bottom: "-1.5rem",
                        left: "1rem",
                        color: colors.darkGreen,
                      }}
                    >
                      {data.TINVerification?.length > 9 && (
                        <Button
                          btnText={"Submit"}
                          width={"5.5rem"}
                          height={"2rem"}
                          isLoading={tinIsloading}
                          disabled={tinIsloading || data.tinIsValidated}
                          padding={"0 10px"}
                          borderWidth={"4px"}
                          spinnerWidth={"1rem"}
                          spinnerHeight={"1rem"}
                          onBtnClick={() =>
                            verifyTIN.mutate(data.TINVerification)
                          }
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <div>
            <h6 className="fw-bold mt-5" style={{ color: colors.darkGreen }}>
              DOCUMENTS
            </h6>
            {developerDocuments?.length <= 0 ? (
              <CenteredText
                title={
                  "There are currently to documents to upload at this time."
                }
              ></CenteredText>
            ) : (
              developerDocuments?.map((doc, index) => (
                <>
                  <div className="row  d-flex justify-content-between align-items-center w-100">
                    <div className="col-md-12 d-flex flex-column justify-content-around border-bottom py-3">
                      <div className="row d-flex justify-content-evenly align-items-center position-relative">
                        <div className="text-center text-md-start col-md-6">
                          <span className="doc_upload_label">
                            {doc.name}{" "}
                            {doc.isRequired && (
                              <span className="required">*</span>
                            )}{" "}
                          </span>
                        </div>

                        <div className="col-md-6 d-flex justify-content-center justify-content-md-end mt-2 mt-md-0">
                          {doc.isUploaded ? (
                            <Button
                              btnText={"Delete"}
                              bordercolor={colors.red}
                              backgroundcolor={colors.white}
                              backgroundHover={colors.dangerLight}
                              colorHover={colors.danger}
                              color={colors.danger}
                              iconcolorHover={colors.danger}
                              iconcolor={colors.danger}
                              bordersize={2}
                              btntextcolor={colors.red}
                              type={"button"}
                              onBtnClick={() => handleDeleteFile(doc.id)}
                            />
                          ) : (
                            <Button
                              btnText={"Upload"}
                              type="button"
                              onBtnClick={() => {
                                // if (
                                //   !data.cacIsValidated ||
                                //   !data.tinIsValidated
                                // ) {
                                //   return toast.error(
                                //     "Must complete both CAC and TIN verifications"
                                //   );
                                // }
                                setDocIndex(index);
                                fileRef.current.click();
                              }}
                            />
                          )}
                        </div>
                        {doc.isRequired && !doc.isUploaded && (
                          <span
                            className="form-error position-absolute"
                            style={{ bottom: "-0.6rem", left: "0.3rem" }}
                          >
                            {formErrors?.requiredDoc?.message}
                          </span>
                        )}
                      </div>

                      {doc?.isUploaded && (
                        <div className="row d-flex justify-content-evenly align-items-center pt-2 px-2">
                          <div className="col-6">
                            <span className="doc_uploaded">
                              {doc?.fileName}
                            </span>
                          </div>
                          <div className="col-6 d-flex justify-content-end pe-4">
                            <span className="doc_uploaded">UPLOADED</span>
                          </div>
                        </div>
                      )}
                      <input
                        type="file"
                        accept="pdf/*"
                        ref={fileRef}
                        onChange={(event) => handleFileChange(event)}
                        className="file_upload"
                      />
                    </div>
                  </div>
                </>
              ))
            )}
          </div>
        </>
      )}
    </>
  );
};

export default DocumentSubmission;
