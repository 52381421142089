import React, { useEffect, useState } from "react";
import {
  ERROR_CODE,
  colors,
  dum,
  formattedPrice,
  getNumber,
  isNullOrEmpty,
  onlyNumbers,
  convertToTitleCase,
  removeUnderscore,
} from "../../utils/constants.js";
import Button from "../../Components/Common/Button/index.jsx";
import CheckInput from "../../Components/Common/CheckInput/index.jsx";
import FormInput from "../../Components/Common/FormInput/index.jsx";
import FooterButtons from "../../Components/Common/FooterButtons/index.js";
import Icons from "../../Components/Common/Icons/index.jsx";
import MultitextInput from "../../Components/Common/MultitextInput/index.jsx";
import { useForm } from "react-hook-form";
import { model, modelSchema, submitData } from "./model";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import useAuth from "../../hooks/useAuth.js";
import useAxiosBase from "../../hooks/useAxiosBase.js";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import useCreatePlans from "./hooks/useCreatePlans";
import useUpdatePlans from "./hooks/useUpdatePlans";
import CenteredText from "../../Components/CenteredText/index.jsx";
import Dropdown from "../../Components/Common/Dropdown/index.jsx";
import Modal from "../../Components/Common/Modal/index.jsx";
// import EstateCardDetails from "../../Components/Common/EstateCardDetails.jsx/index.jsx";
import Spinner from "../../Components/Spinner/index.jsx";
import PlanTypeCard from "../../Components/Common/PlanTypeCard/index.jsx";
import {
  planTypes,
  bankTypes,
  interestTypes,
  processingFees,
  deliveryLevelsLists,
} from "../../utils/mockData.js";

const MortgagePlanForm = ({ plan }) => {
  const { authUser } = useAuth();
  const navigate = useNavigate();

  const params = useParams();
  const houseId = params?.houseId;

  const [planId, setPlanId] = useState();

  // const {
  //   data: groupedProperties,
  //   isLoading: propertyLoading,
  //   refetch,
  //   isRefetching,
  // } = useGetGroupedProperties();
  const { createPlans } = useCreatePlans(houseId);
  const { updatePlans } = useUpdatePlans(planId, houseId);

  // const [isLoading, setIsLoading ] = useState(false);

  const [processingForm, setProcessingForm] = useState([]);
  const [documentForm, setDocumentForm] = useState([]);
  const [planName, setPlanName] = useState();
  const [planTypeName, setPlanTypeName] = useState();
  const [selectedPlanType, setSelectedPlanType] = useState(null);
  const [estateCollection, setEstateCollection] = useState();
  const [propertyCollection, setPropertyCollection] = useState();
  const [loanTenorError, setLoanTenorError] = useState("");
  const [planDetails, setPlanDetails] = useState({});

  const [showPlanTypeModal, setShowPlanTypeModal] = useState(false);
  const [
    showSelectInterestOrCompoundType,
    setShowSelectInterestOrCompoundType,
  ] = useState(false);
  const [disable, setDisable] = useState(false);

  const [bankName, setBankName] = useState("");
  const [selectedBank, setSelectedBank] = useState(null);
  const [selectedDeliveryLevel, setSelectedDeliveryLevel] = useState(null);
  const [deliveryLevelName, setDeliveryLevelName] = useState("");
  const [selectedInterestType, setSelectedInterestType] = useState(null);
  const [interestTypeName, setInterestTypeName] = useState("");

  const [defaultCheckableDocuments, setDefaultCheckableDocuments] = useState([
    {
      id: "BANK_STATEMENT",
      name: "Bank Statements",
      label: "Bank Statements",
      isRequired: true,
      checked: true,
      description:
        "Recent bank statements for the last six months to demonstrate financial stability and capacity to repay the loan with bank stamp.",
    },
    {
      id: "EMPLOYMENT_LETTER",
      name: "Employment Letter",
      label: "Employment Letter",
      isRequired: true,
      checked: true,
      description: "",
    },
    {
      id: "CREDIT_REPORT",
      name: "Credit Report",
      label: "Credit Report (For Diaspora Customers)",
      isRequired: true,
      checked: true,
      description: "",
    },
  ]);

  const [showNHFDoc, setShowNHFDoc] = useState(false);
  const [checkableNHFDocuments, setCheckableNHFDocuments] = useState([
    {
      id: "APPLICATION_FORM",
      name: "Application Form",
      label: "Application Form",
      isRequired: true,
      checked: true,
      description: "Completed NHF loan application form.",
    },
    {
      id: "IDENTITY_PROOF",
      name: "Identity Proof",
      label: "Identity Proof",
      isRequired: true,
      checked: true,
      description:
        "Valid government-issued identification (e.g., national ID card, passport, driver’s license).",
    },
    {
      id: "PROOF_OF_NHF_CONTRIBUTION",
      name: "Proof of NHF Contribution",
      label: "Proof of NHF Contribution",
      isRequired: true,
      checked: true,
      description:
        "Six-month statement of contribution from the NHF internet banking portal.",
    },
    {
      id: "EMPLOYMENT_DETAILS",
      name: "Employment Details",
      label: "Employment Details",
      isRequired: true,
      checked: true,
      description:
        "For salaried employees, a letter from the employer confirming employment status and salary.",
    },
    {
      id: "BUSINESS_DETAILS",
      name: "Business Details",
      label: "Business Details",
      isRequired: false,
      checked: false,
      description:
        "For self-employed individuals, proof of business registration and financial statements.",
    },
    {
      id: "THREE_MONTHS_PAYSLIP",
      name: "3 Months Payslip",
      label: "3 Months Payslip",
      isRequired: true,
      checked: true,
      description: "Three months pay slips preceding the date of application.",
    },
    {
      id: "PROPERTY_DOCUMENT",
      name: "Property Documents",
      label: "Property Documents",
      isRequired: true,
      checked: true,
      description: "Photocopy of Title Documents of the Property.",
    },
    {
      id: "LETTER_OF_INTRODUCTION_FROM_EMPLOYER",
      name: "Letter of Introduction from Employer",
      label: "Letter of Introduction from Employer",
      isRequired: false,
      checked: false,
      description: "A letter from the employer introducing the applicant.",
    },
    {
      id: "LETTER_OF_UNDERTAKING_NOT_TO_ACCESS_NHF_LOAN",
      name: "Letter of Undertaking Not to Access NHF Loan",
      label: "Letter of Undertaking Not to Access NHF Loan",
      isRequired: false,
      checked: false,
      description:
        "A letter of undertaking stating that the applicant has not previously accessed an NHF loan.",
    },
    {
      id: "BIRTH_CERTIFICATE_OR_SOWRN_AGE_DECLARATION",
      name: "Birth Certificate/Sworn Age Declaration",
      label: "Birth Certificate/Sworn Age Declaration",
      isRequired: true,
      checked: true,
      description:
        "A copy of the applicant’s birth certificate or a sworn age declaration.",
    },
    {
      id: "VALUATION_REPORT",
      name: "Valuation Report",
      label: "Valuation Report",
      isRequired: true,
      checked: true,
      description:
        "A valuation report from a registered estate valuer for the property intended for purchase or renovation.",
    },
    {
      id: "PASSPORT_PHOTOGRAPHS",
      name: "Passport Photographs",
      label: "Passport Photographs",
      isRequired: true,
      checked: true,
      description: "Two recent passport-sized photographs of the applicant.",
    },
    {
      id: "TAX_CLEARANCE_CERTIFICATE",
      name: "Tax Clearance Certificate",
      label: "Tax Clearance Certificate",
      isRequired: false,
      checked: false,
      description:
        "A valid tax clearance certificate showing the applicant’s tax compliance status.",
    },
  ]);

  const [showFMBDoc, setShowFMBDoc] = useState(false);
  const [checkableFMBDocuments, setCheckableFMBDocuments] = useState([
    {
      id: "APPLICATION_FORM",
      name: "Application Form",
      label: "Application Form",
      isRequired: false,
      checked: false,
      description: "Filled out completely.",
    },
    {
      id: "PASSPORT_PHOTOGRAPHS",
      name: "Passport Photographs",
      label: "Passport Photographs",
      isRequired: true,
      checked: true,
      description: "Two passport photographs with a white background.",
    },
    {
      id: "ALLOCATION_LETTER",
      name: "Allocation Letter",
      label: "Allocation Letter",
      isRequired: true,
      checked: true,
      description: "Signed by the applicant.",
    },
    {
      id: "LETTER_OF_APPOINTMENT_OR_EMPLOYMENT",
      name: "Letter of Appointment/Employment",
      label: "Letter of Appointment/Employment",
      isRequired: true,
      checked: true,
      description:
        "Official document from your employer stating your position and start date.",
    },
    {
      id: "LETTER_OF_CONFIRMATION_OF_APPOINTMENT_OR_EMPLOYMENT",
      name: "Letter of Confirmation of Appointment/Employment",
      label: "Letter of Confirmation of Appointment/Employment",
      isRequired: true,
      checked: true,
      description:
        "Document confirming your employment status, usually provided after probation.",
    },
    {
      id: "IDENTITY_PROOF",
      name: "Identity Proof",
      label: "Identity Proof",
      isRequired: true,
      checked: true,
      description:
        "Valid government-issued identification (e.g., national ID card, passport, driver’s license).",
    },
    {
      id: "OFFICE_ID",
      name: "Office ID",
      label: "Office ID",
      isRequired: true,
      checked: true,
      description:
        "Identification card issued by your employer to verify your employment.",
    },
    {
      id: "PROOF_OF_NHF_CONTRIBUTION",
      name: "Proof of NHF Contribution: ",
      label: "Proof of NHF Contribution: ",
      isRequired: true,
      checked: true,
      description:
        "Six-month statement of contribution from the NHF internet banking portal.",
    },
    {
      id: "THREE_MONTHS_PAYSLIP",
      name: "Three Months Payslip",
      label: "Three Months Payslip",
      isRequired: true,
      checked: true,
      description: "Three months pay slips preceding the date of application.",
    },
    {
      id: "BVN_PRINT_OUT",
      name: "BVN Print Out",
      label: "BVN Print Out",
      isRequired: true,
      checked: true,
      description: "Printout with photograph. ",
    },
    {
      id: "UTILITY_BILL",
      name: "Utility Bill",
      label: "Utility Bill",
      isRequired: false,
      checked: false,
      description:
        "Most recent utility bill (required for business owners or self-employed applicants).",
    },
    {
      id: "MOST_RECENT_SIX_MONTHS_REGISTERED_BUSINESS_ADDITIONAL_INCOME_STATEMENT_OF_ACCOUNT",
      name: "Most Recent Six Months Registered Business Additional Income Statement of Account",
      label:
        "Most Recent Six Months Registered Business Additional Income Statement of Account",
      isRequired: false,
      checked: false,
      description:
        "Where applicable, only for agricultural businesses for civil servants with bank stamp.",
    },
    {
      id: "SWORN_AFFIDAVIT",
      name: "Sworn Affidavit",
      label: "Sworn Affidavit",
      isRequired: false,
      checked: false,
      description:
        "To continue to pay/make NHF Contribution (required for business owners or self-employed applicants).",
    },
    {
      id: "BANK_CERTIFIED_PAYMENT_MANDATE",
      name: "Bank Certified Payment Mandate: ",
      label: "Bank Certified Payment Mandate: ",
      isRequired: false,
      checked: false,
      description:
        "Standing order to continue to pay NHF Contribution until retirement (required for business owners or self-employed applicants).",
    },
    {
      id: "CERTIFICATE_OF_BUSINESS_REGISTRATION",
      name: "Certificate of Business Registration",
      label: "Certificate of Business Registration",
      isRequired: false,
      checked: false,
      description:
        "Including business owner’s details (required for business owners or self-employed applicants).",
    },
    {
      id: "GUARANTORS",
      name: "Guarantors",
      label: "Guarantors",
      isRequired: false,
      checked: false,
      description:
        "Preferably civil servants (this will be required later, for business owners or self-employed applicants).",
    },
    {
      id: "SWORN_AFFIDAVIT_TO_SUPPORT_SPOUSE",
      name: "Sworn Affidavit to Support Spouse",
      label: "Sworn Affidavit to Support Spouse",
      isRequired: false,
      checked: false,
      description: "Provided by the supporting spouse.",
    },
    {
      id: "MOST_RECENT_THREE_MONTHS_PAY_SLIP",
      name: "Most Recent 3 Months Pay Slips (Supporting Spouse)",
      label: "Most Recent 3 Months Pay Slips (Supporting Spouse)",
      isRequired: false,
      checked: false,
      description:
        "Only for the supporting spouse who wishes to support with salary.",
    },
    {
      id: "MOST_RECENT_SIX_MONTHS_STATEMENT_OF_ACCOUNT",
      name: "Most Recent 6 Months Statement of Account",
      label: "Most Recent 6 Months Statement of Account",
      isRequired: false,
      checked: false,
      description: "Including salary or registered business, with bank stamp.",
    },
  ]);

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(model) });

  const onSubmit = (data) => {
    let checkedDocuments = defaultCheckableDocuments
      .filter((doc) => doc.checked)
      .map((doc) => ({
        id: doc.id,
        name: doc.name,
        description: doc.description,
        isRequired: doc.isRequired,
      }));

    if (showNHFDoc) {
      const checkedNHFDocuments = checkableNHFDocuments
        .filter((doc) => doc.checked)
        .map((doc) => ({
          id: doc.id,
          name: doc.name,
          description: doc.description,
          isRequired: doc.isRequired,
        }));

      checkedDocuments = checkedDocuments.concat(checkedNHFDocuments);
    }

    if (showFMBDoc) {
      const checkedFMBDocuments = checkableFMBDocuments
        .filter((doc) => doc.checked)
        .map((doc) => ({
          id: doc.id,
          name: doc.name,
          description: doc.description,
          isRequired: doc.isRequired,
        }));

      checkedDocuments = checkedDocuments.concat(checkedFMBDocuments);
    }

    if (documentForm?.length > 0) {
      const docForm = documentForm.map((doc) => ({
        name: doc.name,
        description: doc.description,
        isRequired: doc.isRequired,
      }));

      checkedDocuments = checkedDocuments.concat(docForm);
    }

    if (!data?.numberOfMissedPayments) {
      data.numberOfMissedPayments = 0;
    } else if (typeof data?.numberOfMissedPayments === "string") {
      data.numberOfMissedPayments = parseFloat(
        data.numberOfMissedPayments.replace(/,/g, "")
      );
    }

    data["organizationId"] = authUser?.user?.organizationId;

    data = {
      ...data,
      maxLoanAmount: data?.maxLoanAmount
        ? getNumber(data?.maxLoanAmount)
        : null,
      deliveryLevelPrice: data?.deliveryLevelPrice
        ? getNumber(data?.deliveryLevelPrice)
        : null,
      mortgagePeriod: data?.mortgagePeriod
        ? getNumber(data?.mortgagePeriod)
        : null,
      maxAge: data?.maxAge ? getNumber(data?.maxAge) : null,
      interestRate: data?.interestRate ? getNumber(data?.interestRate) : null,
      loanTenor: data?.loanTenor ? getNumber(data?.loanTenor) : 1,
      equityPercentage: data?.equityPercentage
        ? getNumber(data?.equityPercentage)
        : null,
      processingPeriod: data?.processingPeriod
        ? getNumber(data?.processingPeriod)
        : null,
      defaultFee: data?.defaultFee ? getNumber(data?.defaultFee) : null,
      gracePeriod: data?.gracePeriod ? getNumber(data?.gracePeriod) : null,
      numberOfMissedPayments: data?.numberOfMissedPayments,
    };

    // Convert processingForm.amount to numbers
    const processedProcessingForm = processingForm.map((item) => ({
      ...item,
      amount: parseFloat(item.amount.replace(/,/g, "")),
    }));
    if (isNullOrEmpty(data?.id)) {
      const formData = {
        ownerType: "BANK",
        bankName: selectedBank,
        type: selectedPlanType,
        interestType: selectedInterestType,
        name: data.name,
        description: data.description,
        deliveryLevelPrice: Number(data.deliveryLevelPrice),
        deliveryDescription: data.deliveryDescription,
        occupancyPolicy: data.occupancyPolicy,
        deliveryLevel: selectedDeliveryLevel,
        numberOfMissedPayments: data?.numberOfMissedPayments ?? 0,
        propertyRecoveryTerms: data.propertyRecoveryTerms,
        mortgagePeriod: Number(data.processingPeriod),
        access: data.access,
        maxLoanAmount: Number(data.maxLoanAmount),
        maxAge: Number(data.maxAge),
        interestRate: Number(data.interestRate),
        loanTenor: Number(data.loanTenor),
        equityPercentage: Number(data.equityPercentage),
        organizationId: authUser?.user?.organizationId,
        processingPeriod: Number(data.processingPeriod),
        defaultFee: Number(data?.defaultFee),
        gracePeriod: Number(data?.gracePeriod),
        applicableFees: processedProcessingForm,
        houses: [houseId] ?? null,
        documents: checkedDocuments,
        repaymentMethods: [],
      };
      // console.log("sumitedformData", formData);
      createPlans.mutate(formData);
    } else {
      data["id"] = plan.id;
      const formData = {
        ownerType: "BANK",
        bankName: selectedBank,
        type: selectedPlanType,
        interestType: selectedInterestType,
        name: data.name,
        description: data.description,
        deliveryLevelPrice: Number(data.deliveryLevelPrice),
        deliveryDescription: data.deliveryDescription,
        propertyRecoveryTerms: data.propertyRecoveryTerms,
        occupancyPolicy: data.occupancyPolicy,
        deliveryLevel: selectedDeliveryLevel,
        numberOfMissedPayments: data?.numberOfMissedPayments ?? 0,
        mortgagePeriod: Number(data.processingPeriod),
        access: data.access,
        maxAge: Number(data.maxAge),
        maxLoanAmount: Number(data.maxLoanAmount),
        interestRate: Number(data.interestRate),
        loanTenor: Number(data.loanTenor),
        equityPercentage: Number(data.equityPercentage),
        defaultFee: Number(data.defaultFee),
        gracePeriod: Number(data.gracePeriod),
        organizationId: authUser?.user?.organizationId,
        processingPeriod: Number(data.processingPeriod),
        applicableFees: processedProcessingForm,
        houses: [houseId] ?? null,
        documents: checkedDocuments,
        repaymentMethods: [],
      };

      // console.log("update", formData);
      updatePlans.mutate(formData);
    }
  };

  const handleAddDynamicForm = () => {
    if (processingForm?.length && processingForm[0].name === "") {
      toast.error(`Please enter a fee name!`);
      return;
    }
    if (processingForm?.length && processingForm[0].amount === 0) {
      toast.error(`Please enter the fee amount!`);
      return;
    }
    let newForm = {
      name: "",
      amount: 0,
      type: "FIXED",
    };
    setProcessingForm([newForm, ...processingForm]);
  };

  const handleChangeEventDynamicForm = (name, value, index) => {
    let dynamicInputData = [...processingForm];

    if (name === "amount" && dynamicInputData[index].type === "PERCENTAGE") {
      if (!onlyNumbers(value) || value < 1 || value > 100) {
        dynamicInputData[index].error =
          "Percentage must be a number between 1 and 100";
        dynamicInputData[index][name] = "";
      } else {
        dynamicInputData[index].error = "";
        dynamicInputData[index][name] = value;
      }
    } else {
      dynamicInputData[index][name] = value;
      dynamicInputData[index].error = "";
    }

    setProcessingForm(dynamicInputData);
  };

  const handleFeeTypeChange = (value, index) => {
    let dynamicInputData = [...processingForm];
    dynamicInputData[index]["type"] = value;
    dynamicInputData[index]["amount"] = 0; // Reset amount on fee type change
    dynamicInputData[index]["error"] = ""; // Reset error on fee type change
    setProcessingForm(dynamicInputData);
  };

  const handleRemoveFeature = (index) => {
    let newData = [...processingForm];
    newData.splice(index, 1);
    setProcessingForm(newData);
  };

  const handleAddDocumentForm = () => {
    if (documentForm.length && documentForm[0].name === "") {
      toast.error(`Please enter a document name`);
      return;
    }
    if (documentForm.length && documentForm[0].description === "") {
      toast.error(
        `Please enter a description for this document${documentForm.length}!`
      );
      return;
    }
    let newForm = {
      name: "",
      description: "",
      isRequired: false,
    };
    setDocumentForm([newForm, ...documentForm]);
  };

  const handleChangeEventDocumentForm = (name, value, index) => {
    let dynamicInputData = [...documentForm];
    if (name === "isRequired") {
      dynamicInputData[index][name] = !dynamicInputData[index][name];
    } else {
      dynamicInputData[index][name] = value;
    }
    setDocumentForm(dynamicInputData);
  };

  const handleRemoveDocumentForm = (index) => {
    let newData = [...documentForm];
    newData.splice(index, 1);
    setDocumentForm(newData);
  };

  const handleSelectInterestType = (e) => {
    let id = e;

    if (id) {
      const interestName = interestTypes.find((x) => x.id === id).name;
      setInterestTypeName(interestName);
      setSelectedInterestType(id);
    }
  };

  const handleSelectPlanType = (e) => {
    let id = e;
    reset();

    if (id) {
      const planTypeName = planTypes?.find((x) => x.id === id)?.name;
      setPlanTypeName(planTypeName);
      setSelectedPlanType(id);
    } else {
      return;
    }

    if (removeUnderscore(id) === "OUTRIGHTPLAN") {
      setValue("equityPercentage", "100");
      setValue("loanTenor", "360");
    }

    if (removeUnderscore(id) === "RENTTOOWN") {
      setDisable(true);
      setShowFMBDoc(true);
      setValue("interestRate", "7");
      setValue("loanTenor", "360");
      setValue("maxAge", "60");
      setValue("maxLoanAmount", "50,000,000");
      setInterestTypeName("Compound Interest");
      setSelectedInterestType("COMPOUND_INTEREST");
    }

    if (removeUnderscore(id) !== "RENTTOOWN") {
      setShowFMBDoc(false);
    }

    if (removeUnderscore(id) === "NHFPLAN") {
      setDisable(true);
      setShowNHFDoc(true);
      setValue("interestRate", "6");
      setValue("loanTenor", "360");
      setValue("maxAge", "60");
      setValue("maxLoanAmount", "50,000,000");
      setInterestTypeName("Compound Interest");
      setSelectedInterestType("COMPOUND_INTEREST");
    }

    if (removeUnderscore(id) !== "NHFPLAN") {
      setShowNHFDoc(false);
    }

    if (removeUnderscore(id) === "MORTGAGEPLAN") {
      setValue("maxAge", "60");
    }

    if (removeUnderscore(id) !== "MORTGAGEPLAN") {
      setDisable(false);
      setBankName(null);
      setSelectedBank(null);
    }

    if (
      removeUnderscore(id) !== "NHFPLAN" &&
      removeUnderscore(id) !== "RENTTOOWN" &&
      removeUnderscore(id) !== "MORTGAGEPLAN"
    ) {
      setInterestTypeName(null);
      setSelectedInterestType(null);
    }
  };

  const handleDocCheck = (docName) => {
    const updatedDefaultDocs = defaultCheckableDocuments.map((doc) =>
      doc.name === docName ? { ...doc, checked: !doc.checked } : doc
    );

    const updatedNHFDocs = checkableNHFDocuments.map((doc) =>
      doc.name === docName ? { ...doc, checked: !doc.checked } : doc
    );

    const updatedFMBDocs = checkableFMBDocuments.map((doc) =>
      doc.name === docName ? { ...doc, checked: !doc.checked } : doc
    );

    setCheckableNHFDocuments(updatedNHFDocs);
    setCheckableFMBDocuments(updatedFMBDocs);
    setDefaultCheckableDocuments(updatedDefaultDocs);
  };

  // useEffect(() => {
  //   // Filter properties with estateId not null
  //   const estateCollect = groupedProperties?.filter(
  //     (prop) => prop.estateId !== null
  //   );

  //   // Filter properties with estateId null
  //   const propCollect = groupedProperties?.filter(
  //     (prop) => prop.estateId === null
  //   );

  //   setEstateCollection(estateCollect);
  //   setPropertyCollection(propCollect);
  // }, [groupedProperties]);

  useEffect(() => {
    if (plan) {
      setPlanId(plan.id);
      setPlanName(plan.name);
      setPlanDetails({
        name: plan.name,
        maxAge: plan.maxAge,
        maxLoanAmount: formattedPrice(plan.maxLoanAmount),
        deliveryLevelPrice: formattedPrice(plan.deliveryLevelPrice),
        interestRate: plan.interestRate < 1 ? "0%" : `${plan.interestRate}%`,
        mortgagePeriod: `${plan.mortgagePeriod}`,
        loanTenor: `${plan.loanTenor} Months`,
        processingPeriod: `${plan.processingPeriod} Days`,
        gracePeriod: `${plan.gracePeriod} Days`,
        numberOfMissedPayments: plan.numberOfMissedPayments,
        access: plan.access,
        equityPercentage: `${plan.equityPercentage}%`,
        defaultFee: formattedPrice(plan.defaultFee),
        description: plan.description,
        deliveryDescription: plan.deliveryDescription,
      });
      setProcessingForm(plan.applicableFees);

      if (removeUnderscore(plan.type) === "RENTTOOWN") {
        setShowFMBDoc(true);
      }

      if (removeUnderscore(plan.type) === "NHFPLAN") {
        setShowNHFDoc(true);
      }

      const planName = planTypes.find((x) => x.id === plan.type)?.name;
      setPlanTypeName(planName);
      setSelectedPlanType(plan.type);

      const interestName = interestTypes.find(
        (x) => x.id === plan.interestType
      )?.name;
      setInterestTypeName(interestName);
      setSelectedInterestType(plan.interestType);

      const deliveryLevelName = deliveryLevelsLists.find(
        (x) => x.id === plan.deliveryLevel
      )?.name;
      setDeliveryLevelName(deliveryLevelName);
      setSelectedDeliveryLevel(plan.deliveryLevel);

      const bankName = bankTypes.find((x) => x.id === plan.bankName)?.name;
      setBankName(bankName);
      setSelectedBank(plan.bankName);

      const nhfDocumentIds = checkableNHFDocuments?.map((doc) => doc.id);
      const fmbDocumentIds = checkableFMBDocuments?.map((doc) => doc.id);
      const defaultDocumentIds = defaultCheckableDocuments?.map(
        (doc) => doc.id
      );

      const docForm = plan?.requiredDocuments
        ?.filter(
          (doc) =>
            !nhfDocumentIds.includes(doc.optionId) &&
            !fmbDocumentIds.includes(doc.optionId) &&
            !defaultDocumentIds.includes(doc.optionId)
        )
        .map((doc) => ({
          name: doc.option?.name,
          isRequired: doc.option?.isRequired,
          description: doc.option?.description,
        }));

      // Update NHF Documents to be checked if they are required by the plan
      const updatedNHFDocuments = checkableNHFDocuments.map((doc) => ({
        ...doc,
        checked: plan.requiredDocuments.some(
          (requiredDoc) => requiredDoc.optionId === doc.id
        ),
      }));

      // Update FMB Documents to be checked if they are required by the plan
      const updatedFMBDocuments = checkableFMBDocuments.map((doc) => ({
        ...doc,
        checked: plan.requiredDocuments.some(
          (requiredDoc) => requiredDoc.optionId === doc.id
        ),
      }));

      setCheckableNHFDocuments(updatedNHFDocuments);
      setCheckableFMBDocuments(updatedFMBDocuments);
      setDocumentForm(docForm);
    }
  }, [plan]);

  useEffect(() => {
    reset(planDetails, processingForm, documentForm);
  }, [planDetails, reset]);

  useEffect(() => {
    if (!plan?.id) {
      setShowPlanTypeModal(true);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {plan && (
        <input
          {...register("id")}
          value={plan.id}
          style={{ display: "none" }}
        />
      )}

      <div className="h4 mt-4">Plan Details</div>
      <div className="row">
        {/* {removeUnderscore(selectedPlanType) === "MORTGAGEPLAN" && (
          <div className="col-sm-6 col-md-4 mb-2">
            <Dropdown
              required
              labelName={"Bank Name"}
              placeholder={"Select"}
              value={bankName}
              options={bankTypes}
              onChange={(e) => {
                let id = e.target.value;

                if (id) {
                  const bankName = bankTypes.find((x) => x.id === id).name;
                  setBankName(bankName);
                  setSelectedBank(id);
                }
              }}
            />
          </div>
        )} */}

        <div className="col-sm-6 col-md-4 mb-2">
          <Dropdown
            labelName={"Plan Type"}
            required={true}
            value={planTypeName}
            placeholder={"Select"}
            options={planTypes}
            onChange={(e) => handleSelectPlanType(e?.target?.value)}
          />
        </div>

        <div
          className="col-sm-6 col-md-4 mb-2"
          onBlur={(e) => {
            let name = e.target.value;
            if (name) {
              setPlanName(name);
            }
          }}
        >
          <FormInput
            labelName={"Plan Name"}
            placeholder={"Disapora Track plan"}
            required
            register={register("name")}
            error={errors?.name?.message}
          />
        </div>

        {removeUnderscore(selectedPlanType) === "MORTGAGEPLAN" && (
          <div className="col-sm-6 col-md-4 mb-2">
            <Dropdown
              labelName={"Interest Type"}
              required={true}
              value={interestTypeName}
              placeholder={"Select"}
              options={interestTypes}
              onChange={(e) => handleSelectInterestType(e?.target?.value)}
              disabled={disable}
            />
          </div>
        )}

        {(removeUnderscore(selectedPlanType) === "NHFPLAN" ||
          removeUnderscore(selectedPlanType) === "MORTGAGEPLAN" ||
          removeUnderscore(selectedPlanType) === "RENTTOOWN") && (
          <div className="col-sm-6 col-md-4 mb-2">
            <FormInput
              labelName={"Interest Rate (%)"}
              required
              placeholder={"Figures are in percentage"}
              register={register("interestRate")}
              hintText={"Enter only numbers. E.g 10, not 10%"}
              error={errors?.interestRate?.message}
              disabled={
                removeUnderscore(selectedPlanType) !== "MORTGAGEPLAN" &&
                !disable
              }
            />
          </div>
        )}

        {(removeUnderscore(selectedPlanType) === "NHFPLAN" ||
          removeUnderscore(selectedPlanType) === "MORTGAGEPLAN" ||
          removeUnderscore(selectedPlanType) === "RENTTOOWN") && (
          <div
            className="col-sm-6 col-md-4 mb-2"
            onBlur={(e) =>
              setValue(
                "maxLoanAmount",
                !onlyNumbers(e.target.value)
                  ? formattedPrice(plan?.maxLoanAmount)
                  : formattedPrice(e.target.value)
              )
            }
          >
            <FormInput
              labelName={"Max Loan Amount"}
              required
              placeholder={"Enter max loan amount"}
              register={register("maxLoanAmount")}
              error={errors?.maxLoanAmount?.message}
              disabled={
                removeUnderscore(selectedPlanType) !== "MORTGAGEPLAN" &&
                !disable
              }
            />
          </div>
        )}

        {removeUnderscore(selectedPlanType) !== "OUTRIGHTPLAN" && (
          <div
            className="col-sm-6 col-md-4 mb-2"
            onBlur={(e) => {
              if (removeUnderscore(selectedPlanType) === "MORTGAGEPLAN") {
                if (e.target.value < 12) {
                  setValue("loanTenor", "");
                  setLoanTenorError(
                    "Minimum loan tenor for Mortgage Plan is 12 months"
                  );
                  return;
                }
                setLoanTenorError("");
                setValue("loanTenor", e.target.value);
              }
            }}
          >
            <FormInput
              labelName={"Payment Period (In Months)"}
              required
              placeholder={"Figures are in months"}
              register={register("loanTenor")}
              hintText={"Enter only numbers. E.g 24, not 24 Months"}
              error={loanTenorError}
              disabled={
                removeUnderscore(selectedPlanType) !== "MORTGAGEPLAN" &&
                removeUnderscore(selectedPlanType) !== "PAYMENTPLAN" &&
                !disable
              }
            />
          </div>
        )}

        {removeUnderscore(selectedPlanType) !== "OUTRIGHTPLAN" && (
          <div className="col-sm-6 col-md-4 mb-2">
            <FormInput
              labelName={"Minimum Downpayment (%)"}
              placeholder={"Figures are in percentage"}
              required
              register={register("equityPercentage")}
              hintText={"Enter only numbers. E.g 10, not 10%"}
              error={errors?.equityPercentage?.message}
            />
          </div>
        )}

        <div className="col-sm-6 col-md-4 mb-2">
          <FormInput
            labelName={"Application Processing Time (in days)"}
            placeholder={"How many days?"}
            required
            register={register("processingPeriod")}
            hintText={"Enter only numbers. E.g 10, not 10 days"}
            error={errors?.processingPeriod?.message}
          />
        </div>

        {removeUnderscore(selectedPlanType) !== "OUTRIGHTPLAN" && (
          <div className="col-sm-6 col-md-4 mb-4">
            <FormInput
              labelName={"Monthly Default Fee (%)"}
              placeholder={"Figures are in percentage"}
              register={register("defaultFee")}
              hintText={"Enter only numbers. E.g 10, not 10%"}
              error={errors?.defaultFee?.message}
            />
          </div>
        )}

        {removeUnderscore(selectedPlanType) !== "OUTRIGHTPLAN" && (
          <div className="col-sm-6 col-md-4 mb-4">
            <FormInput
              labelName={"Grace Period (in days)"}
              placeholder={"How many day?"}
              register={register("gracePeriod")}
              hintText={"Enter only numbers. E.g 10, not 10 days"}
              error={errors?.gracePeriod?.message}
            />
          </div>
        )}

        {(removeUnderscore(selectedPlanType) === "RENTTOOWN" ||
          removeUnderscore(selectedPlanType) === "MORTGAGEPLAN" ||
          removeUnderscore(selectedPlanType) === "NHFPLAN") && (
          <div className="col-sm-6 col-md-4 mb-4">
            <FormInput
              labelName={"Max Age"}
              required
              placeholder={"Maximum age limit in years"}
              register={register("maxAge")}
              hintText={"Enter only numbers. E.g 10, not 10 Years"}
              error={errors?.maxAge?.message}
              disabled={
                removeUnderscore(selectedPlanType) === "MORTGAGEPLAN"
                  ? disable
                  : !disable
              }
            />
          </div>
        )}

        {removeUnderscore(selectedPlanType) !== "OUTRIGHTPLAN" && (
          <div
            className="col-sm-6 col-md-4 mb-4"
            onBlur={(e) =>
              setValue(
                "numberOfMissedPayments",
                !onlyNumbers(e.target.value)
                  ? formattedPrice(plan?.numberOfMissedPayments)
                  : formattedPrice(e.target.value)
              )
            }
          >
            <FormInput
              labelName={"Maximum Missed Payments Before Property Recovery"}
              placeholder={"Payments missed?"}
              register={register("numberOfMissedPayments")}
              hintText={
                "How many payments can a customer miss before you recover your property"
              }
              error={errors?.numberOfMissedPayments?.message}
            />
          </div>
        )}

        <div
          className="col-sm-6 col-md-4 mb-4"
          onBlur={(e) =>
            setValue(
              "deliveryLevelPrice",
              !onlyNumbers(e.target.value)
                ? formattedPrice(plan?.deliveryLevelPrice)
                : formattedPrice(e.target.value)
            )
          }
        >
          <FormInput
            labelName={"Delivery Level Price"}
            required
            placeholder={"Enter the delivery level price"}
            register={register("deliveryLevelPrice")}
            error={errors?.deliveryLevelPrice?.message}
          />
        </div>

        <div className="col-sm-6 col-md-4 mb-4 position-relative">
          <Dropdown
            labelName={"Delivery Level"}
            required={true}
            placeholder={"Select"}
            value={deliveryLevelName}
            options={deliveryLevelsLists}
            hintText={
              "At what level of development do you want to sell houses on this plan?"
            }
            onChange={(e) => {
              let id = e.target.value;
              if (id) {
                const deliveryLevelName = deliveryLevelsLists.find(
                  (x) => x.id === id
                ).name;
                setDeliveryLevelName(deliveryLevelName);
                setSelectedDeliveryLevel(id);
              }
            }}
          />
        </div>

        <div className="col-sm-6 col-md-4 mb-4">
          <MultitextInput
            required
            label={"Delivery Level Description"}
            placeholder={"Describe the delivery Level"}
            register={register("deliveryDescription")}
            error={errors?.deliveryDescription?.message}
          />
        </div>

        {(removeUnderscore(selectedPlanType) === "NHFPLAN" ||
          removeUnderscore(selectedPlanType) === "MORTGAGEPLAN" ||
          removeUnderscore(selectedPlanType) === "RENTTOOWN") && (
          <div className="col-sm-6 col-md-4 mb-4">
            <MultitextInput
              label={"Description"}
              placeholder={"Describe the plan to customer"}
              register={register("description")}
              error={errors?.description?.message}
            />
          </div>
        )}

        <div className="col-sm-6 col-md-4 mb-4">
          <MultitextInput
            label={"Occupancy Policy"}
            placeholder={"Please describe your occupancy policy in detail"}
            register={register("occupancyPolicy")}
            error={errors?.occupancyPolicy?.message}
          />
        </div>

        {removeUnderscore(selectedPlanType) !== "OUTRIGHTPLAN" && (
          <div className="col-sm-6 col-md-4 mb-4">
            <MultitextInput
              label={"Missed Payment Policy"}
              placeholder={"What's your policy on missed payments"}
              register={register("propertyRecoveryTerms")}
              error={errors?.propertyRecoveryTerms?.message}
            />
          </div>
        )}
      </div>

      <hr className="hr mt-3" />

      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center processing-fees py-3">
          <div className="h4">Processing Fees</div>
          <div className="d-flex justify-content-end">
            <Button
              lefticonname={"plus"}
              btnText={"Add Fee"}
              type="button"
              iconcolor={colors.primary}
              btntextcolor={colors.primary}
              backgroundcolor={colors.white}
              iconcolorHover={colors.hover}
              colorHover={colors.hover}
              borderHover={colors.hover}
              backgroundHover={colors.white}
              onBtnClick={handleAddDynamicForm}
            />
          </div>
        </div>

        {processingForm.length > 0 ? (
          processingForm?.map((input, index) => (
            <div className="row mt-3" key={index}>
              <div className="row mb-2">
                <span>
                  <Button
                    btnText={<Icons iconName={"delete"} />}
                    bordercolor={colors.black}
                    borderHover={colors.red}
                    backgroundcolor={colors.white}
                    backgroundHover={colors.dangerLight}
                    colorHover={colors.danger}
                    color={colors.danger}
                    iconcolorHover={colors.danger}
                    padding={"0"}
                    bordersize={2}
                    btntextcolor={colors.red}
                    width={"40px"}
                    height={"43px"}
                    type={"button"}
                    onBtnClick={() => handleRemoveFeature(index)}
                  />
                </span>
              </div>
              <div className="row">
                <div className="col-sm-6 col-md-4">
                  <FormInput
                    labelName={"Fee Name"}
                    placeholder={"Processing"}
                    required
                    value={input.name}
                    name={"name"}
                    onChange={(name, value) =>
                      handleChangeEventDynamicForm(name, value, index)
                    }
                  />
                </div>
                <div className="col-sm-6 col-md-4 mb-2">
                  <Dropdown
                    labelName={"Fee Type (Fixed or Percentage)"}
                    required={true}
                    value={convertToTitleCase(input.type)}
                    placeholder={"Select"}
                    options={processingFees}
                    onChange={(e) => handleFeeTypeChange(e.target.value, index)}
                  />
                </div>
                {input.type === "PERCENTAGE" ? (
                  <div className="col-sm-6 col-md-4 mb-4">
                    <FormInput
                      left={"-7rem"}
                      inputDescription
                      inputDescriptionText={
                        "Percentage of property price means the fee will be calculated as a percentage of the property price. For example, if a house costs N100 million, and the fee is 1%, the customer will be paying 1% of the property price (N1 million)."
                      }
                      labelName={"Amount (%)"}
                      placeholder={"Figures are in percentage (eg. 10%)"}
                      required
                      error={input.error}
                      value={input.amount}
                      name={"amount"}
                      onChange={(name, value) =>
                        handleChangeEventDynamicForm(name, value, index)
                      }
                    />
                  </div>
                ) : (
                  <div className="col-sm-6 col-md-4">
                    <FormInput
                      left={"-5rem"}
                      inputDescription
                      inputDescriptionText={
                        "A fixed amount means you will be charging a flat fee. For example, you will charge N50,000 Naira, rather than a percentage of the property price."
                      }
                      labelName={"Amount"}
                      placeholder={"N250,000"}
                      required
                      onBlur={(e) => formattedPrice(e.target.value)}
                      value={formattedPrice(input.amount)}
                      name={"amount"}
                      onChange={(name, value) =>
                        handleChangeEventDynamicForm(name, value, index)
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <div>
            <CenteredText title={"Processing Fees"}>
              There are currently no processing fees for this plan. If there are
              any fees you collect from your customers, click the add fee button
              above to input such fees.
            </CenteredText>
          </div>
        )}
      </div>

      <hr className="hr mt-4" />

      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center processing-fees py-3">
          <div className="h4">Required Documents</div>
          <div className="d-flex justify-content-end">
            <Button
              lefticonname={"plus"}
              btnText={"Add Doc"}
              type="button"
              iconcolor={colors.primary}
              btntextcolor={colors.primary}
              backgroundcolor={colors.white}
              iconcolorHover={colors.hover}
              colorHover={colors.hover}
              borderHover={colors.hover}
              backgroundHover={colors.white}
              onBtnClick={handleAddDocumentForm}
            />
          </div>
        </div>

        {documentForm.length > 0 ? (
          documentForm.map((input, index) => (
            <div className="row mt-4" key={index}>
              <div className="row mb-2">
                <span>
                  <Button
                    btnText={<Icons iconName={"delete"} />}
                    bordercolor={colors.black}
                    borderHover={colors.red}
                    backgroundcolor={colors.white}
                    backgroundHover={colors.dangerLight}
                    colorHover={colors.danger}
                    color={colors.danger}
                    iconcolorHover={colors.danger}
                    padding={"0"}
                    bordersize={2}
                    btntextcolor={colors.red}
                    width={"40px"}
                    height={"43px"}
                    type={"button"}
                    onBtnClick={() => handleRemoveDocumentForm(index)}
                  />
                </span>
              </div>
              <div className="row">
                <div className="col-sm-6 col-md-5">
                  <FormInput
                    labelName={"Document Name"}
                    placeholder={"Document Name"}
                    required
                    value={input.name}
                    name={`name`}
                    onChange={(name, value) =>
                      handleChangeEventDocumentForm(name, value, index)
                    }
                  />
                </div>
                <div className="col-sm-6 col-md-5">
                  <MultitextInput
                    label={"How would you describe this document?"}
                    placeholder={"Document Description"}
                    name={`description`}
                    required
                    value={input.description}
                    onChange={(e) =>
                      handleChangeEventDocumentForm(
                        e.target.name,
                        e?.target?.value,
                        index
                      )
                    }
                  />
                </div>
                <div className="col-sm-6 col-md-2 pt-1">
                  <label className="custom-form-label">Is Required</label>
                  <CheckInput
                    name={"isRequired"}
                    checkTick={true}
                    checked={input.isRequired}
                    width={"23px"}
                    height={"23px"}
                    bcolor={"#0000001A"}
                    bordercolor={"#CECECE"}
                    register={register("isRequired")}
                    onChange={(e) =>
                      handleChangeEventDocumentForm(
                        "isRequired",
                        e.target.checked,
                        index
                      )
                    }
                  />
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="">
            <CenteredText title={"Document Submission"}>
              There are currently no documents to be submitted for this plan. If
              there are any documents you require your customers to submit,
              click the add doc button above to receive documents from your
              customers. By default, we ask for bank statements and employment
              letters.
            </CenteredText>
          </div>
        )}
        <div className="row m-0 mt-4">
          {defaultCheckableDocuments?.map((doc) => (
            <div
              key={doc.name}
              className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
            >
              <p className="m-0 fw-bold">
                {doc.label}{" "}
                {doc.isRequired && <span className="required">*</span>}
              </p>
              <CheckInput
                name={doc.name}
                checked={doc.checked}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  handleDocCheck(doc.name);
                }}
              />
            </div>
          ))}

          {showNHFDoc
            ? checkableNHFDocuments?.map((doc) => (
                <div
                  key={doc.name}
                  className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
                >
                  <p className="m-0 fw-bold">
                    {doc.label}{" "}
                    {doc.isRequired && <span className="required">*</span>}
                  </p>
                  <CheckInput
                    name={doc.name}
                    checked={doc.checked}
                    checkTick
                    width={"23px"}
                    height={"23px"}
                    bcolor={"#0000001A"}
                    bordercolor={"#CECECE"}
                    onChange={() => {
                      handleDocCheck(doc.name);
                    }}
                  />
                </div>
              ))
            : null}

          {showFMBDoc
            ? checkableFMBDocuments?.map((doc) => (
                <div
                  key={doc.name}
                  className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
                >
                  <p className="m-0 fw-bold">
                    {doc.label}{" "}
                    {doc.isRequired && <span className="required">*</span>}
                  </p>
                  <CheckInput
                    name={doc.name}
                    checked={doc.checked}
                    checkTick
                    width={"23px"}
                    height={"23px"}
                    bcolor={"#0000001A"}
                    bordercolor={"#CECECE"}
                    onChange={() => {
                      handleDocCheck(doc.name);
                    }}
                  />
                </div>
              ))
            : null}
        </div>
      </div>

      {/* <hr className="hr mt-4" /> */}

      {/* <div className="mt-4">
        <div className="processing-fees row">
          <div className="h4">Repayment Methods</div>
        </div>
        <div className="row mt-3 mb-5">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-md-3">
                  <CheckInput
                    name={"REMITA_PAYMENT"}
                    checkTick={true}
                    label={"Remita Payment"}
                    width={"23px"}
                    height={"23px"}
                    bcolor={colors.darkGray}
                    bordercolor={colors.grey}
                    checked={true}
                    disabled={true}
                    register={register("REMITA_PAYMENT")}
                    onChange={(e) => handleOnCheckRepayment(e)}
                  />
                </div>
                <div className="col-3">
                    <CheckInput
                      label={"Payment Gateway"}
                      name={"PAYMENT_GATEWAY"}
                      register={register("PAYMENT_GATEWAY")}
                      borderradius={"50%"}
                      width={"23px"}
                      height={"23px"}
                      onChange={(e) => handleOnCheckRepayment(e)}
                      bordercolor={colors.gray}
                    />
                  </div>
                  <div className="col-3">
                    <CheckInput
                      label={"Bank Transfer-Receipt Upload"}
                      name={"BANK_TRANSFER"}
                      register={register("BANK_TRANSFER")}
                      borderradius={"50%"}
                      width={"23px"}
                      height={"23px"}
                      onChange={(e) => handleOnCheckRepayment(e)}
                      bordercolor={colors.gray}
                    />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {plan?.id ? (
        <FooterButtons
          saveLabel={"Update"}
          cancelBtn={() => {
            if (houseId) {
              navigate(`/properties/my-properties/house/plans/${houseId}}`);
            } else {
              navigate("/plans");
            }
          }}
          loading={updatePlans.isLoading}
          disabled={updatePlans.isLoading}
          largeLoader={updatePlans.isLoading}
        />
      ) : (
        <FooterButtons
          saveLabel={"Create"}
          cancelBtn={() => {
            if (houseId) {
              navigate(`/properties/my-properties/house/plans/${houseId}}`);
            } else {
              navigate("/plans");
            }
          }}
          loading={createPlans.isLoading}
          disabled={createPlans.isLoading}
          largeLoader={createPlans.isLoading}
        />
      )}

      {/* SELECT PLAN TYPE  */}
      <Modal
        show={showPlanTypeModal}
        closeModal={() => {
          if (!selectedPlanType) {
            setShowPlanTypeModal(false);
            setSelectedPlanType(null);
            handleSelectInterestType(null);
            if (houseId) {
              navigate(`/properties/my-properties/house/plans/${houseId}}`);
            } else {
              navigate("/plans");
            }
          }
          setShowPlanTypeModal(false);
        }}
        title={
          showSelectInterestOrCompoundType
            ? "Select Interest Type"
            : "Select Plan Type"
        }
        subTitle={
          showSelectInterestOrCompoundType
            ? "What type of interest rate does this mortgage plan use?"
            : "What kind of plan do you want to create? Select One"
        }
        confirmModal={() => {
          if (
            removeUnderscore(selectedPlanType) === "MORTGAGEPLAN" &&
            !showSelectInterestOrCompoundType
          ) {
            return setShowSelectInterestOrCompoundType(true);
          }
          setShowPlanTypeModal(false);
        }}
        closeModalIcon={true}
        backBtn={showSelectInterestOrCompoundType}
        onBtnClick={() => {
          setSelectedPlanType(null);
          handleSelectInterestType(null);
          setShowSelectInterestOrCompoundType(false);
        }}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Continue"}
        width={"100%"}
        height={"100%"}
        crossLine={false}
        titleColor={colors.darkGreen}
        titleFont={"Millik"}
        titleFontSize={"38px"}
      >
        <>
          {showSelectInterestOrCompoundType ? (
            <div className="select_interest_type d-md-flex mt-5">
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"Simple Interest or Markup Rate"}
                  iconName={"simpleInterest"}
                  description={
                    "This is a simple markup on the property price. For example, if a house is N50 Million with a 10% simple interest or markup rate, they’ll repay N50, Million + N5 Million (the 10% extra markup rate)."
                  }
                  onChange={() => handleSelectInterestType("SIMPLE_INTEREST")}
                  onClick={() => handleSelectInterestType("SIMPLE_INTEREST")}
                  selected={selectedInterestType === "SIMPLE_INTEREST"}
                />
              </div>

              <p className="text-center">OR</p>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"Compound Interest"}
                  iconName={"compoundInterest"}
                  description={
                    "This is a compounding interest rate which is used in most traditional mortgages. Compounding rates accrue interest on a monthly basis. For example, if a house is N50 Million with a 10% compund interest, it means the 10% interest rate will compound monthly. We calculate the compund rate automatically and attach it to the customer’s repayment schedule."
                  }
                  onChange={() => handleSelectInterestType("COMPOUND_INTEREST")}
                  onClick={() => handleSelectInterestType("COMPOUND_INTEREST")}
                  selected={selectedInterestType === "COMPOUND_INTEREST"}
                />
              </div>
            </div>
          ) : (
            <div className="row m-0 mt-4">
              {/* <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"Outright Plan"}
                  iconName={"outrightPlan"}
                  description={
                    "With outright plans, customers pay 100% of the purchase price of the property in one bulk payment. There is no repayment necessary."
                  }
                  onChange={() => handleSelectPlanType("OUTRIGHT_PLAN")}
                  onClick={() => handleSelectPlanType("OUTRIGHT_PLAN")}
                  selected={selectedPlanType === "OUTRIGHT_PLAN"}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"Payment Plan"}
                  iconName={"paymentPlan"}
                  description={
                    " Payment plans allow customers pay a downpayment then spread the  remaining payment over a fixed time period."
                  }
                  onChange={() => handleSelectPlanType("PAYMENT_PLAN")}
                  onClick={() => handleSelectPlanType("PAYMENT_PLAN")}
                  selected={selectedPlanType === "PAYMENT_PLAN"}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={" FMBN Rent To Own"}
                  iconName={"FMBN"}
                  description={
                    " This is a plan offered by the Federal Mortgage Bank of Nigeria.vIf you have partnered with FMBN to sell properties through their Rent To Own Plan, you may use this plan to boost your sales and collect applications and manage sales and documentation. However, you will be responsible for processing the customer’s application to FMBN."
                  }
                  onChange={() => handleSelectPlanType("RENT_TO_OWN")}
                  onClick={() => handleSelectPlanType("RENT_TO_OWN")}
                  selected={selectedPlanType === "RENT_TO_OWN"}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"  National Housing Fund"}
                  iconName={"nationalHousingFund"}
                  description={
                    "This is a plan from the Federal Mortgage Bank of Nigeria. If you are authorized by FMBN to sell NHF mortgages, Rent To Own you can do so through here. However, you will be responsible for processing the customer’s application to FMBN."
                  }
                  onChange={() => handleSelectPlanType("NHF_PLAN")}
                  onClick={() => handleSelectPlanType("NHF_PLAN")}
                  selected={selectedPlanType === "NHF_PLAN"}
                />
              </div> */}
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={" Mortgage Plan"}
                  iconName={"mortgagePlan"}
                  description={
                    " This is a plan that attracts interest payments from customers and is paid over several years. Interest on mortgages can be simple or compond interest. We accomodate for both interest types."
                  }
                  cursor={"pointer"}
                  onChange={() => handleSelectPlanType("MORTGAGE_PLAN")}
                  onClick={() => handleSelectPlanType("MORTGAGE_PLAN")}
                  selected={selectedPlanType === "MORTGAGE_PLAN"}
                />
              </div>
            </div>
          )}
        </>
      </Modal>
    </form>
  );
};

export default MortgagePlanForm;
