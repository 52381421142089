import { useEffect, useCallback } from "react";
import baseAxios from "../api/baseAxios";
import useAuth from "./useAuth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const useAxiosBase = () => {
  const { authUser, setAuthUser } = useAuth();
  const navigate = useNavigate();

  const handleErrorResponse = useCallback(
    (status) => {
      toast.error("session expired!");
      sessionStorage.removeItem("user");
      setAuthUser(null);
      navigate("/account/login");
      return status;
    },
    [navigate, setAuthUser]
  );

  const getRequest = async ({ url, params }) => {
    const response = baseAxios
      .get(url, { params: { params } })
      .then((res) => res);
    if ([401, 403].includes(response.status))
      return handleErrorResponse(response.status);
    return response;
  };

  const deleteRequest = async ({ url, data }) => {
    const response = baseAxios.delete(url, data);
    return response;
  };

  const patchRequest = async ({ url, data }) => {
    const response = baseAxios.patch(url, data);
    return response.data;
  };

  const postRequest = async ({ url, data, params }) => {
    const response = baseAxios.post(url, data, { params });
    return response;
  };

  const postReq = async ({ url, data, params }) => {
    return await baseAxios.post(url, data, { params }).then((res) => res.data);
  };

  const putRequest = async ({ url, data }) => {
    const response = baseAxios.put(url, data);
    return response;
  };

  useEffect(() => {
    const requestInterceptor = baseAxios.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${authUser?.token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseInterceptor = baseAxios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if ([401, 403].includes(error?.response?.status))
          return handleErrorResponse(error.response.status);
        return error;
      }
    );

    return () => {
      baseAxios.interceptors.request.eject(requestInterceptor);
      baseAxios.interceptors.response.eject(responseInterceptor);
    };
  }, [authUser, handleErrorResponse]);

  return {
    baseAxios,
    getRequest,
    postRequest,
    postReq,
    putRequest,
    deleteRequest,
    patchRequest,
    handleErrorResponse,
  };
};
export default useAxiosBase;
