import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "./useAxiosBase";

const useAllStates = ({ onSuccess }) => {
  const { getRequest } = useAxiosBase();

  return useQuery({
    queryKey: ["all-states"],
    queryFn: () =>
      getRequest({
        url: "/public/state/NIGERIA/get-all?PageNumber=1&PageSize=50",
      }).then((res) => {
        return res?.data?.value?.value;
      }),
    onSuccess: onSuccess,
    onError: (err) => {
      console.log("err", err);
    },
    staleTime: 50000,
  });
};

export default useAllStates;
