import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useNav } from "../../../hooks/useNav";
import "../../Properties/properties.css";
import usePermission from "../../../hooks/usePermissions";
import useEstate from "../../../hooks/useEstate";
import CenteredText from "../../../Components/CenteredText";
import Button from "../../../Components/Common/Button";
import { sliderSettings } from "./constant";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { toast } from "react-toastify";
import { Carousel } from "primereact/carousel";
import Spinner from "../../../Components/Spinner";
import SkeletonLoader from "../../../Components/SkeletonLoader";
import { getImage } from "../../../utils/generalUtils";
import defaultHouse from "../../../house.svg";
import useHouse from "../../../hooks/useHouse";
import InfoTable from "../../../Components/Common/InfoTable";
import CellSelector from "../../../Components/Common/CellSelector";
import { Dropdown } from "react-bootstrap";
import useDeleteHouse from "./House/hooks/useDeleteHouse";
import { colors, convertToTitleCase } from "../../../utils/constants";
import { HiDotsVertical } from "react-icons/hi";
import Modal from "../../../Components/Common/Modal";
import EstatesModalContent from "./Estate/EstatesModalContent";
import TableLoader from "../../../Components/Common/TableLoader";

const MyProperties = () => {
  const {
    accessCreateHouse,
    accessHouseViewing,
    accessCreateEstate,
    accessEstateViewing,
  } = usePermission();

  const navigate = useNavigate();
  const location = useLocation();

  const defaultImage =
    "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8aG91c2V8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60";

  const [openModal, setOpenModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [openEstateModal, setOpenEstateModal] = useState(false);
  const [houseId, setHouseId] = useState();
  const [houseToDelete, setHouseToDelete] = useState();

  const [showModal, setShowModal] = useState(false);

  const { deleteHouse } = useDeleteHouse(houseId);

  const {
    data: estateCollection,
    error,
    isLoading,
    refetch: refetchEstate,
    isRefetching: isRefetchingEstate,
  } = useEstate();

  const [housePagination, setHousePagination] = useState(1);
  const [loadingHouseTable, setLoadingHouseTable] = useState(false);
  const {
    data: houseCollection,
    error: houseError,
    isLoading: houseLoading,
    refetch: refetchHouseCollection,
    isRefetching,
  } = useHouse(housePagination);

  const estates = useMemo(() => {
    if (!searchInput) return estateCollection;
    if (searchInput.length >= 1) {
      return estateCollection.filter((est) => {
        return est.name
          .toLocaleLowerCase()
          .includes(searchInput.toLocaleLowerCase());
      });
    }
  }, [searchInput, estateCollection]);

  if (error || houseError) {
    if (toast === null) toast.error(error?.message);
  }

  useNav({
    mainTitle: "My Properties",
    isProp: true,
    houses: houseCollection?.totalRecords ?? 0,
    estates: estates?.length,
  });

  const handlePlan = (houseId, option) => {
    if (option === "create") {
      navigate(`/properties/my-properties/house/plans/create/${houseId}`);
    } else {
      navigate(`/properties/my-properties/house/plans/${houseId}`);
    }
  };

  const handleDeleteHouse = (rowId) => {
    const selectedHouse = houseCollection?.data?.find(
      (house) => house.id === rowId
    );
    setHouseToDelete(selectedHouse);
    setHouseId(rowId);
    setShowModal(true);
  };

  //  HOUSE PROPERTY ESTATE
  const houseColumns = (viewProperty, handlePlan, handleDeleteHouse) => [
    {
      name: "",
      grow: 0,
      selector: (row) => (
        <CellSelector loading={isRefetching}>
          <img
            data-tag="allowRowEvents"
            height="60px"
            width="70px"
            alt={row?.images?.length ? row?.images[0]?.name : "alt image"}
            src={row?.images?.length > 0 ? row?.images[0] : defaultImage}
            style={{ borderRadius: "18px", padding: "8px" }}
          />
        </CellSelector>
      ),
    },
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          NAME
        </p>
      ),
      sortable: true,
      wrap: true,
      selector: (row) => (
        <CellSelector loading={isRefetching}>{row.name ?? "---"}</CellSelector>
      ),
    },
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          DETAILS
        </p>
      ),
      sortable: true,
      selector: (row) => (
        <CellSelector loading={isRefetching}>
          <>
            <span className="fw-semibold">{convertToTitleCase(row.type)}</span>{" "}
            <span className="circle_bar"></span> {row.address} {row.city}
          </>
        </CellSelector>
      ),
      wrap: true,
      grow: 2,
    },
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          PRICE
        </p>
      ),
      sortable: true,
      selector: (row) => (
        <CellSelector loading={isRefetching}>
          <>₦ {row.price?.toLocaleString()}</>
        </CellSelector>
      ),
    },
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          ESTATE
        </p>
      ),
      sortable: true,
      selector: (row) => (
        <CellSelector loading={isRefetching}>
          {row.estateName ?? "None Selected"}
        </CellSelector>
      ),
    },
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          PLANS
        </p>
      ),
      sortable: true,
      grow: 0.5,
      selector: (row) => (
        <CellSelector loading={isRefetching}>
          {row.houseMortgagePlans?.length}
        </CellSelector>
      ),
    },
    // {
    //   name: <p className="fw-bold p-0 m-0" style={{fontSize: "12px"}}>House Type</p>,
    //   sortable: true,
    //   selector: (row) => convertToTitleCase(row.type),
    // },
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          UNITS
        </p>
      ),
      sortable: true,
      grow: 0.6,
      selector: (row) => (
        <CellSelector loading={isRefetching}>{row.units}</CellSelector>
      ),
    },
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          {" "}
          ACTION{" "}
        </p>
      ),
      sortable: false,
      grow: 0.5,
      center: true,
      cell: (row) => (
        <Dropdown>
          <Dropdown.Toggle id={`dropdown-${row.id}`}>
            <HiDotsVertical className="fw-bold text-dark" />
          </Dropdown.Toggle>

          {!isRefetching && (
            <Dropdown.Menu>
              {row.houseMortgagePlans?.length > 0 && (
                <Dropdown.Item onClick={() => handlePlan(row.id, "view")}>
                  View Plans
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={() => handlePlan(row.id, "create")}>
                Create Plan
              </Dropdown.Item>
              <Dropdown.Item onClick={() => viewProperty(row.id)}>
                View House
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger"
                onClick={() => handleDeleteHouse(row.id)}
              >
                Delete House
              </Dropdown.Item>
            </Dropdown.Menu>
          )}
        </Dropdown>
      ),
      allowOverflow: true,
    },
  ];

  useEffect(() => {
    refetchEstate();
    refetchHouseCollection();
  }, [location.state]);

  useEffect(() => {
    setLoadingHouseTable(isRefetching);
  }, [isRefetching]);

  useEffect(() => {
    if (housePagination) {
      refetchHouseCollection();
    }
  }, [housePagination]);

  const estateCardTemp = (estate) => {
    const image = getImage;
    return (
      <div
        className="card pr-card mx-2"
        onClick={() =>
          navigate(`/properties/my-properties/estate/${estate.id}`)
        }
      >
        {isRefetchingEstate ? (
          <div className="mx-2 mb-2">
            <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
          </div>
        ) : (
          <div className="card-body slider-body pr-cr-cd">
            <img
              src={image(estate.images) ?? defaultHouse}
              alt={estate.name}
              style={{ width: "100%", height: "95%" }}
            />
          </div>
        )}

        <div className="card-details text-center p-2 pb-3">
          {isRefetchingEstate ? (
            <div className="mb-2">
              <SkeletonLoader height={"1.5rem"} borderRadius={"9px"} />
            </div>
          ) : (
            <div className="row pr-title">
              <h6>{estate.name}</h6>
            </div>
          )}

          {isRefetchingEstate ? (
            <SkeletonLoader />
          ) : (
            <div className="row pr-desc">
              <p className="p-0 mb-2">{estate.address}</p>
            </div>
          )}

          {isRefetchingEstate ? (
            <SkeletonLoader />
          ) : (
            <div className="row pr-hou">
              <span>
                {estate.houses?.length}{" "}
                {estate.houses?.length > 1 ? "HOUSES" : "HOUSE"}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="ps-3 pe-4 my-5">
      {/* First section */}
      {accessEstateViewing?.value ? (
        <>
          <div className="d-flex justify-content-between">
            <h3 style={{ fontSize: "24px" }}>
              Estates - (<span>{estates?.length ?? 0}</span>)
            </h3>
            {accessCreateEstate?.value && (
              <div className="d-flex justify-content-end">
                <Button
                  btnText="Add Estate"
                  type={"button"}
                  onBtnClick={() =>
                    navigate("/properties/my-properties/create-estate")
                  }
                />
              </div>
            )}
          </div>
          <div>
            {isLoading ? (
              <div style={{ height: "14rem" }}>
                <Spinner loadingText={"Loading Estates..."} />
              </div>
            ) : estates?.length ? (
              <div className="mb-5 mt-3">
                <Carousel
                  value={estates}
                  numVisible={4}
                  numScroll={1}
                  itemTemplate={estateCardTemp}
                  style={{
                    backgroundColor: "#F0F0F0",
                    paddingTop: "33px",
                    paddingLeft: "10px",
                    borderRadius: "10px",
                  }}
                />
              </div>
            ) : (
              <div className="my-5">
                <CenteredText title="No Estates Added Yet">
                  An estate is a collection of houses in the same location.
                  Click the “add estate” button above to create an estate and
                  then add the types of houses that are available in the estate.
                </CenteredText>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="my-5">
          <CenteredText title={"Unauthorized Access"}>
            You don't have permission to view estates. Please contact the
            organization administrator for assistance.
          </CenteredText>
        </div>
      )}

      {/* Second Section */}
      {accessHouseViewing?.value ? (
        <>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <h3 style={{ fontSize: "24px" }}>
              Houses - (<span>{houseCollection?.data?.length ?? 0}</span>)
            </h3>
            {accessCreateHouse?.value && (
              <div className="d-flex justify-content-end">
                <Button
                  btnText="Add House"
                  type={"button"}
                  onBtnClick={() => setOpenModal(!openModal)}
                />
              </div>
            )}
          </div>

          {houseLoading ? (
            <div className="mt-3">
              <TableLoader />
            </div>
          ) : houseCollection?.data?.length ? (
            <InfoTable
              columns={houseColumns(
                (houseId) => navigate(`/properties/my-properties/house/${houseId}`),
                (rowId, option) => handlePlan(rowId, option),
                (rowId) => handleDeleteHouse(rowId)
              )}
              dataCollection={houseCollection?.data}
              rightTextAsButton={true}
              pointerOnHover={true}
              highlightOnHover={true}
              loadingTable={loadingHouseTable}
              paginationTotalRows={houseCollection?.totalRecords}
              onChangePage={(page) => setHousePagination(page)}
              onRowClicked={(row, event) =>
                navigate(`/properties/my-properties/house/${row.id}`)
              }
            />
          ) : (
            <div className="my-5">
              <CenteredText title="No Houses Added Yet">
                If a house exists in an estate, create an estate and add the
                house under that estate. Houses added under estates will also
                populate here. You can also add an individual house (a house not
                in an estate or a standalone house) by clicking the “add house”
                button above.
              </CenteredText>
            </div>
          )}
        </>
      ) : (
        <div className="my-5">
          <CenteredText title={"Unauthorized Access"}>
            You don't have permission to view houses. Please contact the
            organization administrator for assistance.
          </CenteredText>
        </div>
      )}

      <Modal
        show={openModal}
        closeModal={() => navigate("/properties/my-properties/create-house")}
        title={"Does this House belong in an Estate?"}
        subTitle={"Is this house located in an estate?"}
        confirmModal={() => {
          setOpenModal(false);
          setOpenEstateModal(true);
        }}
        cancelButtonLabel="No"
        confirmButtonLabel="Yes"
      />

      <Modal
        show={openEstateModal}
        title={"Select The Estate This House Should Be Added To."}
        width={"100%"}
        height={"100%"}
        titleFontSize={"38px"}
        crossLine={false}
        closeModalIcon
        closeModal={() => setOpenEstateModal(false)}
      >
        <EstatesModalContent
          estates={estates}
          createLink={"/properties/my-properties/create-estate"}
        />
      </Modal>

      <Modal
        show={showModal}
        title={"You are about to delete a house"}
        cancelButtonLabel={"No"}
        confirmButtonLabel={"Yes"}
        isBtnLoading={deleteHouse.isLoading}
        closeModal={() => setShowModal(false)}
        confirmModal={() =>
          deleteHouse.mutate(null, {
            onSuccess: () => {
              setShowModal(false);
              refetchHouseCollection();
            },
          })
        }
      >
        <div className="text-center">
          Are you sure you want to delete {houseToDelete?.name ?? ""},{" "}
          {convertToTitleCase(houseToDelete?.type)} - {houseToDelete?.address}?{" "}
          <strong className="text-danger">This action can't be undone</strong>
        </div>
      </Modal>
    </div>
  );
};

export default MyProperties;
