import React, { useState, useMemo, useEffect } from "react";
import "../Developers/developers.css";
import { useNav } from "../../hooks/useNav";
import InfoCard from "../../Components/Common/InfoCard";
import InfoTable from "../../Components/Common/InfoTable";
import { developerColums } from "./constants";
import Modal from "../../Components/Common/Modal/index";
import FormInput from "../../Components/Common/FormInput";
import Button from "../../Components/Common/Button";
import { colors } from "../../utils/constants";
import Icons from "../../Components/Common/Icons";
import useAuth from "../../hooks/useAuth";
import useAxiosBase from "../../hooks/useAxiosBase";
import { useQuery } from "@tanstack/react-query";
import { getSingleImage } from "../../utils/imageUtils";
import { Link, useNavigate } from "react-router-dom";
import CenteredText from "../../Components/CenteredText";
import Spinner from "../../Components/Spinner";
import { toast } from "react-toastify";
import { defaultImage } from "./constants";
import useGetBanksPartnership from "./hooks/useGetDeveloperPartnership";
import useGetDeveloperCollections from "./hooks/useGetPropertyDeveloper";
import useInviteDeveloper from "./hooks/useInviteDevelopers";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { invitOrganisation } from "../../models/InviteOrganisation";
import { HiDotsVertical } from "react-icons/hi";
import Dropdown from "react-bootstrap/Dropdown";
import Badges from "../../Components/Common/Badges";
import SkeletonLoader from "../../Components/SkeletonLoader";
import TableLoader from "../../Components/Common/TableLoader";
import CellSelector from "../../Components/Common/CellSelector";
import DeveloperCard from "./DeveloperCard";

const Developers = () => {
  useNav({
    mainTitle: "Developer Partnerships",
    subTitle: "The developers you work with.",
  });

  const { authUser } = useAuth();
  const orgID = authUser?.user?.organizationId;

  const [pagination, setPagination] = useState(1);

  const { getRequest } = useAxiosBase();
  const navigate = useNavigate();

  const { data: developerCollections, isLoading: loading } =
    useGetDeveloperCollections();
  const {
    data: developoers,
    isLoading,
    refetch,
    isRefetching,
  } = useGetBanksPartnership(pagination);
  const { inviteDeveloper } = useInviteDeveloper();

  const [slectedDeveloperName, setSelectedDeveloperName] = useState();

  const [requesterID, setRequesterID] = useState("");
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showDevsModal, setShowDevsModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showUnpartnerModal, setShowUnpartnerModal] = useState(false);
  const [showRevokeModal, setShowRevokeModal] = useState(false);
  const [pending, setPending] = useState(false);
  const [search, setSearch] = useState("");

  // INVITE BANK
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ resolver: yupResolver(invitOrganisation) });

  const handleSubmitInvite = (data) => {
    console.log(data);
    // inviteDeveloper.mutate(data);
  };

  const propertyDev = () => {
    if (!search) return developerCollections;
    if (search.length >= 1 && developerCollections?.length > 0) {
      return developerCollections?.filter((x) => {
        return x.name.toLocaleLowerCase().includes(search.toLocaleLowerCase());
      });
    }

    return developerCollections;
  };

  const showPartnerShipModal = (requesterId, status) => {
    setRequesterID(requesterId);

    const selectedDeveloper = developoers?.data?.find(
      (dev) => dev.partner?.id === requesterId
    );

    if (selectedDeveloper) {
      setSelectedDeveloperName(selectedDeveloper?.partner?.name);
    }

    if (status === "PENDING") {
      navigate(`/mortgage-banks/${requesterId}`);
    } else if (status === "ACCEPTED") {
      setShowAcceptModal(true);
    } else if (status === "REJECTED") {
      setShowDeclineModal(true);
    } else if (status === "UNPARTNER") {
      setShowUnpartnerModal(true);
    } else if (status === "REVOKED") {
      setShowRevokeModal(true);
    }
  };

  const handlePartnerShipRequest = (requesterId, status) => {
    getRequest({
      url: `bank/partnership/${requesterId}/respond-to-request/${status}`,
    })
      .then((res) => {
        const responseData = res.data?.value;
        if (responseData.statusCode === 200) {
          toast.success(responseData?.message);
          refetch();
          setShowAcceptModal(false);
          setShowDeclineModal(false);
          setShowUnpartnerModal(false);
          setShowRevokeModal(false);
          setPending(false);
        } else {
          toast.error(responseData?.message);
          setPending(false);
        }
        setPending(false);
      })
      .catch((error) => {
        toast.error("An error occurred while processing your request.");
      });

    refetch();
  };

  // BANK COLUMS TO DISPLAY IN THE TABLE STARTS
  const developerColums = (handlePartnerShipRequest, loading) => [
    {
      name: <p className="fw-bold p-0 m-0"> DEVELOPERS </p>,
      cell: (row) => {
        const displayName =
          row.sender?.id === orgID ? row.partner?.name : row.sender?.name;
        return (
          <CellSelector loading={loading}>
            <div className="d-flex align-items-center gap-1">
              <img
                data-tag="allowRowEvents"
                height="60px"
                width="70px"
                alt={
                  row.sender?.id === orgID
                    ? row?.partner?.name
                    : row?.sender?.name
                }
                src={
                  row.sender?.id === orgID
                    ? row?.partner?.logo
                    : row.sender?.logo
                }
                style={{ borderRadius: "18px", padding: "8px" }}
              />
              <p className="m-0">{displayName}</p>
            </div>
          </CellSelector>
        );
      },
    },
    {
      name: <p className="fw-bold p-0 m-0"> ADDRESS</p>,
      sortable: true,
      selector: (row) => {
        const displayAddress =
          row.sender?.id === orgID ? row.partner?.address : row.sender?.address;
        return <CellSelector loading={loading}>{displayAddress}</CellSelector>;
      },
    },
    {
      name: <p className="fw-bold p-0 m-0"> PROPERTY DETAILS</p>,
      sortable: true,
      selector: (row) => {
        const displayName =
          row.sender?.id === orgID ? row.partner?.name : row.sender?.name;
        return (
          <CellSelector loading={loading}>
            {`${row?.partner?.estateCount ?? 0} Estates`}
            <span className="circle_bar mx-1"></span>
            {row?.partner?.houseCount +
              `${row?.partner?.houseCount <= 1 ? " House" : " Houses"}`}
          </CellSelector>
        );
      },
    },
    {
      name: <p className="fw-bold p-0 m-0">STATUS</p>,
      sortable: true,
      compact: true,
      grow: 0.5,
      selector: (row) => row?.status,
      cell: (row) => {
        let displayText, tooltipText;
        let borderColor, bgColor, textColor;
        switch (row.status) {
          case "ACCEPTED":
            displayText = "Partnered";
            tooltipText = "The customer’s application has been approved";
            borderColor = colors.darkGreen;
            // bgColor = colors.darkGreen;
            textColor = colors.darkGreen;
            break;
          case "PENDING":
            displayText = "Pending";
            tooltipText =
              "All necessary steps have been met, marking the completion of the transaction.";
            borderColor = colors.darkOrange;
            bgColor = colors.yellowLight;
            textColor = colors.darkOrange;
            break;
          case "UNPARTNER":
            displayText = "Unpartnered";
            tooltipText =
              "All necessary steps have been met, marking the completion of the transaction.";
            borderColor = colors.darkGray;
            bgColor = colors.grey;
            textColor = colors.darkGray;
            break;
          case "REVOKED":
            displayText = "Revoked";
            tooltipText =
              "All necessary steps have been met, marking the completion of the transaction.";
            borderColor = colors.darkGray;
            bgColor = colors.grey;
            textColor = colors.darkGray;
            break;
          default:
            displayText = row.status;
            tooltipText = row.status;
            break;
        }

        return (
          <CellSelector loading={loading}>
            <Badges
              text={
                displayText?.length > 17
                  ? `${displayText.substring(0, 13)}..`
                  : displayText
              }
              title={tooltipText}
              backgroundcolor={bgColor}
              bordercolor={borderColor}
              color={textColor}
            />
          </CellSelector>
        );
      },
      wrap: true,
    },
    {
      name: <p className="fw-bold p-0 m-0"> ACTION </p>,
      sortable: false,
      grow: 0.5,
      center: true,
      cell: (row) => (
        <Dropdown>
          <Dropdown.Toggle id={`dropdown-${row.id}`}>
            <HiDotsVertical className="fw-bold text-dark" />
          </Dropdown.Toggle>

          {!loading && (
            <Dropdown.Menu>
              {row.status === "REVOKED" ? (
                <Dropdown.Item
                  onClick={() =>
                    navigate(
                      `/mortgage-banks/${
                        row.sender?.id === orgID
                          ? row.partner.id
                          : row.sender?.id
                      }`
                    )
                  }
                >
                  Resend Request
                </Dropdown.Item>
              ) : row.status === "UNPARTNER" ? (
                <Dropdown.Item
                  onClick={() => handlePartnerShipRequest(row.id, "ACCEPTED")}
                >
                  Partner
                </Dropdown.Item>
              ) : row?.status === "ACCEPTED" ? (
                <Dropdown.Item
                  onClick={() => handlePartnerShipRequest(row.id, "UNPARTNER")}
                >
                  Unpartner
                </Dropdown.Item>
              ) : row?.status === "PENDING" && row?.sender?.id !== orgID ? (
                <>
                  <Dropdown.Item
                    onClick={() => handlePartnerShipRequest(row.id, "ACCEPTED")}
                  >
                    Accept
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="text-danger"
                    onClick={() => handlePartnerShipRequest(row.id, "REJECTED")}
                  >
                    Decline
                  </Dropdown.Item>
                </>
              ) : row?.status === "PENDING" && row?.sender?.id === orgID ? (
                <Dropdown.Item
                  onClick={() => handlePartnerShipRequest(row.id, "REVOKED")}
                >
                  Revoke Partnership
                </Dropdown.Item>
              ) : row?.status === "NULL" ? (
                <Dropdown.Item
                  onClick={() => handlePartnerShipRequest(row.id, "PENDING")}
                >
                  Request Partnership
                </Dropdown.Item>
              ) : null}
            </Dropdown.Menu>
          )}
        </Dropdown>
      ),
    },
  ];
  // BANK COLUMS TO DISPLAY IN THE TABLE ENDS

  useEffect(() => {
    if (pagination) {
      refetch();
    }
  }, [pagination]);
  return (
    <div className="developers container-fluid">
      <div className="d-flex justify-content-end gap-3 pt-2 pb-3">
        {/* <div>
          <Button
            btnText={"Invite Developers"}
            iconcolor={colors.primary}
            btntextcolor={colors.primary}
            backgroundcolor={colors.white}
            iconcolorHover={colors.hover}
            colorHover={colors.hover}
            borderHover={colors.hover}
            backgroundHover={colors.white}
            padding={" 16px"}
            onBtnClick={() => setShowInviteModal(true)}
          />
        </div> */}
        <div>
          <Button
            btnText={"Add Developers"}
            onBtnClick={() => {
              propertyDev();
              setShowDevsModal(true);
            }}
            disabled={!developerCollections}
          />
        </div>
      </div>

      <div className="my-3">
        <InfoCard
          mainValue={
            isLoading || isRefetching ? (
              <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
            ) : (
              developoers?.totalRecords ?? 0
            )
          }
          description={
            isLoading || isRefetching ? <SkeletonLoader /> : "Patner Developers"
          }
        />
      </div>

      <div className="my-4">
        {isLoading ? (
          <div className="mt-4">
            <TableLoader />
          </div>
        ) : developoers?.data?.length > 0 ? (
          <div className="mt-3">
            <InfoTable
              columns={developerColums(
                (rowId, status) => showPartnerShipModal(rowId, status),
                isRefetching
              )}
              dataCollection={developoers?.data}
              pointerOnHover={true}
              highlightOnHover={true}
              loadingTable={isRefetching}
              paginationTotalRows={developoers?.totalRecords}
              onChangePage={(page) => setPagination(page)}
              onRowClicked={(row, event) =>
                navigate(
                  `/developers/${
                    row.sender?.id === orgID ? row.partner.id : row.sender?.id
                  }`
                )
              }
            />
          </div>
        ) : (
          <CenteredText title={"You are not partnered with any developers"}>
            Click the “add developers" button above to create a partnership
            between a bank.
          </CenteredText>
        )}
      </div>

      {/* INVITE DEVELOPER */}
      <Modal
        title="Invite Developers"
        show={showInviteModal}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Invite"}
        confirmModal={handleSubmit(handleSubmitInvite)}
        closeModal={() => setShowInviteModal(false)}
        isBtnLoading={pending}
      >
        <form>
          <div className="row d-flex justify-content-center">
            <div className="col-8">
              <FormInput
                labelName={"Name"}
                required
                register={register("organisationName")}
                error={errors?.organisationName?.message}
              />
              <FormInput
                labelName={"Email"}
                required
                register={register("organisationEmail")}
                error={errors?.organisationEmail?.message}
              />
              <FormInput
                labelName={"Phone No"}
                required
                register={register("organisationPhone")}
                error={errors?.organisationPhone?.message}
              />
            </div>
          </div>
        </form>
      </Modal>

      {/* ADD DEVELOPER */}
      <Modal
        show={showDevsModal}
        title={`Developers on Gidda-(${developerCollections?.length ?? "0"})`}
        closeModal={setShowDevsModal}
        width={"100%"}
        height={"100%"}
        titleFont={"Millik"}
        titleFontSize={"38px"}
        crossLine={true}
        closeModalIcon={true}
      >
        {/* <div className="row mt-3 mb-3">
          <FormInput
            placeholder="Search for Banks"
            lefticonname={"search"}
            value={search}
            onChange={(name, value) => setSearch(value)}
          />
        </div> */}

        <div className="row mt-4">
          {loading ? (
            <Spinner />
          ) : (
            developerCollections?.length > 0 &&
            developerCollections.map((dev) => (
              <div
                key={dev.id}
                className="col-sm-6 col-md-3"
                onClick={() => navigate(`/developers/${dev.id}`)}
              >
                <DeveloperCard developer={dev} />
              </div>
            ))
          )}
        </div>
      </Modal>

      {/* ACCEPT PARTNERSHIP */}
      <Modal
        title="Accept Partnership Request"
        show={showAcceptModal}
        cancelButtonLabel={"No"}
        confirmButtonLabel={"Yes"}
        confirmModal={() => {
          setPending(true);
          handlePartnerShipRequest(requesterID, "ACCEPTED");
        }}
        closeModal={() => {
          setPending(false);
          setShowAcceptModal(false);
        }}
        isBtnLoading={pending}
        disabled={pending}
      >
        <p className="text-center">
          Are you sure you want to accept the request to partner with{" "}
          {slectedDeveloperName}?{" "}
          <strong>
            This will give you access to sell their properties through your
            plans.
          </strong>
        </p>
      </Modal>

      {/* DECLINE PARTNERSHIP */}
      <Modal
        title="Reject Developer Partnership Request"
        show={showDeclineModal}
        cancelButtonLabel={"No"}
        confirmButtonLabel={"Yes"}
        confirmModal={() => {
          setPending(true);
          handlePartnerShipRequest(requesterID, "REJECTED");
        }}
        closeModal={() => {
          setPending(false);
          setShowDeclineModal(false);
        }}
        isBtnLoading={pending}
        disabled={pending}
      >
        <p className="text-center">
          Are you sure you want to decline the request to partner with{" "}
          {slectedDeveloperName}?{" "}
          <strong>This will give them access to sell your properties.</strong>
        </p>
      </Modal>

      {/* UNPARTNER DEVELOPER */}
      <Modal
        title="Unpartner with Developer"
        show={showUnpartnerModal}
        cancelButtonLabel={"No"}
        confirmButtonLabel={"Yes"}
        confirmModal={() => {
          setPending(true);
          handlePartnerShipRequest(requesterID, "UNPARTNER");
        }}
        closeModal={() => {
          setPending(false);
          setShowUnpartnerModal(false);
        }}
        isBtnLoading={pending}
        disabled={pending}
      >
        <p className="text-center">
          Are you sure you want to unpartner with {slectedDeveloperName}?{" "}
          <strong>
            This will mean you won’t be able to access any of their properties
            anymmore.
          </strong>
        </p>
      </Modal>

      {/* REVOKE DEVELOPER */}
      <Modal
        title="Revoke partnership with this Developer"
        show={showRevokeModal}
        cancelButtonLabel={"No"}
        confirmButtonLabel={"Yes"}
        confirmModal={() => {
          setPending(true);
          handlePartnerShipRequest(requesterID, "REVOKED");
        }}
        closeModal={() => {
          setPending(false);
          setShowRevokeModal(false);
        }}
        isBtnLoading={pending}
        disabled={pending}
      >
        <p className="text-center">
          Are you sure you want to revoke your partnership request{" "}
          {slectedDeveloperName}?{" "}
          <strong>
            This will mean you won’t be able to access any of their properties
            anymore.
          </strong>
        </p>
      </Modal>
    </div>
  );
};

export default Developers;
