import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useAddProspectOfficer = (applicationUserId, status) => {
  const { getRequest } = useAxiosBase();

  const addProspectOfficer = useMutation({
    mutationKey: ["prospect-officer", applicationUserId],
    mutationFn: (data) =>
      getRequest({
        url: `/bank/user/${applicationUserId}/update-as-prospect-officer?status=${status}`,
        data: data,
      }),
      onSuccess: (res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success(res?.data?.value?.message);
        } else {
          toast.error(res?.response?.data?.value?.message || "An error occurred");
        }
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message || "An error occurred");
      },
  });

  return { addProspectOfficer };
};

export default useAddProspectOfficer;