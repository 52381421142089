import React, { useState } from "react";
import useGetPendingHouses from "../House/hooks/useGetPendingHouses";
import useAddDeveloperHouses from "../House/hooks/useAddDeveloperHouses";
import useGetPlans from "../../../Plans/hooks/useGetPlans";
import Modal from "../../../../Components/Common/Modal";
import Spinner from "../../../../Components/Spinner";
import { convertToTitleCase } from "../../../../utils/constants";
import HouseCard from "../House/HouseCard";
import CenteredText from "../../../../Components/CenteredText";
import PlanCard from "../PlanCard.jsx";
import Button from "../../../../Components/Common/Button/index.jsx";

const PendingPropertyModal = ({
  pendingCount,
  refetchPendingCount,
  refetchAcceptedProperty,
  openPropertyModal,
  setOpenPropertyModal,
}) => {
  const {
    data: pendingPropertyCollection,
    isLoading,
    refetch,
  } = useGetPendingHouses();

  const { addDeveloperHouses } = useAddDeveloperHouses();

  const { data: mortgagePlans } = useGetPlans();

  const [houseIds, setHouseIds] = useState([]);
  const [formData, setFormData] = useState({
    houseIds: [],
    mortgagePlanIds: [],
  });

  const [showPlans, setShowPlans] = useState(false);

  const [openAddAllModal, setOpenAddAllModal] = useState(false);

  const [selectedEstate, setSelectedEstate] = useState("");

  const handleAddRemoveHouse = (houseId) => {
    const newHouseIds = houseIds.includes(houseId)
      ? houseIds.filter((id) => id !== houseId)
      : [...houseIds, houseId];

    setHouseIds(newHouseIds);
    setFormData({
      ...formData,
      houseIds: newHouseIds,
    });
  };

  // ADD ALL PROPERTIES ID's
  const handleAddAllProperties = () => {
    if (pendingPropertyCollection) {
      const allHouseIds = pendingPropertyCollection?.map((house) => house.id);
      setHouseIds(allHouseIds);
      setFormData({
        ...formData,
        houseIds: allHouseIds,
      });
      setOpenAddAllModal(false); // Close the modal after adding all properties
    }
  };

  const handleTogglePlan = (planId) => {
    const newPlanIds = formData?.mortgagePlanIds?.includes(planId)
      ? formData?.mortgagePlanIds?.filter((id) => id !== planId)
      : [...formData?.mortgagePlanIds, planId];

    setFormData({
      ...formData,
      mortgagePlanIds: newPlanIds,
    });
  };

  const isConfirmButtonDisabled = () => {
    if (!showPlans && houseIds.length === 0) return true;
    if (showPlans && formData.mortgagePlanIds.length === 0) return true;
    return false;
  };

  return (
    <>
      <Modal
        show={openPropertyModal}
        title={
          showPlans
            ? "Select Plans"
            : `New Houses From Your Partner Developers - ${pendingCount ?? 0}`
        }
        subTitle={
          showPlans
            ? "Please select the mortgage plans you wish to add to the selected houses"
            : `Select at least one house to proceed with adding mortgage plans.`
        }
        width={"100%"}
        height={"100%"}
        titleFontSize={"38px"}
        crossLine={false}
        confirmButtonLabel={"Proceed"}
        cancelButtonLabel={"Cancel"}
        closeModalIcon
        backBtn={showPlans ? true : false}
        onBtnClick={() => {
          if (showPlans) {
            setShowPlans(false);
            setFormData({
              ...formData,
              mortgagePlanIds: [],
            });
          }
        }}
        closeModal={() => {
          setShowPlans(false);
          setHouseIds([]);
          setFormData({ houseIds: [], mortgagePlanIds: [] });
          setOpenPropertyModal(false);
        }}
        confirmModal={() => {
          if (showPlans) {
            addDeveloperHouses.mutate(formData, {
              onSuccess: () => {
                refetch();
                refetchPendingCount();
                refetchAcceptedProperty();
                setShowPlans(false);
                setHouseIds([]);
                setFormData({ houseIds: [], mortgagePlanIds: [] });
                setOpenPropertyModal(false);
              },
            });
          } else {
            setShowPlans(true);
          }
        }}
        isBtnLoading={addDeveloperHouses.isLoading}
        disabled={isConfirmButtonDisabled() || addDeveloperHouses.isLoading}
      >
        <div>
          {/* <div className="row mt-3 mb-3">
      <FormInput
        placeholder="Search for Developers"
        lefticonname={"search"}
        value={search}
        onChange={(name, value) => setSearch(value)}
      />
    </div> */}

          <div className="row px-4 mt-4">
            {isLoading ? (
              <div style={{ height: "50vh" }}>
                <Spinner loadingText={"Loading Estates..."} />
              </div>
            ) : pendingPropertyCollection?.length > 0 ? (
              showPlans ? (
                mortgagePlans?.data?.length > 0 ? (
                  mortgagePlans?.data?.map((plan) => (
                    <div key={plan.id} className="col-md-3 mt-1">
                      <PlanCard
                        planName={plan.name}
                        description={convertToTitleCase(plan.deliveryLevel)}
                        checked={formData?.mortgagePlanIds?.includes(plan.id)}
                        onChange={() => handleTogglePlan(plan.id)}
                      />
                    </div>
                  ))
                ) : (
                  <div className="mt-5">
                    <CenteredText title={"No Plans Available"}>
                      Unfortunately, there are no mortgage plans available at
                      the moment. Please check back later or contact our support
                      for more information.
                    </CenteredText>
                  </div>
                )
              ) : (
                <>
                  <div className="d-flex justify-content-end mb-4">
                    <Button
                      btnText={"Add All"}
                      onBtnClick={() => setOpenAddAllModal(true)}
                    />
                  </div>
                  {pendingPropertyCollection?.map((house) => (
                    <div key={house.id} className="col-md-3 mt-1">
                      <HouseCard
                        house={house}
                        houseIds={houseIds}
                        handleAddRemoveHouse={handleAddRemoveHouse}
                      />
                    </div>
                  ))}
                </>
              )
            ) : (
              <div className="mt-5">
                <CenteredText title="No Pending Houses">
                  Currently, there are no pending Houses. Please check back
                  later.
                </CenteredText>
              </div>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        show={openAddAllModal}
        title={"Add all Properties"}
        subTitle={
          <p className="mt-3">
            By adding all, you will be adding all of the properties in{" "}
            <strong>{selectedEstate?.name}</strong> to whatever Mortgage Plan
            you select on the next page.{" "}
            <strong>Do you still want to add all?</strong>
          </p>
        }
        confirmButtonLabel={"Add"}
        cancelButtonLabel={"Cancel"}
        confirmModal={handleAddAllProperties}
        closeModal={() => setOpenAddAllModal(false)}
      ></Modal>
    </>
  );
};

export default PendingPropertyModal;
