import React, { useMemo, useState } from "react";
import FormInput from "../../../../Components/Common/FormInput";
import { colors } from "../../../../utils/constants";
import { Link } from "react-router-dom";
import EstateCards from "./EstateCards";
import { useNavigate } from "react-router-dom";
import CenteredText from "../../../../Components/CenteredText";

const EstatesModalContent = ({ estates, createLink }) => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const estatesCollection = useMemo(() => {
    if (!search) return estates;
    if (search.length >= 1 && estates?.length > 0) {
      return estates?.filter((x) => {
        return x.name.toLocaleLowerCase().includes(search.toLocaleLowerCase());
      });
    }
    return estates;
  }, [estates, search]);
  return (
    <div>
      {/* <div className="row mt-3 mb-3">
        <FormInput
          placeholder="Search for Estates"
          righticonname={"search"}
          backgroundcolor={colors.grey}
          bordercolor={colors.grey}
          value={search}
          onChange={(name, value) => setSearch(value)}
        />
      </div> */}
      <p className="m-0">
        Can't find the estate?
        <span className="anchor" onClick={() => navigate(createLink)}>
          {" "}
          Create the Estate
        </span>
      </p>
      <div className="row">
        {estatesCollection?.length > 0 ? (
          estatesCollection?.map((er) => (
            <div
              key={er.id}
              className="col-3"
              onClick={() =>
                navigate(`/properties/my-properties/create-house/${er.id}`)
              }
            >
              <EstateCards estate={er} key={er.id} />
            </div>
          ))
        ) : (
          <div className="my-5">
            <CenteredText title={"No Estates Found"}>
              An estate is a collection of houses in the same location. Close
              and then click the “add estate” button to create an estate and
              then add the types of houses that are available in the estate.
            </CenteredText>
          </div>
        )}
      </div>
    </div>
  );
};

export default EstatesModalContent;
