import React, { useState, useEffect } from "react";
import FormInput from "../../../Components/Common/FormInput";
import {
  colors,
  formatPercentage,
  onlyNumbers,
  getNumber,
  formattedPrice,
} from "../../../utils/constants";
import { useForm } from "react-hook-form";
import CheckInput from "../../../Components/Common/CheckInput";
import useSubmitLoanTerms from "../hooks/useSubmitLoanTerms";
import { useRef } from "react";
import Modal from "../../../Components/Common/Modal";

const PaymentPlan = ({
  application,
  showSubmitModal,
  setShowSubmitModal,
  refetch,
}) => {
  const docRef = useRef();

  const [planDetails, setPlanDetails] = useState(application);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const [allowEquityChange, setAllowEquityChange] = useState(true);

  const [homePrice, setHomePrice] = useState();
  const [payableEquity, setPayableEquity] = useState();
  const [payableTenor, setPayableTenor] = useState();
  const [downPayment, setDownPayment] = useState();
  const [interestRate, setInterestRate] = useState();
  const [monthlyRepayment, setMonthlyRepayment] = useState();

  const { submitLoanTerms, isLoading } = useSubmitLoanTerms(application.id);

  const {
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const dataToSubmit = {
      payableLoanTenor: Number(payableTenor),
      payableEquityPercentage: Number(payableEquity),
      allowEquityChange: allowEquityChange,
      payableHousePrice: Number(getNumber(homePrice)),
    };

    // console.log("dataToSubmit", dataToSubmit);
    submitLoanTerms.mutate(dataToSubmit, {
      onSuccess: () => {
        setIsSubmiting(false);
        setShowSubmitModal(false);
        refetch();
      },
    });
  };

  useEffect(() => {
    reset(planDetails);
  }, [planDetails, reset]);

  useEffect(() => {
    if (planDetails) {
      setHomePrice(formattedPrice(planDetails?.payableHousePrice));
      setPayableEquity(planDetails?.payableEquity);
      setPayableTenor(planDetails?.mortgagePlan?.loanTenor);
      const calculatedDownPayment =
        (planDetails?.payableEquity / 100) * planDetails?.payableHousePrice;
      setDownPayment(calculatedDownPayment);

      if (planDetails?.mortgagePlan?.interestRate < 1) {
        setInterestRate("0%");
      } else {
        setInterestRate(
          formatPercentage(planDetails?.mortgagePlan?.interestRate?.toFixed(2))
        );
      }
    }
  }, [planDetails]);

  useEffect(() => {
    if (planDetails) {
      // CALCULATE MONTLY REPAYMENT
      let interestRate = planDetails?.mortgagePlan?.interestRate;
      let loanTenor = payableTenor;
      let equityPercentage = payableEquity;
      let housePrice = getNumber(homePrice);

      let r = interestRate != 0 ? interestRate / 100 / 12 : 0;
      let n = loanTenor;
      equityPercentage = equityPercentage / 100;
      let equity = housePrice * equityPercentage;
      let p = housePrice - equity;

      if (interestRate != 0) {
        // PMT Formular = p * r * (1 + r) ^ n / (1 + r) ^ (n - 1);

        let power = (1 + r) ^ n;
        let pxr = p * r
        let prXpower = pxr * power
        let power1 = power - 1
        let amount = prXpower / power1

        setMonthlyRepayment(amount);
      } else {
        if (loanTenor == 0 && equityPercentage == 100) {
          setMonthlyRepayment(0);
        } else {
          setMonthlyRepayment(p / loanTenor);
        }
      }
    }
  }, [planDetails, payableTenor, homePrice, payableEquity]);

  return (
    <div className="container">
      <Modal
        show={showSubmitModal}
        title={"Send Loan Terms"}
        width={"100%"}
        height={"100%"}
        titleFont={"Millik"}
        titleFontSize={"38px"}
        closeModalIcon
        crossLine={false}
        closeModal={() => setShowSubmitModal(false)}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Send"}
        footerBankground={colors.grey}
        isBtnLoading={submitLoanTerms.isLoading}
        disabled={submitLoanTerms.isLoading}
        confirmModal={handleSubmit(onSubmit)}
      >
        <div className="col-md-6 text-center m-auto">
          <small style={{ fontFamily: '"Red Hat Display", sans-serif' }}>
            Submit the terms you are offering {planDetails?.customer?.name}.
            This will ensure that they understand exactly what you are offering
            them. If you and {planDetails?.customer?.name} negotiated a
            different price, you can update the price to reflect your
            negotiation.
          </small>
        </div>

        <form className="row mt-4 px-3 m-auto container-fulid">
          <div
            className="col-sm-6 col-md-4 mt-3"
            onBlur={(e) =>
              setValue(
                "payableHousePrice",
                !onlyNumbers(e.target.value)
                  ? formattedPrice(homePrice)
                  : formattedPrice(e.target.value)
              )
            }
          >
            <FormInput
              required
              labelName={"Home Price"}
              placeholder={"N40,000,000"}
              name={"payableHousePrice"}
              // register={register("payableHousePrice")}
              value={homePrice}
              error={errors?.homePrice?.message}
              backgroundcolor={"white"}
              bordercolor={colors.darkGreen}
              onChange={(name, value) => {
                const calculatedDownPayment =
                  (payableEquity / 100) * getNumber(value);
                setDownPayment(calculatedDownPayment);
                setHomePrice(formattedPrice(value));
              }}
            />
          </div>
          <div className="col-sm-6 col-md-4 mt-3 position-relative">
            {planDetails?.payableEquity === 0 ? (
              <FormInput
                labelName={"Equity Percentage (%)"}
                required
                placeholder={"0"}
                disabled={true}
                hoverBackgroundcolor={colors.grey}
              />
            ) : (
              <>
                <FormInput
                  type={"number"}
                  min={0}
                  required
                  labelName={"Equity Percentage (%)"}
                  placeholder={"Down Payment"}
                  // register={register("payableEquity")}
                  value={payableEquity}
                  // error={errors?.payableEquity?.message}
                  backgroundcolor={"white"}
                  bordercolor={colors.darkGreen}
                  onChange={(name, value) => {
                    if (value > planDetails?.payableEquity) {
                      return;
                    } else {
                      // Update payableEquity state
                      setPayableEquity(value);

                      // Calculate downPayment based on updated payableEquity
                      const calculatedDownPayment =
                        (value / 100) * getNumber(homePrice);
                      setDownPayment(calculatedDownPayment);
                    }
                  }}
                />
                <p
                  className="position-absolute m-0"
                  style={{ fontSize: "11px", bottom: "-0.2rem", left: "1rem" }}
                >
                  The Equity Percentage can only be lowered
                </p>
              </>
            )}
          </div>

          <div className="col-sm-6 col-md-4 mt-3 position-relative">
            <FormInput
              labelName={"Payment Period (Months)"}
              type={"number"}
              required
              placeholder={"Payment Period"}
              value={payableTenor}
              error={errors?.loanTenor?.message}
              backgroundcolor={"white"}
              bordercolor={colors.darkGreen}
              onChange={(name, value) => setPayableTenor(value)}
              // onChange={(name, value) => {
              //   if (value > planDetails?.mortgagePlan?.loanTenor) {
              //     return;
              //   } else {
              //     setPayableTenor(value);
              //   }
              // }}
            />
            {/* <p
              className="position-absolute m-0"
              style={{ fontSize: "11px", bottom: "-0.2rem", left: "1rem" }}
            >
              The Payment Period amount can only be lowered
            </p> */}
          </div>

          <div className="col-sm-6 col-md-4 mt-3">
            <FormInput
              labelName={"Down Payment"}
              placeholder={"Down Payment"}
              value={formattedPrice(downPayment)}
              error={errors?.downPayment?.message}
              disabled={true}
            />
          </div>
          <div className="col-sm-6 col-md-4 mt-3">
            <FormInput
              labelName={"Interest Rate"}
              placeholder={"Interest Rate"}
              register={register("interestRate")}
              value={interestRate}
              error={errors?.interestRate?.message}
              disabled={true}
            />
          </div>
          {/* <div className="col-sm-6 col-md-4 mt-3">
            <FormInput
              labelName={"Payment Period"}
              placeholder={"Loan Tenor"}
              register={register("loanTenor")}
              value={`${planDetails?.mortgagePlan?.loanTenor} Months`}
              error={errors?.loanTenor?.message}
              disabled={true}
            />
          </div> */}
          <div className="col-sm-6 col-md-4 mt-3">
            <FormInput
              labelName={"Monthly Repayments"}
              placeholder={"Monthly Repayments"}
              value={
                monthlyRepayment
                  ? formattedPrice(monthlyRepayment?.toFixed(2))
                  : 0
              }
              register={register("monthlyPayment")}
              error={errors?.monthlyPayment}
              disabled={true}
            />
          </div>
          <div className="col-sm-6 col-md-4 mt-3">
            <FormInput
              labelName={"Earnings After Tenor"}
              placeholder={"Earnings After Tenor"}
              value={formattedPrice(
                (monthlyRepayment * payableTenor)?.toFixed(2)
              )}
              register={register("earningsAfterTenor")}
              error={errors?.earningsAfterTenor?.message}
              disabled={true}
            />
          </div>
          <div className="col-sm-6 col-md-4 mt-3">
            <FormInput
              labelName={"Default Fee (%)"}
              placeholder={"Default Fee"}
              value={`${planDetails?.mortgagePlan?.defaultFee}%`}
              error={errors?.defaultFee?.message}
              disabled={true}
            />
          </div>
          <div className="col-sm-6 col-md-4 mt-3">
            <FormInput
              labelName={"Grace Period"}
              placeholder={"Grace Period"}
              value={`${planDetails?.mortgagePlan?.gracePeriod} Days`}
              error={errors?.gracePeriod?.message}
              disabled={true}
            />
          </div>
          <div className="row col-10 p-3">
            <CheckInput
              label={"I want customer’s to be able to pay a higher downpayment"}
              width={"30px"}
              height={"30px"}
              borderradius={"8px"}
              className={"m-3"}
              required={true}
              checked={allowEquityChange}
              checkTick={true}
              register={register("higherDownPayment")}
              name={"higherDownPayment"}
              onChange={() => setAllowEquityChange(!allowEquityChange)}
              bordercolor={colors.green}
              labelMT={"0.4rem"}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default PaymentPlan;
