import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosBase from "../../hooks/useAxiosBase";
import useAuth from "../../hooks/useAuth";
import Spinner from "../../Components/Spinner";
import { useNav } from "../../hooks/useNav";
import { getImage, getSingleImage } from "../../utils/imageUtils";
import { toast } from "react-toastify";
import Icons from "../../Components/Common/Icons";
import { defaultImage, plansColumns } from "./constants";
import useGetPropertyDeveloperDetails from "./hooks/useGetPropertyDeveloperDetails";
import useSendPartnershipRequest from "./hooks/useSendPertnershipRequest";
import Button from "../../Components/Common/Button";
import Modal from "../../Components/Common/Modal";
import CenteredText from "../../Components/CenteredText";
import useGetSingleDeveloper from "./hooks/useGetSingleDeveloper";
import TabGroup from "../../Components/TabGroup";
import LicenseAndCertificationDoc from "../../Components/Common/License&CertificationDoc";
import { convertToTitleCase } from "../../utils/constants";
import InfoTable from "../../Components/Common/InfoTable";
import { columns } from "./constants";
import PropertyCompletionStatus from "../../Components/Common/PropertyCompletionStatus";
import useGetDeveloperPartnershipTerms from "./hooks/useGetDeveloperPartnershipTerms";

const DeveloperDetails = () => {
  useNav({
    hasBackButton: true,
  });

  const { authUser } = useAuth();
  const orgID = authUser?.user?.organizationId;

  const navigate = useNavigate();
  const params = useParams();
  const devId = params?.id;

  const { getRequest } = useAxiosBase();

  const [developerId, setDeveloperId] = useState(devId);

  const [developerDetails, setDeveloperDetails] = useState()

  const {
    data: getDeveloperDetails,
    isLoading,
    error,
    refetch,
    isRefetching,
  } = useGetSingleDeveloper(devId);

  const { data: getPartnershipTerms, isLoading: loadingPartnershipTerms } =
    useGetDeveloperPartnershipTerms(developerDetails?.organization?.id);

  const [bankOrgName, setBankOrgName] = useState();
  const [bankOrgID, setBankOrgID] = useState();
  const [orgRequestId, setOrgRequestId] = useState();
  const [buttonText, setButtonText] = useState();
  const [declineBtn, setDeclineBtn] = useState(false);
  const [pending, setPending] = useState(false);

  const { sendPartnershipRequest } = useSendPartnershipRequest(developerId);
  const [showModal, setShowModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showUnpartnerModal, setShowUnpartnerModal] = useState(false);
  const [showRevokeModal, setShowRevokeModal] = useState(false);

  const handleBtnClick = () => {
    if (buttonText === "Request Partnership") {
      setShowModal(true);
    } else if (buttonText === "Revoke Partnership") {
      setShowRevokeModal(true);
    } else if (buttonText === "Accept Partnership") {
      setShowAcceptModal(true);
    }
  };

  const [slectedDeveloperName, setSelectedDeveloperName] = useState();

  const prevFinished = [
    {
      label:
        getPartnershipTerms?.fullyFinishedFurnished &&
        "Fully Finished (Furnished)",
    },
    {
      label:
        getPartnershipTerms?.fullyFinishedNotFurnished &&
        "Fully Finished (Not Furnished)",
    },
  ];

  const prevConstruction = [
    {
      label: getPartnershipTerms?.semiFinished && "Semi-Finished",
    },
    {
      label: getPartnershipTerms?.shell && "Shell",
    },
    {
      label: getPartnershipTerms?.carcass && "Carcass",
    },
  ];

  useEffect(() => {
    refetch();
  }, [devId]);

  useEffect(() => {
    setDeveloperDetails(getDeveloperDetails);

    if (developerDetails) {
      setBankOrgName(developerDetails?.organization?.name);
      setBankOrgID(developerDetails?.organization?.id);

      if (developerDetails.status === null) {
        setButtonText("Request Partnership");
      } else if (
        developerDetails.status === "PENDING" &&
        orgID !== developerDetails.id
      ) {
        setButtonText("Revoke Partnership");
      } else if (
        developerDetails.status === "PENDING" &&
        orgID === developerDetails.id
      ) {
        setDeclineBtn(true);
        setButtonText("Accept Partnership");
      } else if (developerDetails.status === "ACCEPTED") {
        setButtonText("Revoke Partnership");
      } else {
        setButtonText(false);
      }

      if (developerDetails.id) {
        setOrgRequestId(developerDetails.id);
      }
    }
  }, [getDeveloperDetails, developerDetails]);

  const handlePartnerShipRequest = (requesterId, status) => {
    getRequest({
      url: `bank/partnership/${requesterId}/respond-to-request/${status}`,
    })
      .then((res) => {
        const responseData = res.data?.value;
        if (responseData.statusCode === 200) {
          toast.success(responseData?.message);
          refetch();
          setShowAcceptModal(false);
          setShowDeclineModal(false);
          setShowUnpartnerModal(false);
          setShowRevokeModal(false);
          setPending(false);
        } else {
          toast.error(responseData?.message);
          setPending(false);
        }
        setPending(false);
      })
      .catch((error) => {
        toast.error("An error occurred while processing your request.");
      });

    refetch();
  };

  if (isLoading || isRefetching)
    return (
      <div style={{ height: "60vh" }}>
        <Spinner loadingText={"Loading Developer Details..."} />
      </div>
    );

  return (
    <div className="px-2">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="h3 pt-2">{developerDetails?.organization?.name}</h3>
            <div className="d-flex gap-4">
              <div className="">
                <Button
                  btnText={buttonText}
                  onBtnClick={() => handleBtnClick()}
                  disabled={!buttonText}
                />
              </div>
              {declineBtn && (
                <div className="">
                  <Button
                    btnText={"Decline Partnership"}
                    onBtnClick={() => setShowDeclineModal(true)}
                    padding={"16px"}
                  />
                </div>
              )}
            </div>
          </div>
          <TabGroup>
            {/* OVERVIEW TAB */}
            <div label="OVERVIEW">
              <div className="row mb-5">
                <div className="bank-image-details img-fluid d-flex justify-content-start mb-4">
                  <img
                    src={getSingleImage(developerDetails?.organization?.logo)}
                    alt="property"
                  />
                </div>

                {developerDetails?.organization?.description && (
                  <div className="mb-3">
                    <h4 className="h4">Description</h4>
                    <p>{developerDetails?.organization?.description}</p>
                  </div>
                )}

                <div className="mb-3">
                  <h4 className="h4">Contact Details</h4>
                  <div className="d-flex gap-4">
                    <div className="text-bold">
                      <Icons iconName={"envolope"} className="devIcon" />
                      <a
                        href={`mailto:${developerDetails?.organization?.emailAddress}`}
                        className="devEmail"
                      >
                        <span className="p-1">
                          {developerDetails?.organization?.emailAddress}
                        </span>
                      </a>
                    </div>
                    <div className="text-bold">
                      <Icons iconName={"phone"} className="devIcon" />
                      <a
                        href={`tel:${developerDetails?.organization?.phoneNumber}`}
                        className="text-decoration-none"
                      >
                        <span className="p-1 ps-2">
                          {developerDetails?.organization?.phoneNumber}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 p-3 pt-4 ">
                  <h4 className="h4 mt-1">Bank Relationships</h4>
                  <p className="text-success fw-bold">
                    WORKS WITH{" "}
                    {developerDetails?.organization?.partnerships?.length}{" "}
                    PROPERTY DEVELOPERS
                  </p>
                  {developerDetails?.organization?.partnerships?.length ? (
                    developerDetails?.organization?.partnerships?.map(
                      (partner) => (
                        <div className="relationships border border-success">
                          <img
                            src={defaultImage}
                            height="60px"
                            width="60px"
                            style={{ borderRadius: "18px", padding: "8px" }}
                          />
                          <p>{partner.name}</p>
                          <div className="mb-1">
                            <Button
                              btnText={"View Developer"}
                              width={"95"}
                              btnTextFont={"12"}
                              // onBtnClick={() => setShowModal(true)}
                              padding={"4px 0"}
                            />
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <CenteredText title={"No Bank Partnerships"}>
                      {" "}
                      {developerDetails?.organization?.name} currently doesn't
                      have no partnerships with any Bank.
                    </CenteredText>
                  )}
                </div>

                <div className="col-md-12 px-3 py-1 m-0">
                  <h4 className="h4">Licenses & Certifications</h4>
                  <div className="row">
                    {developerDetails?.organization?.documents?.length ? (
                      developerDetails?.organization?.documents?.map((doc) => (
                        <div className="col-md-2 mb-3">
                          <LicenseAndCertificationDoc
                            name={convertToTitleCase(doc.optionId)}
                            extension={doc.extension?.toUpperCase()}
                          />
                        </div>
                      ))
                    ) : (
                      <CenteredText title={"No Licenses & Certifications"}>
                        {" "}
                        You currently do not have any licenses or certifications
                        with developers. Click the “Request Partnership” button
                        to create a partnership with a developer.
                      </CenteredText>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* PROPERTIES TAB */}
            <div label="PROPERTIES" className="mb-4">
              {isLoading ? (
                <div style={{ height: "35vh" }}>
                  <Spinner loadingText={"Loading Properties..."} />{" "}
                </div>
              ) : !developerDetails?.organization?.houses?.length ? (
                <div className="mt-5">
                  <CenteredText title={"There are no Properties at this time"}>
                    {" "}
                    When a Developer adds a Property, the information will be
                    displayed here.
                  </CenteredText>
                </div>
              ) : (
                <div className="col-12">
                  <InfoTable
                    columns={columns(isRefetching)}
                    dataCollection={developerDetails?.organization?.houses}
                    pointerOnHover={true}
                    highlightOnHover={true}
                    loadingTable={isRefetching}
                    paginationTotalRows={
                      developerDetails?.organization?.houses?.length
                    }
                    // onChangePage={(page) => setPagination(page)}
                    // onRowClicked={(row, event) =>
                    //   navigate(`/plans/edit/${row?.id}`)
                    // }
                  />
                </div>
              )}
            </div>

            {/* PLANS TAB */}
            {/* <div label="PLANS" className="mb-4">
              {isLoading ? (
                <div style={{ height: "35vh" }}>
                  <Spinner loadingText={"Loading plans..."} />{" "}
                </div>
              ) : !developerDetails?.organization?.plan?.length ? (
                <div className="mt-5">
                  <CenteredText title={"There are no Plans at this time"}>
                    {" "}
                    When a Developer adds a Plan, the information will be
                    displayed here.
                  </CenteredText>
                </div>
              ) : (
                <div className="col-12">
                  <InfoTable
                    columns={plansColumns(isRefetching)}
                    dataCollection={plans?.data}
                    pointerOnHover={true}
                    highlightOnHover={true}
                    loadingTable={isRefetching}
                    paginationTotalRows={plans?.totalRecords}
                    onChangePage={(page) => setPagination(page)}
                    onRowClicked={(row, event) =>
                      navigate(`/plans/edit/${row?.id}`)
                    }
                  />
                </div>
              )}
            </div> */}
          </TabGroup>
        </>
      )}

      {/* REQUEST PARTNERSHIP */}
      <Modal
        title={`${bankOrgName} Partnership Request`}
        show={showModal}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Send"}
        closeModalIcon={true}
        width={"100%"}
        height={"100%"}
        crossLine={false}
        titleFontSize={"38px"}
        closeModal={() => setShowModal(false)}
        confirmModal={() =>
          sendPartnershipRequest.mutate(null, {
            onSuccess: () => {
              refetch();
              setShowModal(false);
            },
          })
        }
        isBtnLoading={sendPartnershipRequest.isLoading}
        disabled={sendPartnershipRequest.isLoading}
      >
        {buttonText === "Request Partnership" ? (
          <div className="px-2 px-md-5">
            <p className="text-center w-md-50 m-auto">
              Are you sure you want to work with {bankOrgName}? Working with
              this developer means they will have the ability to sell your
              properties on your mortgage plans.{" "}
              <strong>
                Kindly read through the partnership terms and consideration
                areas below before you proceed to partner with the developer.
              </strong>
            </p>

            {getPartnershipTerms ? (
              <>
                <p className="mt-4">
                  Partnership Consideration Areas and Terms for{" "}
                  <strong>{bankOrgName}</strong>{" "}
                </p>
                {loadingPartnershipTerms ? (
                  <Spinner loadingText={"Loading Terms"} />
                ) : (
                  <div className="row">
                    <div className="col-md-3 col-lg-2 p-0">
                      <p className="m-0 fw-bold">PAYMENT PERIOD</p>
                      <p>
                        Not More Than {getPartnershipTerms?.paymentPeriod} Weeks
                      </p>
                    </div>

                    <div className="col-md-8 col-lg-6">
                      <p className="m-0 fw-bold">PROPERTY COMPLETION STATUS</p>
                      <p>
                        The completion status you want to partner with{" "}
                        {bankOrgName} for their properties on
                      </p>
                      <div className="d-lg-flex gap-4">
                        <div className="w-100 mb-3">
                          <PropertyCompletionStatus
                            title={"MOVE-IN READY"}
                            iconName={"moveInReady"}
                            checkInputs={prevFinished}
                          />
                        </div>
                        <div className="w-100 mb-3">
                          <PropertyCompletionStatus
                            title={"UNDER CONSTRUCTION"}
                            iconName={"underConstruction"}
                            checkInputs={prevConstruction}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-3">
                      <p className="m-0 fw-bold">
                        STANDARD PARTNERHSIP AGREEMENT
                      </p>
                      <div className="col-md-6">
                        <LicenseAndCertificationDoc
                          name={convertToTitleCase(
                            getPartnershipTerms?.agreement?.optionId
                          )}
                          document={getPartnershipTerms?.agreement?.document}
                          extension={getPartnershipTerms?.agreement?.extension?.toUpperCase()}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="mt-5">
                <CenteredText title="No Partnership Terms Available">
                  <p>
                    Currently, there are no partnership terms available for the
                    bank <strong>{bankOrgName}</strong>. Please check back later
                    or contact your bank representative for more information.
                  </p>
                </CenteredText>
              </div>
            )}
          </div>
        ) : buttonText === "Revoke Partnership" ? (
          <p className="text-center">
            {" "}
            Are you sure you want to Revoke Partnership with {bankOrgName}?{" "}
            <strong>This action can not be undone.</strong>
          </p>
        ) : (
          <p className="text-center">
            {" "}
            Are you sure you want to Accept Partnership with {
              bankOrgName
            } bank?{" "}
            <strong>
              We advice you agree only if you already have a relationship with
              this Bank.
            </strong>
          </p>
        )}
      </Modal>

      {/* ACCEPT PARTNERSHIP */}
      <Modal
        title="Accept Bank Partnership Request"
        show={showAcceptModal}
        cancelButtonLabel={"No"}
        confirmButtonLabel={"Yes"}
        closeModal={() => setShowAcceptModal(false)}
        confirmModal={() => {
          setPending(true);
          handlePartnerShipRequest(orgRequestId, "ACCEPTED");
        }}
        isBtnLoading={pending}
        disabled={pending}
      >
        <div className="text-center">
          Are you sure you want to accept the request to partner with this
          Developer
          {/* {slectedDeveloperName}.{" "} */}
          <strong>
            This will give you access to sell their properties through your
            plans.
          </strong>
        </div>
      </Modal>

      {/* DECLINE PARTNERSHIP */}
      <Modal
        title="Reject Bank Partnership Request"
        show={showDeclineModal}
        cancelButtonLabel={"No"}
        confirmButtonLabel={"Yes"}
        closeModal={() => setShowDeclineModal(false)}
        confirmModal={() => {
          setPending(true);
          handlePartnerShipRequest(orgRequestId, "REJECTED");
        }}
        isBtnLoading={pending}
        disabled={pending}
      >
        <div className="text-center">
          Are you sure you want to decline the request to partner with{" "}
          {slectedDeveloperName}?{" "}
          <strong>This will give them access to sell your properties.</strong>
        </div>
      </Modal>

      {/* UNPARTNER PARTNERSHIP */}
      <Modal
        title="Unpartner with this Bank"
        show={showUnpartnerModal}
        cancelButtonLabel={"No"}
        confirmButtonLabel={"Yes"}
        closeModal={() => setShowUnpartnerModal(false)}
        confirmModal={() => {
          setPending(true);
          handlePartnerShipRequest(orgRequestId, "UNPARTNER");
        }}
        isBtnLoading={pending}
        disabled={pending}
      >
        <div className="text-center">
          Are you sure you want to unpartner with {slectedDeveloperName}?{" "}
          <strong>
            This will mean you won’t be able to access any of their properties
            anymmore.
          </strong>
        </div>
      </Modal>

      {/* REVOKE PARTNERSHIP */}
      <Modal
        title="Revoke partnership with this Bank"
        show={showRevokeModal}
        cancelButtonLabel={"No"}
        confirmButtonLabel={"Yes"}
        closeModal={() => setShowRevokeModal(false)}
        confirmModal={() => {
          setPending(true);
          handlePartnerShipRequest(orgRequestId, "REVOKED");
        }}
        isBtnLoading={pending}
        disabled={pending}
      >
        <div className="text-center">
          Are you sure you want to revoke your partnership request with{" "}
          {slectedDeveloperName}?{" "}
          <strong>
            This will mean you won’t be able to access any of their properties
            anymore.
          </strong>
        </div>
      </Modal>
    </div>
  );
};

export default DeveloperDetails;
