import React, { useState, useEffect, useRef } from "react";
import useAuth from "../../hooks/useAuth";
import Button from "../../Components/Common/Button";
import { useNav } from "../../hooks/useNav";
import "./mortgageApplication.css";
import { tabs } from "./utils/constants";
import Tabs from "./Tabs";
import { useNavigate, useParams } from "react-router-dom";
import useGetApplication from "./hooks/useGetApplication";
import Dropdown from "../../Components/Common/Dropdown";

import AffordabilityReview from "./Documents/AffordabilityReview";

import Modal from "../../Components/Common/Modal";
import Spinner from "../../Components/Spinner";
import MultitextInput from "../../Components/Common/MultitextInput";
import {
  applicationStatus,
  applicationStatusIndex,
} from "./utils/applicationStatus";
import useAxiosBase from "../../hooks/useAxiosBase";
import { toast } from "react-toastify";
import { applicationNumber } from "../../utils/generalUtils";
import {
  isNullOrEmpty,
  convertToTitleCase,
  formattedPrice,
} from "../../utils/constants";
import { endApplicationLists } from "../../utils/mockData";
import useSendForReview from "./hooks/useSendForReview";
import useGetPlanDocuments from "../../hooks/useGetPlanDocuments";
import useUploadOfferLetter from "./hooks/useUploadOfferLetter";
import useEndApplication from "./hooks/useEndApplication";
import useUploadLegalSearchReport from "./hooks/useUploadLegalSearchReport";

import DetailSubCard from "../../Components/Common/DetailSubCard";
import DetailCard from "../../Components/Common/DetailCard";
import IconGrid from "../../Components/Common/IconGrid";
import PropertyAddress from "../../Components/Common/PropertyAddress";
import Icons from "../../Components/Common/Icons";
import "./applications.css";
import CheckInput from "../../Components/Common/CheckInput";
import { colors } from "../../utils/constants";
import UploadDocument from "../../Components/Common/UploadDocument";
import { getBase64Image, fileExtension } from "../../utils/imageUtils";
import VerifyingLoader from "../../Components/Common/VerifyingLoader";
import LicenseAndCertificationDoc from "../../Components/Common/License&CertificationDoc";

const ApplicationDetails = () => {
  useNav({ mainTitle: "", hasBackButton: true });
  const params = useParams();
  const { authUser } = useAuth();

  const userId = authUser?.user?.id;
  const applicationId = params.id;
  const applicationOwner = params.owner;
  const {
    data: application,
    isLoading,
    refetch,
    isRefetching,
  } = useGetApplication({ applicationOwner, applicationId });

  const { uploadOfferLetter } = useUploadOfferLetter(applicationId);
  const { endApplication } = useEndApplication(applicationId);
  const { uploadLegalSearchReport } = useUploadLegalSearchReport(applicationId);

  const [steps, setSteps] = useState(0);
  const [recommendationComment, setRecommendationComment] = useState("");
  const [recommendationModal, setRecommendationModal] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showOfferLetterModal, setShowOfferLetterModal] = useState(false);
  const [showLegalSearchModal, setShowLegalSearchModal] = useState(false);
  const [showEndApplicationModal, setShowEndApplicationModal] = useState(false);
  const [showUploadTitleDocModal, setShowUploadTitleDocModal] = useState(false);
  const sendForReview = useSendForReview();
  const LegalSearchfileInputRef = useRef(null);

  const [bathrooms, setBathrooms] = useState();
  const [bedrooms, setBedrooms] = useState();
  const [enSuite, setEnSuite] = useState();
  const [squareMeters, setSquareMeters] = useState();
  const [furnished, setFurnished] = useState();
  const [gatedCompound, setGatedCompound] = useState();
  const [guardedCompound, setGuardedCompound] = useState();
  const [features, setFetures] = useState([]);
  const [offerLetterRevision, setOfferLetterRevision] = useState();
  const [endApplicationReason, setEndApplicationReason] = useState();
  const [endApplicationComment, setEndApplicationComment] = useState("");
  const [confirmEnddApplication, setConfirmEnddApplication] = useState();

  const docRef = useRef();
  const offerDocRef = useRef();
  const [files, setFiles] = useState([]);
  const [titleDoc, setTitleDoc] = useState([]);
  const [legalSearchReportDoc, setLegalSearchReportDoc] = useState(null);

  // UPLOAD OFFER LETTER
  const handleFileChange = async (event, document) => {
    const file = event.target.files[0];

    // Check if the uploaded file is a PDF
    if (!file.type.includes("pdf")) {
      toast.error("Please upload a PDF file");
      return;
    }

    URL.revokeObjectURL(file);
    const base64 = await getBase64Image(file);
    let newImage = {
      base64: base64,
      optionId: "MORTGAGE_TERMS_DOCUMENT",
      extension: fileExtension(file),
      name: file.name,
      description: "Loan Term Uploaded",
    };

    setFiles([newImage, ...files]);
  };

  // UPLOAD LEGAL SEARCH REPORT
  const handleLegalDocFileChange = async (event) => {
    const file = event.target.files[0];

    // Check if the uploaded file is a PDF
    if (!file.type.includes("pdf")) {
      toast.error("Please upload a PDF file");
      return;
    }

    URL.revokeObjectURL(file);
    const base64 = await getBase64Image(file);
    let newImage = {
      base64: base64,
      optionId: "LEGAL_SEARCH",
      extension: fileExtension(file),
      name: file.name,
      description: "Legal Search Report Uploaded",
    };

    setLegalSearchReportDoc(newImage);
    setShowLegalSearchModal(true);
  };

  // UPLOAD TITLE DOCUMENT
  const handleTitleDocFileChange = async (event) => {
    const file = event.target.files[0];

    // Check if the uploaded file is a PDF
    if (!file.type.includes("pdf")) {
      toast.error("Please upload a PDF file");
      return;
    }

    URL.revokeObjectURL(file);
    const base64 = await getBase64Image(file);
    let newImage = {
      base64: base64,
      optionId: "TITLE_DOCUMENT",
      extension: fileExtension(file),
      name: file.name,
      description: "Title Document Uploaded",
    };

    setTitleDoc([newImage, ...files]);
  };

  const handleSendToReviewer = (appId) => {
    if (recommendationComment) {
      const data = {
        appId: appId,
        Comment: recommendationComment,
      };
      sendForReview.mutate(data, {
        onSuccess: () => {
          refetch();
          setRecommendationModal(false);
        },
      });
      return;
    }
    toast.error("Please leave a comment.");
  };

  const { data: requiredDocuments } = useGetPlanDocuments(
    application?.mortgagePlanId
  );

  const handleUploadOfferLetter = () => {
    if (offerLetterRevision && offerLetterRevision?.revisions?.length < 1) {
      toast.error(
        "Before reuploading offer letter, The customer needs to review and revise it."
      );

      setShowOfferLetterModal(false);
      return;
    }
    uploadOfferLetter.mutate(
      {
        ...files[0],
        revisionId: offerLetterRevision?.revisions?.[0]?.id ?? null,
      },
      {
        onSuccess: (data) => {
          if (data?.value?.statusCode === 200) {
            toast.success("Uploaded Successfully!");
            refetch();
            setShowOfferLetterModal(false);
            setFiles([]);
            resetFile();
          }
        },
      }
    );
  };

  const handleUploadTtileDoc = () => {};

  const handleEndApplication = () => {};

  const handleSecondaryBtnClick = (tab) => {
    if (tab.buttonOption?.secondary === "End Application") {
      setShowEndApplicationModal(true);
    }
  };

  const handlePrimaryBtnClick = (tab) => {
    if (tab.buttonOption?.primary === "Upload Legal Search Report") {
      if (LegalSearchfileInputRef.current) {
        LegalSearchfileInputRef.current.click();
      }
      return;
    }

    if (tab.buttonOption?.primary === "Send for Review") {
      setRecommendationModal(true);
    }

    if (
      tab.buttonOption?.primary === "Send Loan Terms" ||
      tab.buttonOption?.primary === "Update Loan Terms"
    ) {
      setShowSubmitModal(true);
      return;
    }

    if (
      tab.buttonOption?.primary === "Upload Loan Terms Offer" ||
      tab.buttonOption?.primary === "Reupload Offer Letter"
    ) {
      setShowOfferLetterModal(true);
      return;
    }

    if (steps >= tabs(requiredDocuments, application, steps)?.length - 1) {
      setSteps(0);
    } else {
      setSteps(steps + 1);
    }
  };

  useEffect(() => {
    tabs(requiredDocuments, application, steps);
    if (application) {
      setSteps(applicationStatusIndex[application.status]);
    }
  }, [application, steps, requiredDocuments]);

  useEffect(() => {
    // Set values for Specifications section
    const specificationsValues =
      application?.house?.features?.reduce((accumulator, feature) => {
        switch (feature.icon) {
          case "bathrooms":
            accumulator.bathrooms = feature.name;
            break;
          case "bedrooms":
            accumulator.bedrooms = feature.name;
            break;
          case "furnished_unfurnished":
            accumulator.furnished = feature.name;
            break;
          case "gated_compound_gated_community":
            accumulator.gated_compound = feature.name;
            break;
          case "sqm":
            accumulator.square_meters = feature.name.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            );
            break;
          case "ensuite":
            accumulator.ensuite = feature.name;
            break;
          case "guarded":
            accumulator.guarded_compound = feature.name;
            break;
          // Add more cases for other specifications as needed
          default:
            break;
        }
        return accumulator;
      }, {}) || {};

    // Set the form field values for Specifications
    setBathrooms(specificationsValues.bathrooms);
    setBedrooms(specificationsValues.bedrooms);
    setFurnished(specificationsValues.furnished);
    setEnSuite(specificationsValues.ensuite);
    setSquareMeters(specificationsValues.square_meters);
    setGatedCompound(specificationsValues.gated_compound);
    setGuardedCompound(specificationsValues.guarded_compound);

    if (application?.documents) {
      const offerDoc = application?.documents?.find(
        (doc) => doc.document.optionId === "MORTGAGE_TERMS_DOCUMENT"
      );

      setOfferLetterRevision(offerDoc);
    }
  }, [application]);

  useEffect(() => {
    refetch();
  }, [application?.status]);

  if (isLoading)
    return (
      <div style={{ height: "60vh" }}>
        {" "}
        <Spinner loadingText={"Loading Application Details..."} />{" "}
      </div>
    );

  const resetFile = () => {
    docRef.current.value = null;
  };

  return (
    <>
      {!isLoading && isNullOrEmpty(application) ? (
        <div className="container-fluid application_details mt-5">
          <div
            style={{
              borderStyle: "double",
              margin: "0 auto",
              display: "flex",
            }}
            className="row align-items-center justify-items-center pb-3 alert alert-danger blockquote me-5 mt-2 mb-4 callout callout-success border-5"
          >
            <span
              className="h3 fw-bold text-center"
              style={{ fontSize: "30px" }}
            >
              APPLICATION NOT FOUND (404)
            </span>
            <span className="text-center">
              <h4 className="h6 text-uppercase text-center">
                The current application could not be found.
              </h4>
              <p>
                Please contact the administrator{" "}
                <a href="mailto:info@giddaa.com">info@giddaa.com</a>
              </p>
            </span>
          </div>
        </div>
      ) : (
        <div className="px-2 application_details mb-5">
          {isRefetching && (
            <VerifyingLoader
              title={"Fetching application"}
              position={"absolute"}
            />
          )}
          <div className="fixed-tab bg-white">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h3 className="h3 text-uppercase">
                  Application - {applicationNumber(application?.id)}
                </h3>
                <div>
                  <p
                    style={{
                      border: "1px solid #335F32",
                      backgroundColor: "#DFFFDE",
                      color: "#335F32",
                      fontSize: "13px",
                      borderRadius: "20px",
                      display: "inline-block",
                      padding: "3px 9px",
                      display: "inline-block",
                    }}
                  >
                    {application?.status === "LOAN_TERM_ACCEPTED"
                      ? "Term Accepted"
                      : application?.status === "LOAN_TERM_REJECTED"
                      ? "Term Rejected"
                      : convertToTitleCase(application?.status)}
                  </p>
                </div>
              </div>

              <div>
                {tabs(requiredDocuments, application, steps)?.tabs?.map(
                  (tab, index) => {
                    if (application?.isActive)
                      return (
                        <>
                          {userId === application?.handler?.handlerId && (
                            <div className="d-flex gap-2">
                              <div>
                                {tab.buttonOption?.secondary && (
                                  <Button
                                    key={tab.name}
                                    btnText={tab.buttonOption?.secondary}
                                    onBtnClick={() =>
                                      handleSecondaryBtnClick(tab)
                                    }
                                    padding={"10px 9px"}
                                    btntextcolor={colors.primary}
                                    backgroundcolor={colors.white}
                                    iconcolorHover={colors.hover}
                                    colorHover={colors.hover}
                                    borderHover={colors.hover}
                                    backgroundHover={colors.white}
                                  />
                                )}
                              </div>

                              <div>
                                {tab.buttonOption?.primary && (
                                  <Button
                                    key={tab.name}
                                    btnText={tab.buttonOption?.primary}
                                    onBtnClick={() =>
                                      handlePrimaryBtnClick(tab)
                                    }
                                    padding={"11px 9px"}
                                    disabled={
                                      tabs(
                                        requiredDocuments,
                                        application,
                                        steps
                                        // setSteps
                                      )?.tabs?.find((x) => x.id.includes(steps))
                                        ?.validated ||
                                      application.status === "UNDER_REVIEW"
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      );
                  }
                )}

                {application?.isActive &&
                  userId !== application?.handler?.handlerId && (
                    <div className="d-flex gap-2">
                      <div>
                        <Button
                          btnText={"Upload Title Doc"}
                          onBtnClick={() => setShowUploadTitleDocModal(true)}
                          padding={"10px 9px"}
                          btntextcolor={colors.primary}
                          backgroundcolor={colors.white}
                          iconcolorHover={colors.hover}
                          colorHover={colors.hover}
                          borderHover={colors.hover}
                          backgroundHover={colors.white}
                        />
                      </div>

                      <div>
                        <Button
                          btnText={"Upload Offer Doc"}
                          onBtnClick={() => setShowOfferLetterModal(true)}
                          padding={"11px 9px"}
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>

            <Tabs
              tabs={tabs(requiredDocuments, application, steps, setSteps)}
            />

            {/* {application?.transactions?.isPaid ? (
              <p className="yellowPendingPill w-100 p-3 text-center my-3">
                The customer is currently seeking to conduct due diligence for
                the purchase of{" "}
                <strong>{application?.house?.name ?? "this property"}</strong>{" "}
                located at <strong>{application?.house?.address}</strong>. You
                will be kept informed of the customer’s decision from the due
                diligence process.
              </p>
            ) : (
              <>
                {application?.status === "UPLOADED_OFFER" && (
                  <p className="yellowPendingPill w-100 p-3 text-center my-3">
                    The offer letter has been uploaded, and the customer has
                    been alerted. Please wait till they approve or reject the
                    offer letter before you reupload it.
                  </p>
                )}
                {application?.status === "PENDING_FEE_PAYMENT" && (
                  <p className="yellowPendingPill w-100 p-3 text-center my-3">
                    The offer letter has been uploaded, and the customer has
                    been alerted. Please wait till they approve or reject the
                    offer letter before you reupload it.
                  </p>
                )}
              </>
            )} */}

            <div className="row mt-3">
              <div className="col-md-6 col-xl-4">
                <DetailCard
                  title={"Property Details"}
                  subtitle={
                    application?.payableHousePrice ===
                      application?.mortgagePlan?.maxLoanAmount ||
                    !application?.payableHousePrice ? (
                      <p
                        className="m-0 mb-2"
                        style={{ color: colors.darkGreen }}
                      >
                        ₦
                        {formattedPrice(
                          application?.mortgagePlan?.maxLoanAmount
                        )}
                      </p>
                    ) : (
                      <>
                        <p
                          className="m-0 mb-2"
                          style={{
                            fontSize: "0.6rem",
                            color: colors.danger,
                            backgroundColor: colors.dangerLight,
                            border: `1px solid ${colors.danger}`,
                            borderRadius: "100px",
                            display: "inline-block",
                            padding: "0.1rem 0.3rem",
                          }}
                        >
                          PRICE CHANGE
                        </p>
                        <div className="d-flex align-items-end gap-3 m-0 mb-2">
                          <div>
                            <p className="m-0" style={{ fontSize: "0.6rem" }}>
                              INITIAL PRICE
                            </p>
                            <p
                              className="m-0"
                              style={{ color: colors.darkGreen }}
                            >
                              N
                              {formattedPrice(
                                application?.mortgagePlan?.maxLoanAmount
                              )}
                            </p>
                          </div>
                          <Icons
                            iconName={"rightArrow"}
                            className="priceChangeIcon"
                          />
                          <div>
                            <p className="m-0" style={{ fontSize: "0.6rem" }}>
                              {" "}
                              NEGOTIATED PRICE
                            </p>
                            <p
                              className="m-0"
                              style={{ color: colors.darkGreen }}
                            >
                              N{formattedPrice(application?.payableHousePrice)}
                            </p>
                          </div>
                        </div>
                      </>
                    )
                  }
                  isBold={true}
                  className={"vertical-divider"}
                >
                  <p className="m-0 fw-bold mb-2">
                    {application?.house?.name ?? ""}
                  </p>
                  <div className="d-flex flex-wrap column-gap-3">
                    <p className="d-flex align-items-center gap-1 p-0 mb-3">
                      <p className="p-0 m-0 prop_icon">
                        <Icons iconName={"bedrooms"} />{" "}
                      </p>
                      <p className="p-0 m-0">
                        {application?.house?.bedrooms} Beds
                      </p>
                    </p>
                    <p className="d-flex align-items-center gap-1 p-0 mb-3">
                      <p className="p-0 m-0 prop_icon">
                        <Icons iconName={"bathrooms"} />
                      </p>
                      <p className="p-0 m-0">
                        {application?.house?.bathrooms} Baths
                      </p>
                    </p>
                    {squareMeters && (
                      <p className="d-flex align-items-center gap-1 p-0 mb-3">
                        <p className="p-0 m-0 prop_icon">
                          <Icons iconName={"sqm"} />
                        </p>
                        <p className="p-0 m-0">{squareMeters} Sqm</p>
                      </p>
                    )}
                    <p className="d-flex align-items-end gap-1 p-0 mb-3">
                      <p className="p-0 m-0 prop_icon_location">
                        <Icons iconName={"terrace"} />
                      </p>
                      <p className="p-0 m-0">
                        {convertToTitleCase(application?.house.type)}
                      </p>
                    </p>
                  </div>
                  {/* <div className="d-flex flex-wrap column-gap-3">
                    <p className="d-flex align-items-center gap-1 p-0 mb-3">
                      <p className="p-0 m-0 prop_icon">
                        {" "}
                        <Icons iconName={"furnished_unfurnished"} />
                      </p>
                      <p className="p-0 m-0">
                        {" "}
                        {furnished === "yes" ? "Furnished" : "Unfurnished"}{" "}
                      </p>
                    </p>
                    <p className="d-flex align-items-center gap-1 p-0 m-0">
                      <p className="p-0 m-0"> <Icons iconName={"bedrooms"} /></p>
                      <p className="p-0 m-0"> {guardedCompound} </p>
                    </p>
                    <p className="d-flex align-items-center gap-1 p-0 mb-3">
                      <p className="p-0 m-0 prop_icon">
                        {" "}
                        <Icons iconName={"gated_compound_gated_community"} />
                      </p>
                      <p className="p-0 m-0">
                        {" "}
                        {gatedCompound === "yes"
                          ? "Gated Compound"
                          : "No Gate"}{" "}
                      </p>
                    </p>
                  </div> */}
                  <div className="d-flex align-items-center flex-wrap column-gap-1">
                    <p className="p-0 m-0 prop_icon_location">
                      <Icons iconName={"mapIcon"} />
                    </p>
                    <p className="p-0 m-0"> {application?.house.address} </p>
                  </div>
                </DetailCard>
              </div>

              <div className="col-md-6 col-xl-4">
                <DetailCard
                  title={"Plan Details"}
                  className={"vertical-divider"}
                >
                  <DetailSubCard
                    leftTitle={"Plan Type"}
                    leftDescription={convertToTitleCase(
                      application?.mortgagePlan?.type
                    )}
                    rightTitle={"Plan Name"}
                    rightDescription={application?.mortgagePlan?.name}
                  />
                  <DetailSubCard
                    className="pt-2"
                    leftTitle={"Downpayment"}
                    leftDescription={`${
                      application?.payableEquity
                    }% (₦ ${formattedPrice(
                      application?.payableHousePrice *
                        (application?.payableEquity / 100)
                    )})`}
                    rightTitle={"Payment Period"}
                    rightDescription={`${application?.payableLoanTenor} ${
                      application?.payableLoanTenor > 1 ? "Months" : "Month"
                    }`}
                  />
                  <DetailSubCard
                    className="pt-2"
                    leftTitle={"Interest Rate"}
                    leftDescription={`${application?.mortgagePlan?.interestRate}%`}
                    rightTitle={"Max Age"}
                    rightDescription={`${application?.mortgagePlan?.maxAge} Years`}
                  />
                </DetailCard>
              </div>

              <div className="col-md-6 col-xl-4">
                <DetailCard title={"Customer Details"}>
                  <DetailSubCard
                    leftTitle={"Name"}
                    leftDescription={application?.customer?.name}
                    rightTitle={"Email"}
                    rightDescription={application?.customer?.email}
                  />
                  <DetailSubCard
                    className="pt-2"
                    leftTitle={"Phone No"}
                    leftDescription={application?.customer.phoneNumber}
                    rightTitle={"Monthly Affordability"}
                    rightDescription={`₦ ${formattedPrice(
                      application?.customer?.affordability?.bankPlan
                    )}`}
                  />
                  <DetailSubCard
                    className="pt-2"
                    leftTitle={
                      application?.withInNigeria
                        ? "NIN Verification"
                        : "Int’l Passport"
                    }
                    leftDescription={
                      application?.withInNigeria ? (
                        <div>
                          {application?.verifications?.map((x) => {
                            if (x.type === "NIN") {
                              return <p className="m-0">{x.number}</p>;
                            }
                            return null;
                          })}
                          <div className="m-0">
                            <CheckInput
                              checked={true}
                              checkTick={true}
                              width={"15px"}
                              height={"15px"}
                              bcolor={colors.darkGray}
                              labelColor={colors.darkGreen}
                              borderradius={"50%"}
                              fontSize={"9px"}
                              tickWidth={"0.3rem"}
                              tickHeight={"0.5rem"}
                              bordercolor={colors.grey}
                              tickBorderWidth={"0 2px 2px 0"}
                              labelMT={"0.3rem"}
                              onChange={(e) => {}}
                              label={"NATIONAL IDENTITY VALIDATED"}
                            />
                          </div>
                        </div>
                      ) : (
                        <div>
                          {application?.verifications?.map((x) => {
                            if (x.type === "PASSPORT") {
                              return <p className="m-0">{x.number}</p>;
                            }
                            return null;
                          })}
                          <div className="d-flex align-items-center m-0">
                            <Icons
                              iconName={"viewings"}
                              className={"viewDoc"}
                            />
                            <p
                              className="m-0 anchor"
                              style={{
                                fontSize: "0.6rem",
                                fontWeight: "bold",
                                color: "#335f32",
                              }}
                            >
                              VIEW DOCUMENT
                            </p>
                          </div>
                        </div>
                      )
                    }
                    // rightTitle={application?.verifications?.map((x) => {
                    //   if (x.type === "NHF") {
                    //     return <>{x.type}</>;
                    //   }
                    //   return null;
                    // })}
                    // rightDescription={application?.verifications?.map((x) => {
                    //   if (x.type === "NHF") {
                    //     return <p>{x.number}</p>;
                    //   }
                    //   return null;
                    // })}
                  />
                </DetailCard>
              </div>

              <div>
                <DetailCard title={"Other Details"}>
                  <div className="d-flex gap-3">
                    <div style={{ width: "100%", maxWidth: "25rem" }}>
                      <DetailSubCard
                        leftTitle={"Handling Staff"}
                        leftDescription={
                          application?.handler?.firstName
                            ? `${application?.handler?.firstName} ${application?.handler?.lastName}`
                            : "Unassigned"
                        }
                        rightTitle={"Application Date"}
                        rightDescription={new Date(
                          application?.dateCreated
                        ).toDateString()}
                      />

                      <DetailSubCard
                        className="pt-2"
                        leftTitle={"Region"}
                        leftDescription={
                          application?.withInNigeria
                            ? "Within Nigeria"
                            : "Outside Nigeria"
                        }
                        rightTitle={"Application Approval Date"}
                        rightDescription={new Date(
                          application?.dateCreated
                        ).toDateString()}
                      />
                    </div>
                    {application?.reviewOfficer?.name && (
                      <div style={{ width: "100%", maxWidth: "25rem" }}>
                        <DetailSubCard
                          className="pt-2"
                          leftTitle={"Reviewed By"}
                          leftDescription={application?.reviewOfficer?.name}
                        />
                      </div>
                    )}
                  </div>
                </DetailCard>
              </div>
            </div>
          </div>

          <div className="row w-100 p-2 pe-0 scrollable-tab h-100">
            {
              tabs(
                requiredDocuments,
                application,
                steps,
                setSteps,
                showSubmitModal,
                setShowSubmitModal,
                refetch,
                isRefetching
              )?.tabs?.find((x) => x.id.includes(steps))?.component
            }
          </div>

          <input
            type="file"
            className="d-none"
            ref={LegalSearchfileInputRef}
            accept="pdf/*"
            onChange={handleLegalDocFileChange}
            multiple
          />

          <Modal
            title={"Recommendation"}
            subTitle={
              " Leave a recommendation on the course of action and critical items the reviewer should consider when they are reviewing this application"
            }
            show={recommendationModal}
            closeModal={() => {
              setRecommendationComment("");
              setRecommendationModal(false);
            }}
            cancelButtonLabel={"Cancel"}
            confirmButtonLabel={"Review"}
            titleFontSize={"38px"}
            width={"100%"}
            height={"100%"}
            crossLine={false}
            closeModalIcon
            btnHeight={45}
            confirmModal={() => handleSendToReviewer(applicationId)}
            isBtnLoading={sendForReview.isLoading}
            disabled={
              sendForReview.isLoading || recommendationComment.length <= 10
            }
          >
            <div className="container">
              <div className="col-md-9 mx-auto">
                <MultitextInput
                  placeholder={
                    "What course of action are you recommending to the reviewer?"
                  }
                  row={"7"}
                  value={recommendationComment}
                  onChange={(e) => setRecommendationComment(e.target.value)}
                />
              </div>
            </div>
          </Modal>

          <Modal
            title={"Upload Loan Terms"}
            show={showOfferLetterModal}
            closeModal={() => {
              setFiles([]);
              setShowOfferLetterModal(false);
            }}
            cancelButtonLabel={"Cancel"}
            confirmButtonLabel={"Upload"}
            confirmModal={handleUploadOfferLetter}
            titleFontSize={"38px"}
            width={"100%"}
            height={"100%"}
            crossLine={false}
            closeModalIcon
            btnHeight={45}
            isBtnLoading={uploadOfferLetter.isLoading}
            disabled={!files?.length || uploadOfferLetter.isLoading}
          >
            <div className="col-md-5 my-5 mx-auto">
              <UploadDocument
                docRef={docRef}
                variant={"upload"}
                title={""}
                docObject={files}
                type="pdf/*"
                handleChange={(event) => handleFileChange(event, files)}
              />
            </div>
          </Modal>

          <Modal
            title={"Upload Title Document"}
            show={showUploadTitleDocModal}
            closeModal={() => {
              setTitleDoc(null);
              setShowUploadTitleDocModal(false);
            }}
            cancelButtonLabel={"Cancel"}
            confirmButtonLabel={"Upload"}
            confirmModal={handleUploadTtileDoc}
            titleFontSize={"38px"}
            width={"100%"}
            height={"100%"}
            crossLine={false}
            closeModalIcon
            btnHeight={45}
            // isBtnLoading={uploadOfferLetter.isLoading}
            disabled={!titleDoc?.length || uploadOfferLetter.isLoading}
          >
            <div className="col-md-5 my-5 mx-auto">
              <UploadDocument
                docRef={offerDocRef}
                variant={"upload"}
                title={""}
                docObject={titleDoc}
                type="pdf/*"
                handleChange={(event) => handleTitleDocFileChange(event)}
              />
            </div>
          </Modal>

          <Modal
            title={"Upload Legal Search Report"}
            show={showLegalSearchModal}
            closeModal={() => {
              setLegalSearchReportDoc(null);
              setShowLegalSearchModal(false);
            }}
            cancelButtonLabel={"Cancel"}
            confirmButtonLabel={"Upload"}
            confirmModal={() => {
              uploadLegalSearchReport.mutate(legalSearchReportDoc, {
                onSuccess: () => {
                  refetch();
                  setLegalSearchReportDoc(null);
                  setShowLegalSearchModal(false);
                },
              });
            }}
            titleFontSize={"38px"}
            width={"100%"}
            height={"100%"}
            crossLine={false}
            closeModalIcon
            btnHeight={45}
            isBtnLoading={uploadLegalSearchReport.isLoading}
            disabled={uploadLegalSearchReport.isLoading}
          >
            <div>
              <p className="text-center mb-5 w-50 mx-auto">
                Are you sure you want to submit the legal search report to{" "}
                <strong>{application?.customer?.name}?</strong> This means you
                have carried out the legal search and deemed it to be
                satisfactory to underwrite a mortgage for{" "}
                <strong>{application?.house?.name ?? "this property"}</strong>{" "}
                located at <strong>{application?.house?.address}</strong> ,
                developed by <strong>{application?.organization?.name}</strong>{" "}
                and you want <strong>{application?.customer?.name}</strong> to
                proceed with their application.
              </p>

              <p
                className="fw-bold text-center mb-2 "
                style={{ fontSize: "1rem" }}
              >
                Legal Search Report or Document
              </p>
              <div className="mx-auto" style={{ width: "12rem" }}>
                <LicenseAndCertificationDoc
                  name={legalSearchReportDoc?.name}
                  extension={"PDF"}
                  document={""}
                  required={""}
                  deleteDoc={""}
                  handleDelete={""}
                />
              </div>
              <p className="text-center my-2">The document you uploaded</p>
            </div>
          </Modal>

          <Modal
            title={
              confirmEnddApplication
                ? "End Application Confirmation."
                : "End Application"
            }
            show={showEndApplicationModal}
            closeModal={() => {
              setEndApplicationComment("");
              setConfirmEnddApplication(false);
              setShowEndApplicationModal(false);
            }}
            cancelButtonLabel={"Cancel"}
            confirmButtonLabel={
              confirmEnddApplication ? "Yes" : "End Application"
            }
            confirmModal={() => {
              if (!confirmEnddApplication) {
                return setConfirmEnddApplication(true);
              }

              endApplication.mutate(
                {
                  reason: endApplicationReason,
                  comment: endApplicationComment,
                },
                {
                  onSuccess: () => {
                    refetch();
                    setEndApplicationComment("");
                    setConfirmEnddApplication(false);
                    setShowEndApplicationModal(false);
                  },
                }
              );
            }}
            titleFontSize={confirmEnddApplication ? "24px" : "38px"}
            width={confirmEnddApplication ? "500px" : "100%"}
            height={confirmEnddApplication ? "" : "100%"}
            crossLine={confirmEnddApplication ? true : false}
            closeModalIcon={confirmEnddApplication ? false : true}
            btnHeight={45}
            isBtnLoading={endApplication.isLoading}
            disabled={
              !endApplicationReason ||
              endApplicationComment?.length < 10 ||
              endApplication.isLoading
            }
          >
            <>
              {confirmEnddApplication ? (
                <p className="text-center w-md-50 mx-auto">
                  Are you sure you want to end{" "}
                  <strong>{application?.customer?.name}</strong> Application due
                  to an unsatisfactory outcome?{" "}
                  <span className="text-danger">
                    This action cannot be undone.
                  </span>
                </p>
              ) : (
                <div className="col-md-5 mx-auto">
                  <p className="text-center">
                    To end this application, select a reason from the dropdown
                    menu and provide a detailed explanation. Your description
                    should clearly outline the reasons for not proceeding with
                    the application.
                  </p>
                  <div className="mt-5">
                    <Dropdown
                      labelName={"Reason for Ending The Application"}
                      required
                      placeholder={"Select"}
                      options={endApplicationLists}
                      onChange={(e) => setEndApplicationReason(e.target.value)}
                    />
                  </div>

                  <div className=" mt-2">
                    <MultitextInput
                      label={"Description"}
                      required
                      row={"7"}
                      placeholder={
                        "Provide more information on why you will not be proceeding with their application"
                      }
                      onChange={(e) => setEndApplicationComment(e.target.value)}
                    />
                  </div>
                </div>
              )}
            </>
          </Modal>
        </div>
      )}
    </>
  );
};

export default ApplicationDetails;
