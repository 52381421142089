import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import Icons from "../Common/Icons";
import Button from "../Common/Button";
import { colors } from "../../utils/constants";
import { saveAs } from "file-saver";
import { FaRegCircleQuestion } from "react-icons/fa6";
import CheckInput from "../Common/CheckInput";
import "./documentTable.css";

const DocumentTable = ({
  application,
  documentName,
  handleOnClick,
  hasButton,
  icon = null,
  handleRevise,
  handleViewFeedback,
  handleValidate,
  handleInValidate,
  validateIsLoading,
  isValidated,
  url,
  userName,
  document,
  docValidation,
}) => {
  const { authUser } = useAuth();

  const userId = authUser?.user?.id;

  const [hasRevision, setHasRevision] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [hasBeenValidated, setHasBeenValidated] = useState(isValidated);

  useEffect(() => {
    if (!isValidated) {
      setHasBeenValidated(false);
    } else {
      setHasBeenValidated(true);
    }

    if (document?.revisions?.some((x) => x?.isActive)) {
      setHasRevision(true);
    }
    if (
      docValidation?.validated &&
      !document?.isValidated &&
      docValidation?.docId === document?.applicationDocumentId
    ) {
      setHasBeenValidated(true);
    }
  }, [document, isValidated, docValidation, application]);

  return (
    <div className="d-flex flex-row justify-content-between table-responsive w-100 p-0 m-0">
      <table className="border-top border-bottom table w-100">
        <tbody className="">
          <tr className="d-flex justify-content-between align-items-center gap-4">
            <td>
              {documentName === "Offer Letter" && hasRevision && (
                <span
                  className="badge btn-sm py-1 mb-1"
                  style={{
                    fontSize: "0.5rem",
                    backgroundColor: colors.dangerLight,
                    color: colors.danger,
                    borderRadius: "20px",
                    display: "inline-block",
                    padding: "3px 5px",
                    display: "inline-block",
                  }}
                >
                  ACTION REQUIRED
                </span>
              )}
              {documentName === "Offer Letter" &&
                application?.status === "OFFER_LETTER_UPLOADED" && (
                  <span
                    className="badge btn-sm py-1 mb-1"
                    style={{
                      fontSize: "0.5rem",
                      backgroundColor: colors.dangerLight,
                      color: colors.danger,
                      borderRadius: "20px",
                      display: "inline-block",
                      padding: "3px 5px",
                      display: "inline-block",
                    }}
                  >
                    Pending Customer's Acceptance
                  </span>
                )}
              <td>
                {icon ? (
                  <Icons iconName={icon} />
                ) : (
                  <Icons iconName={"documents"} />
                )}
              </td>
              <td>
                <p className="me-2 m-0 pt-2 text-center">{documentName}</p>
              </td>
              {hasButton && (
                <td className="doc-download pt-2 ps-2">
                  <span
                    role="button"
                    onClick={() => saveAs(url, `${userName}`)}
                  >
                    <Icons
                      iconName={"documentDownload"}
                      className="download_doc_icon"
                    />
                  </span>
                </td>
              )}
              {[
                // "Employment Letter",
                "Bank Statement",
                "Credit Check",
                "Peer Reviews",
              ].includes(documentName) && (
                <td
                  className="doc-view-report"
                  role="button"
                  onClick={handleOnClick}
                >
                  <span
                    className="anchor me-2"
                    style={{ fontSize: "0.8rem", color: colors.darkGreen }}
                  >
                    View Report
                  </span>
                </td>
              )}
            </td>

            <td>
              {userId === application?.handler?.handlerId && hasButton && (
                <td className="doc-verify-analyze rig">
                  {hasRevision ? (
                    documentName === "Offer Letter" ? (
                      <div className="d-flex align-items-center">
                        <Button
                          btnText={"View Feedback"}
                          borderradius={50}
                          width={"95px"}
                          onBtnClick={handleViewFeedback}
                          //  disabled={hasRevision}
                          className="mt-1"
                          padding={"5px 0"}
                        />
                      </div>
                    ) : (
                      <div className="d-flex gap-2 mt-2">
                        <div className="toolTip">
                          {showDescription && (
                            <div className="container description animated">
                              <p className="description-text">
                                This document was sent for revision.
                              </p>
                            </div>
                          )}
                          <FaRegCircleQuestion
                            size="1.2em"
                            onMouseEnter={() => setShowDescription(true)}
                            onMouseLeave={() => setShowDescription(false)}
                          />
                        </div>

                        <div className="">
                          <span
                            className="badge py-2"
                            style={{
                              backgroundColor: colors.dangerLight,
                              color: colors.danger,
                              fontSize: "13px",
                              borderRadius: "20px",
                              display: "inline-block",
                              padding: "3px 9px",
                              display: "inline-block",
                              marginRight: "1rem",
                            }}
                          >
                            Sent For Revision
                          </span>
                        </div>
                      </div>
                    )
                  ) : application?.status !== "PENDING" ? null : hasBeenValidated ? (
                    <Button
                      btnText={"Invalidate"}
                      borderradius={50}
                      className="mt-1"
                      padding={"5px 0"}
                      spinnerWidth={1}
                      spinnerHeight={1}
                      backgroundcolor={"white"}
                      backgroundHover={"white"}
                      bordercolor={colors.darkGreen}
                      borderHover={colors.hover}
                      btntextcolor={colors.darkGreen}
                      colorHover={colors.hover}
                      width={"95px"}
                      onBtnClick={handleInValidate}
                    />
                  ) : (
                    <Button
                      btnText={hasRevision ? "Sent For Revision" : "Revise"}
                      borderradius={50}
                      onBtnClick={handleRevise}
                      disabled={isValidated || hasRevision}
                      className="mt-1"
                      padding={"5px 0"}
                      width={"95px"}
                    />
                  )}
                </td>
              )}

              {hasButton && (
                <>
                  {hasBeenValidated ? (
                    <td className="doc-verify-analyze validateCheck rig pt-2">
                      <CheckInput
                        checked
                        width={"15px"}
                        height={"15px"}
                        bcolor={colors.darkGray}
                        labelColor={colors.darkGreen}
                        borderradius={"50%"}
                        fontSize={"9px"}
                        tickWidth={"0.3rem"}
                        tickHeight={"0.5rem"}
                        bordercolor={colors.grey}
                        tickBorderWidth={"0 2px 2px 0"}
                        checkTick={true}
                        label={"DOCUMENT VALIDATED"}
                        labelMT={"0.4rem"}
                        onChange={(e) => handleInValidate(e)}
                      />
                    </td>
                  ) : (
                    userId === application?.handler?.handlerId &&
                    (application?.status !== "PENDING" ? null
                      : !hasRevision && (
                          <td className="doc-verify-analyze rig">
                            <Button
                              key={documentName}
                              btnText={"Validate"}
                              disabled={hasRevision}
                              borderradius={50}
                              onBtnClick={handleValidate}
                              isLoading={validateIsLoading}
                              className={"mt-1 resizeSpinner" + documentName}
                              padding={"5px 0"}
                              spinnerWidth={1}
                              spinnerHeight={1}
                              backgroundcolor={"white"}
                              backgroundHover={"white"}
                              width={"95px"}
                              bordercolor={colors.darkGreen}
                              borderHover={colors.hover}
                              btntextcolor={colors.darkGreen}
                              colorHover={colors.hover}
                            />
                          </td>
                        ))
                  )}
                </>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DocumentTable;
