import { keyframes } from "styled-components";
import styled from "styled-components";

// Keyframe animation for loader
const loaderAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LargeLoaderWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  /* background: red; */
  background-color: rgba(0, 0, 0, 0.7);

  /* Center the loader */
  display: flex;
  justify-content: center;
  z-index: 9999 !important;
  padding-top: 45vh;

  .large-loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid green; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: ${loaderAnimation} 2s linear infinite; /* Apply animation */
  }
`;
